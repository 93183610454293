<template>
    <div class="wout-login">
        <div class="wout-center">
            <div>
                <img src="@/assets/img/desconto.svg" 
                    width="auto" height="130" alt="Desconto!" crossorigin />
            </div>
            <span class="wout-desconto">Hóspede {{dados.nomehotel}} tem desconto para próxima hospedagem!</span>
        </div>
    </div>
</template>

<script>

export default {
    props:["dados"],
    data: () => {
        return {
            
        }
    }
}
</script>
