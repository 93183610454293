<template>
    <v-card>
        <v-toolbar color="blue">
            <div style="width:100%;height:100%;display:flex;align-items:center;">
                <div style="width:10%">
                    Ficha Digital
                </div>
                <div style="width:85%;display:flex;align-items:center;padding-right:30px;justify-content:flex-end;">
                   


                </div>

            </div>            
        </v-toolbar>

        <v-card-text id="exportPdf" ref="exportPdf" style="margin-top:10px;width: 100%!important;display:flex;justify-content:center;color:black;padding-top:20px!important;max-height:7016px;max-width:1200px;">
            <!-- <div style="width: 100%; height: 400px; overflow: scroll;background-color:red;"  id="exportPdf" ref="exportPdf"> -->
            <div style="width: 80%; overflow: scroll;padding-bottom:50px!important;padding-top:20px;"  >
                <!-- <v-card elevation="2"> -->
                    <div style="display:flex;height:120px;padding:10px!important;padding-top:5px;padding-bottom:0px;">
                        <div style="width:30%">
                            
                            <img v-if="imgSrcLogoHotel" :src="'data:image/png;base64,' + imgSrcLogoHotel" style="width:150px!important;max-height:100px!important;"  />

                        </div>
                        <div v-if="this.dadosHotel && this.dadosHotel.length > 0" style="width:70%;max-height:100px;display:flex;flex-direction:column;font-size:12px;font-weight:bold!important">
                            <div style="">FNRH - {{this.dadosHotel[0].nome}}</div>
                            <div>CNPJ: {{this.dadosHotel[0].cnpj}} - FONE: {{this.dadosHotel[0].telefone}}</div>
                            <div>{{this.dadosHotel[0].razaosocial}} - REG. EMBRATUR: {{this.dadosHotel[0].registroembratur}}</div>
                            <div>{{this.dadosHotel[0].endereco}}, {{this.dadosHotel[0].numero}}, {{this.dadosHotel[0].bairro}}</div>
                            <div>{{this.dadosHotel[0].cidade.nome}}/{{this.dadosHotel[0].uf.sigla}} - {{this.dadosHotel[0].pais.nome}} - CEP: {{this.dadosHotel[0].cep}}
                                </div>
                        </div>
                    </div>
                    <div class="section" style="margin-top:10px;">Dados Pessoais</div>
                    <v-card-text style="padding-top:20px;padding-bottom:0px;padding-top:5px;padding-bottom:0px;">
                        <div>
                            <!-- <v-card style="padding:10px;"> -->
                                <v-row no-gutters>
                                    <v-col cols="4">
                                        <label class="label" for="nome">Nome Completo</label>
                                        <p class="field" id="nome" style="">{{reserva.nome}} {{reserva.sobrenome}}</p>
                                    </v-col>
                                    <v-col cols="2">
                                        <label class="label" for="sexo">Sexo</label>
                                        <p class="field" id="sexo" style="">{{reserva.sexo}}</p>
                                    </v-col>
                                    <v-col cols="3">
                                        <label class="label" for="nascimento">Nascimento</label>
                                        <p class="field" id="nascimento" style="">{{formatarData(reserva.nascimento)}}</p>
                                    </v-col>
                                    <v-col cols="3">
                                        <label class="label" for="tipodocumento">Tipo de Documento</label>
                                        <p class="field" id="tipodocumento" style="">{{reserva.tipodocumento}}</p>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters >
                                    <v-col>
                                        <label class="label" for="documento">Documento</label>
                                        <p class="field" id="documento" style="">{{reserva.documento}}</p>
                                    </v-col>
                                    <v-col>
                                        <label class="label" for="telefone">Telefone</label>
                                        <p class="field" id="telefone" style="">{{reserva.telefoneddi}} ({{reserva.telefoneddd}}) {{reserva.telefone}}</p>
                                    </v-col>
                                    <v-col>
                                        <label class="label" for="celular">Celular</label>
                                        <p class="field" id="celular" style="">{{reserva.celularddi}} ({{reserva.celularddd}}) {{reserva.celular}}</p>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col>
                                        <label class="label" for="email">Email</label>
                                        <p class="field" id="email" style="">{{reserva.email}}</p>
                                    </v-col>
                                    <v-col>
                                        <label class="label" for="profissao">Profissão</label>
                                        <p class="field" id="profissao" style="">{{reserva.profissao}}</p>
                                    </v-col>
                                    <v-col>
                                        <label class="label" for="nacionalidade">Nacionalidade</label>
                                        <p class="field" id="nacionalidade" style="">{{reserva.nacionalidade}}</p>
                                    </v-col>
                                </v-row>
                            <!-- </v-card> -->
                        </div>
                    </v-card-text>
                    <div class="section" style="margin-top:5px;">Endereço</div>
                    <v-card-text style="padding-top:5px;padding-bottom:0px;" >
                        <div>
                            <v-row no-gutters>
                                <v-col cols="6">
                                    <label class="label" for="endereco">Endereço</label>
                                    <p class="field" id="endereco" style="">{{reserva.endereco}}, {{reserva.numero}}{{reserva.complemento?' - '+reserva.complemento:''}}</p>
                                </v-col>
                                <v-col cols="3">
                                    <label class="label" for="bairro">Bairro</label>
                                    <p class="field" id="bairro" style="">{{reserva.bairro}}</p>
                                </v-col>
                                <v-col cols="3">
                                    <label class="label" for="cidade">Cidade / UF / País</label>
                                    <p class="field" id="cidade" style="">{{reserva.cidade}} / {{reserva.ufsigla}} / {{reserva.pais}}</p>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                    <div class="section" style="margin-top:-5px;">Viagem</div>
                    <v-card-text style="padding-top:5px;padding-bottom:0px;" >
                        <div>
                            <v-row no-gutters>
                                <v-col cols="4">
                                    <label class="label" for="empresareservante">Empresa reservante</label>
                                    <p class="field" id="empresareservante" style="">{{reserva.empresareservante}}</p>
                                </v-col>
                                <v-col cols="4">
                                    <label class="label" for="origem">Última procedência</label>
                                    <p class="field" id="origem" style="">{{reserva.cidadeorigem}} / {{reserva.uforigem}} / {{reserva.paisorigem}}</p>
                                </v-col>
                                <v-col cols="4">
                                    <label class="label" for="destino">Próximo destino</label>
                                    <p class="field" id="destino" style="">{{reserva.cidadedestino}} / {{reserva.ufdestino}} / {{reserva.paisdestino}}</p>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="2">
                                    <label class="label" for="motivo">Motivo da viagem</label>
                                    <p class="field" id="motivo" style="">{{reserva.motivo}}</p>
                                </v-col>
                                <v-col cols="2">
                                    <label class="label" for="transporte">Meio de transporte</label>
                                    <p class="field" id="transporte" style="">{{reserva.transporte}}</p>
                                </v-col>
                                <v-col cols="2" v-if="reserva.modelo && (reserva.transporte.toLowerCase() === 'carro' || reserva.transporte.toLowerCase() === 'automóvel' || reserva.transporte.toLowerCase() === 'moto')">
                                    <label class="label" for="transporte">Modelo veículo</label>
                                    <p class="field" id="transporte" style="">{{reserva.modelo}}</p>
                                </v-col>
                                <v-col cols="2" v-if="reserva.placa &&  (reserva.transporte.toLowerCase() === 'carro' || reserva.transporte.toLowerCase() === 'automóvel' || reserva.transporte.toLowerCase() === 'moto')">
                                    <label class="label" for="transporte">Placa veículo</label>
                                    <p class="field" id="transporte" style="">{{reserva.placa}}</p>
                                </v-col>
                                <v-col cols="2">
                                    <label class="label" for="totalhospedes">Total de ocupantes</label>
                                    <p class="field" id="totalhospedes" style="">{{reserva.totalhospedes}}</p>
                                </v-col>
                                <v-col cols="2">
                                    <label class="label" for="numeroreserva">Número da reserva</label>
                                    <p class="field" id="numeroreserva" style="">{{reserva.numeroreserva}}</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2">
                                    <label class="label" for="uh">UH</label>
                                    <p class="field" id="uh" style="">{{reserva.uh}}</p>
                                </v-col>
                                <v-col cols="3">
                                    <label class="label" for="checkin">Checkin</label>
                                    <p class="field" id="checkin" style="">{{formatarData(reserva.datacheckinprevisto)}}</p>
                                </v-col>
                                <v-col cols="3" v-if="reserva.horariochegada">
                                    <label class="label" for="horariochegada">Horário de Chegada</label>
                                    <p class="field" id="horariochegada" style="">{{formatarHora(reserva.horariochegada)}}</p>
                                </v-col>
                                <v-col cols="2">
                                    <label class="label" for="checkout">Checkout</label>
                                    <p class="field" id="checkout" style="">{{formatarData(reserva.datacheckoutprevisto)}}</p>
                                </v-col>
                                <v-col cols="2"  v-if="reserva.horariopartida">
                                    <label class="label" for="horariopartida">Horário de Partida</label>
                                    <p class="field" id="horariopartida" style="">{{formatarHora(reserva.horariopartida)}}</p>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                    <div v-show="formcovid" v-if="relatorioexibido=='COVID'" style="margin-top:-5px!important;" >
                        <div class="section" style="margin-top:10px;">{{formcovid.descricao}}</div>
                        <v-card-text>
                            <div v-for="resposta in formcovid.respostas" :key="resposta.id">
                                <v-row no-gutters>
                                    <v-col cols="8">
                                        <p class="field">{{resposta.pergunta}}</p>
                                    </v-col>
                                    <v-col cols="4">
                                        <p class="field">{{resposta.texto}}</p>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card-text>
                    </div>
                    <div v-if="relatorioexibido=='FNRH'" class="section" style="margin-top:10px;">Documento e Assinatura</div>
                    <v-card-text v-if="relatorioexibido=='FNRH'">

                        <div style="display:flex;">
                            <div style="width:50%;display:flex;justify-content:center;">
                                <img v-if="imgSrcFrente" :src="'data:image/png;base64,' + imgSrcFrente" style="max-height:200px;max-width:250px!important;" class="imgUpload" id="imgDocFrente" />
                            </div>

                            <div style="width:50%;display:flex;justify-content:center;">
                                <img v-if="imgSrcVerso" :src="'data:image/png;base64,' + imgSrcVerso"   style="max-height:200px;max-width:250px!important;" class="imgUpload" id="imgDocVerso" />
                            </div>
                        </div>
                        <div>
                            
                            <v-row no-gutters>
                                <v-spacer />
                                <v-col cols="6" style="display:flex;justify-content:center;">
                                    <!-- <img id="docfrente" alt="Frente do Documento" style="height:180px!important;"/> -->
                                    
            
                                </v-col>
                                <v-col cols="6" style="display:flex;justify-content:center;">
                                    <!-- <img id="docverso" alt="Verso do Documento" style="height:180px!important;"/> -->
                                    
                                </v-col>
                                <v-spacer />
                            </v-row>
                            <v-row no-gutters style="display:flex;align-items:center;justify-content:center;margin-top:0px;margin-bottom:0px!important;">
                                
                                <v-col cols="4" style="display:flex;justify-content:center;" >
                                    <!-- <img id="assinatura" alt="Assinatura" style="width:100px"/> -->
                                    <img v-if="imgSrcAssinatura" :src="'data:image/png;base64,' + imgSrcAssinatura" style="max-width:180px!important;min-width:200px!important;"  id="imgAssinatura" />
                                     
                                </v-col>
                                
                            </v-row>
                            <v-row no-gutters style="display:flex;align-items:center;justify-content:center;padding-bottom:10px!important;">
                                
                                <v-col style="display:flex;flex-direction:column;align-items:center;justify-content:center;">
                                
                                    <div style="width: 66.6%; text-align: center; display: block; border-bottom: 0px solid black;">
                                        __________________________________________
                                    </div>
                                    <div style="width: 66.6%; text-align: center; display: block;">{{reserva.nome}} {{reserva.sobrenome}}</div>
                                
                                </v-col>
                                
                            </v-row>

                            <v-row no-gutters style="display:flex;align-items:center;justify-content:center;margin-top:20px;margin-bottom:0px!important;">
                                
                
                                <div >
                                    <input disabled="true" type="checkbox" id="endereco_compartilhado" name="endereco_compartilhado" 
                                        v-model="reserva.termolgpd" true-value="true" false-value="false" 
                                        style="margin-left:10px;margin-right:0px;-ms-transform: scale(2);-webkit-transform: scale(2);-o-transform: scale(2);transform: scale(2);">
                                    <label class="containerCheck" style="margin-left:20px;min-width:100%;margin-top:20px;font-size:18px;margin-bottom:10px;">
                                        <span class="checkmarkCheck" ></span>Aceito Termos da LGPD
                                    </label>
                                </div>
                            </v-row>

                            <v-row no-gutters style="display:flex;align-items:center;justify-content:center;margin-top:20px;margin-bottom:0px!important;">
                                
                
                                <div>
                                    <input disabled="true" type="checkbox" id="endereco_compartilhado" name="endereco_compartilhado" 
                                        v-model="reserva.aceitetermo" true-value="true" false-value="false" 
                                        style="margin-left:10px;margin-right:0px;-ms-transform: scale(2);-webkit-transform: scale(2);-o-transform: scale(2);transform: scale(2);">
                                    <label class="containerCheck" style="margin-left:20px;min-width:100%;margin-top:20px;font-size:18px;margin-bottom:10px;">
                                        <span class="checkmarkCheck" ></span>Aceito os termos de responsabilidade do hotel
                                    </label>
                                </div>
                            </v-row>
                        </div>
                    </v-card-text>


                     <v-card-text v-if="relatorioexibido=='COVID'">
                        <div>
                            <v-row no-gutters>
                                <div>
                                    O usuário declara, sob as penas da lei, que os dados fornecidos
                                    no cadastro são verídicos e se referem à sua pessoa e/ou
                                    empresa, sendo o usuário, único e exclusivo responsável por
                                    qualquer dano causado pela inveracidade ou má-fe no
                                    fornecimento destes dados.
                                </div>
                            </v-row>
                            <v-row no-gutters style="display:flex;align-items:center;justify-content:center;margin-top:0px;margin-bottom:0px!important;">
                                
                                <v-col cols="4" style="display:flex;justify-content:center;" >
                                    <!-- <img id="assinatura" alt="Assinatura" style="width:100px"/> -->
                                    <img v-if="imgSrcAssinatura" :src="'data:image/png;base64,' + imgSrcAssinatura" style="max-width:200px!important;min-width:200px!important;"  />
                                     
                                </v-col>
                                
                            </v-row>
                            <v-row no-gutters style="display:flex;align-items:center;justify-content:center;padding-bottom:30px!important;">
                                
                                <v-col style="display:flex;flex-direction:column;align-items:center;justify-content:center;">
                                
                                    <div style="width: 66.6%; text-align: center; display: block; border-bottom: 0px solid black;">
                                        __________________________________________
                                    </div>
                                    <div style="width: 66.6%; text-align: center; display: block;">{{reserva.nome}} {{reserva.sobrenome}}</div>
                                
                                </v-col>
                                
                            </v-row>
                        </div>
                    </v-card-text>
                <!-- </v-card> -->
            </div>
        </v-card-text>
        <!-- <v-card-actions>
            <v-spacer />
            
            <v-btn @click="gerarFNRH()" :disabled="reserva.percentualpreenchido<100.0"
                >
                <v-icon color="primary">mdi-download</v-icon>
                <b>Baixar FNRH</b>
                <v-icon color="red">mdi-file-pdf</v-icon>
            </v-btn>
            <v-btn @click="gerarFormularioSanitario()" :disabled="(Object.keys(formcovid).length === 0)">
                <v-icon color="primary">mdi-download</v-icon>
                <b>Baixar Formulário COVID</b>
                <v-icon color="red">mdi-file-pdf</v-icon>
            </v-btn>
            <a href="#" download id="downloadFormSanitario" hidden></a>
            <a href="#" download id="downloadFNRH" hidden></a>
            <v-spacer />
        </v-card-actions>
        <v-card-actions>
            <v-spacer />
            

            <v-btn @click="finalizarFicha()" :disabled="reserva.percentualpreenchido<100.0"
                color="info">
                <v-icon color="white">mdi-upload</v-icon>
                <b>{{textobtnFinalizar}}</b>
            </v-btn>
            
            <v-spacer />
        </v-card-actions> -->
    </v-card>
</template>

<script>
import dayjs from 'dayjs'
import {BUILD_TIPO_INTEGRACAO} from '@/constantes.js'
export default {
    name : "FichaDigital",
    props:["id", "possuiEmpresaReservante"],
    data: ()=>({
        reserva: {},
        formcovid: {},
        docfrente: null,
        docverso: null,
        assinatura: null,
        textobtnFinalizar:'Finalizar Ficha',
        imgSrcFrente:'',
        imgSrcVerso:'',
        imgSrcAssinatura:'',
        imgSrcLogoHotel:'',
        dadosHotel:null,
        relatorioexibido:'FNRH',
        nomedoRelatorio:'Relatorio',
        btnFinalizarHabilitado:false
    }),
    computed: {
        desabilitarFNRH () {
            var result;
            // result += (this.reserva.iconepreenchido=='')
                result = (this.reserva.docfrente == null)
                || (this.reserva.docverso == null)
                || (this.reserva.assinatura == null);
            return result;
        }
    },
    watch:{
        dadosHotel(val){
            this.dadosHotel =val
        }
    },
    methods: {
        exibirRelatorio(valor){
            this.relatorioexibido = valor
            // this.nomedoRelatorio = this.relatorioexibido+"_"+this.dadosHotel[0].nome.replace(' ','_')+"_"+this.reserva.nome.replace(' ','_')+"_"+dayjs().format('DDMMYYYYHHmmss')
            this.nomedoRelatorio = this.reserva.numeroreserva+"-"+this.reserva.nome.replace(' ','-')+"-"+this.reserva.sobrenome.replace(' ','-')+"-"+this.relatorioexibido
        },
        savePdf(){
                // this.$PDFSave(this.$refs.exportPdf, "Relatorio");
                window.scrollTo(0,0);
                this.$PDFSave(this.$refs.exportPdf, this.nomedoRelatorio);
        },
        editarFicha(){
            let body = {
                            reserva: {
                                id: this.reserva.idreserva,
                                hotel: localStorage.getItem("hotel"),
                                iniciofd:true
                            },
                            hospede: {
                                id: this.reserva.idhospede,
                                idhospedepms: this.reserva.idhospedepms,
                                enviadopmstimestamp: null
                            },
                        };

                        this.$http.post("/reserva/upinsert", body).then((response) => {
                            if (response.status == 200) {
                                this.btnFinalizarHabilitado = true
                                this.reserva.enviadopms = false
                            } else {
                                console.log(JSON.stringify(response))
                            }
                            })
                            .catch((error) => {
                                console.log(error);
                                console.log(JSON.stringify(body));
                            });
        },
        finalizarFicha(){
            this.textobtnFinalizar = "Finalizando..."
            this.reserva.usuarioemail = localStorage.getItem('useremail')
            console.log("reserva")
            console.log(this.reserva)
            if(BUILD_TIPO_INTEGRACAO == "HITS"){
                this.reserva.ufnome = this.reserva.ufsigla
            }
            //  this.$socket.emit('dash_backend_importa_reserva', {reserva:this.filtroReserva})  
             this.$socket.emit('dash_to_backend_finalizarficha', {reserva:this.reserva})  

             this.sockets.subscribe("backend_to_dash_finalizarficharetorno", (dados) => {
                   
                  
                    
                    this.sockets.unsubscribe("backend_to_dash_finalizarficharetorno")
                    if(dados.statusCode == 200){

                        let body = {
                            reserva: {
                                id: this.reserva.idreserva,
                                hotel: localStorage.getItem("hotel"),
                                fimfd:true
                            },
                            hospede: {
                                id: this.reserva.idhospede,
                                idhospedepms: this.reserva.idhospedepms,
                                enviadopmstimestamp: dayjs().format()
                            },
                        };

                        this.$http.post("/reserva/upinsert", body).then((response) => {
                            if (response.status == 200) {
                                this.btnFinalizarHabilitado = false
                                this.reserva.enviadopms = true
                            
                                

                                let bodyRemoverTempMerge = {
                                    numeroreserva:this.reserva.numeroreserva,
                                    idhospedepms:this.reserva.idhospedepms
                                } 
                       

                                this.$http.post("/reserva/removerReservaTemp", bodyRemoverTempMerge).then(() => {
                                    this.$emit('update')
                                    this.$emit('fechar')
                                }).catch(()=>{
                                    this.$emit('update')
                                    this.$emit('fechar')
                                })

                                
                            } else {
                                console.log(JSON.stringify(response))
                            }
                            })
                            .catch((error) => {
                                console.log(error);
                                console.log(JSON.stringify(body));
                            });

                    }else{
                        // alert(dados.resposta)
                        alert("Robô não respondeu de Forma Satisfatoria")
                    }

                    this.textobtnFinalizar = "Finalizar Ficha"

                });

            //  this.$socket.subscribe("backend_to_dash_finalizarficharetorno", (dados) => {
            //     this.qtdeNotificacoes += 1
            //     console.log(dados)
            // });
        },
        formatarData(aDate) {
            //this.moment(aDate).locale(this.$i18n.locale).format('L')
            return this.moment(aDate).format('DD/MM/YYYY')
        },
        formatarHora(aDate) {
            return this.moment(aDate).format('HH:MM')
        },
        gerarFNRH() {
            //this.$http.post('/reserva/fnrh/create', { id: this.id, modo: 'base64' })
            this.$http.post('/reserva/fnrh/create', { id: this.id }, 
                {headers: {"Access-Control-Allow-Origin": "*"}})
            .then((response) => {
                if(response.status == 200){
                    // Desta forma modo: 'base64', abre em outra janela mas não faz o download
                    // let pdfWindow = window.open('','FNRH')
                    // pdfWindow.document.write(
                    //     "<iframe width='100%' height='100%' frameborder='0' marginwidth='0' marginheight='0' scrolling='no' style='border:1px solid #CCC; border-width:1px;' src='data:application/pdf;base64, " +
                    //     encodeURI(response.data) + "'></iframe>"                        
                    // )
                    // pdfWindow.document.close();
                    //console.log(JSON.stringify(response.data))
                    //document.getElementById('iframeFNRH').src = response.data.link
                    var alink = document.getElementById('downloadFNRH')
                    if (alink) {
                        alink.setAttribute("target", "_blank");
                        alink.href = response.data.link;
                        alink.click();
                    }
                }
            })
        },
        gerarFormularioSanitario() {
            //this.$http.post('/reserva/formulariosanitario/create', { id: this.id, modo: 'base64' })
            this.$http.post('/reserva/formulariosanitario/create', { id: this.id })
            .then((response) => {
                if(response.status == 200){
                    // Desta forma modo: 'base64', abre em outra janela mas não faz o download
                    // let pdfWindow = window.open('','COVID')
                    // pdfWindow.document.write(
                    //     "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                    //     encodeURI(response.data) + "'></iframe>"
                    // )
                    // pdfWindow.document.close();
                    var alink = document.getElementById('downloadFormSanitario')
                    if (alink) {
                        alink.setAttribute("target", "_blank");
                        alink.href = response.data.link;
                        alink.click();
                    }
                }
            })
        },
        buscarDadosReserva() {
            this.$http.post('/reserva/select', { id: this.$route.params.id })
            .then(async (response) => {
                console.log(response.data)
                if (response.status == 200) {
                    if(response.data && Array.isArray(response.data) && response.data.length > 0) {
                        this.reserva = response.data[0]
                        if(this.reserva.enviadopms){
                            this.btnFinalizarHabilitado = true
                        }else{
                            this.btnFinalizarHabilitado = false
                        }
                  
                        await this.$http.post('perfil/hotel/select', { id: localStorage.getItem('hotel') }).then(async (response) => {
                            this.dadosHotel = response.data

                            if(this.dadosHotel[0].logo){
                            
                                await this.$http.post('imagem/select', { id: this.dadosHotel[0].logo }).then(async (imghotel) => {

                                    this.imgSrcLogoHotel = await Buffer.from(imghotel.data[0].dados).toString()
                                    // this.nomedoRelatorio = this.relatorioexibido+"_"+this.dadosHotel[0].nome.replace(' ','_')+"_"+this.reserva.nome.replace(' ','_')+"_"+dayjs().format('DDMMYYYYHHmmss')
                                    this.nomedoRelatorio = this.reserva.numeroreserva+"-"+this.reserva.nome.replace(' ','-')+"-"+this.reserva.sobrenome.replace(' ','-')+"-"+this.relatorioexibido
                                    this.buscarDadosCovid();
                                    await this.buscarImagem('docfrente')
                                    await this.buscarImagem('docverso')
                                    await this.buscarImagem('assinatura')
                                    
                                })

                            }else{
                                    // this.nomedoRelatorio = this.relatorioexibido+"_"+this.dadosHotel[0].nome.replace(' ','_')+"_"+this.reserva.nome.replace(' ','_')+"_"+dayjs().format('DDMMYYYYHHmmss')
                                    this.nomedoRelatorio = this.reserva.numeroreserva+"-"+this.reserva.nome.replace(' ','-')+"-"+this.reserva.sobrenome.replace(' ','-')+"-"+this.relatorioexibido
                                    this.buscarDadosCovid();
                                    await this.buscarImagem('docfrente')
                                    await this.buscarImagem('docverso')
                                    await this.buscarImagem('assinatura')
                            }

                            // var _self = this
                            // setTimeout(() => {
                            //     _self.savePdf()
                            // }, 1000);
                            
                            var _self = this
                            var donwloadRealizado = false
                            if(this.relatorioexibido === 'FNRH'){
                                setInterval(async () => {

                                    var imgAssinatura = document.getElementById('imgAssinatura')
                                    var isLoadedAss = imgAssinatura.complete && imgAssinatura.naturalHeight !== 0; 

                                    if( isLoadedAss ){
                                        if(!donwloadRealizado){

                                            donwloadRealizado = true

                                            await _self.savePdf()
                                            

                                        }
                                    }
                                }, 500);
                            }else{
                                setTimeout(async () => {
                                    await _self.savePdf()
                                    donwloadRealizado = true
                                }, 2000);
                                
                            }
                            
                            setInterval(async () => {
                                // alert('1')
                                if(donwloadRealizado){
                                    // alert('2')
                                    //  window.close()
                                }
                            },3000)
                            

                        })

                        

                        //  setTimeout(() => {
                        //     window.close()
                        // }, 3000);
                         

                        
                    }
                }
            })
        },
        buscarDadosCovid() {
            if (this.reserva.idpessoa) {
                var params = { 
                    chave: 'COVID', 
                    resposta: {
                        idrespondente: this.reserva.idpessoa
                    }
                }
                this.$http.post('/formulario/resposta/select', params)
                .then((resp) => {
                    if (resp.status == 200) {
                        if(resp.data && Array.isArray(resp.data) && resp.data.length > 0) {
                            this.formcovid = resp.data[0]
                        }
                    }
                })
            } else {
                alert('ainda sem reserva...')
            }
        },
        bin2String(array) {
            return String.fromCharCode.apply(String, array);
        },
        async buscarImagem(campo) {

            if(campo == "docfrente"){

                // imagem frente
                await this.$http.post("/imagem/select", {hotel: localStorage.getItem("hotel"),id: this.reserva[campo],})
                .then(async (responseimg) => {
                  if (Array.isArray(responseimg.data) && responseimg.data.length>0) {
                    this.imgSrcFrente =  await Buffer.from(responseimg.data[0].dados).toString()
                    // var img = document.getElementById(campo)
                    // img.src = await Buffer.from(responseimg.data[0].dados).toString()
                  }
                }).catch((error) => { 
                    console.log(error); 
                });

            }

            if(campo == "docverso"){
                
                // imagem verso
                this.$http.post("/imagem/select", {hotel: localStorage.getItem("hotel"),id: this.reserva[campo],})
                    .then(async (responseimg) => {
                    if (Array.isArray(responseimg.data) && responseimg.data.length>0) {
                        this.imgSrcVerso = Buffer.from(responseimg.data[0].dados).toString()
                        // var img = document.getElementById(campo)
                        // img.src = await Buffer.from(responseimg.data[0].dados).toString()
                    }
                    })
                    .catch((error) => { console.log(error); });
            }

            if(campo == "assinatura"){
            
                if (this.reserva.idpessoa && this.reserva.id) {
                    this.$http.post('/imagem/select', { 
                        id: this.reserva[campo]
                    })
                    .then((resp) => {
                        if (resp.status == 200) {
                            this.imgSrcAssinatura = Buffer.from(resp.data[0].dados).toString()
                            // if(resp.data && Array.isArray(resp.data) && resp.data.length > 0) {
                            //     this[campo] = resp.data[0]
                            //     var img = document.getElementById(campo)
                            //     //img.src = "data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg=="
                            //     var mystr = this.bin2String(this[campo]['dados']['data'])
                            //     img.src = `data:${this[campo]['tipo']};base64, ${mystr}`
                            // }
                        }
                    })
                } else {
                    alert('ainda sem reserva...')
                }

            }

        }
    },
    async created() {
        this.relatorioexibido = this.$route.params.tipo
        // setInterval(() => {
        //     window.scrollTo(0,0);
        // }, 500);
        await this.buscarDadosReserva();
    },
    mounted() {
        //
    }
}
</script>

<style scoped>
.label {
    font-size: 8pt!important;
    font-weight: bold;
}
.field {
    font-size: 11pt!important;
    font-weight: bold;
}
.section {
    font-size: 11pt!important;
    color: white;
    background-color: #6187a5;
    padding: 0.2rem;
    border-radius: 5px 5px 0px 0px;
}
</style>