<template>
    <div class="wout-principal">
        <!-- Cabeçalho -->
        <Header :nome="dados.nome" :uh="dados.uh" :numeroreserva="dados.numeroreserva"
            @voltar="voltar()" @logout="logout()"
        ></Header>
        <!-- Conteúdo -->
        <pgto-cartao :dados="dados" 
            @registrarPagamento="(dadoscartao) => registrarPagamento(dadoscartao)" />
    </div>
</template>

<script>
import PgtoCartao from './PgtoCartao.vue'
import Header from '@/components/webcheckout/Header'
export default {
    props:["dados"],
    data: () =>{
        return{
            exibirDetalhes: true
        }
    },
    components: {
		PgtoCartao, Header
	},
    computed: {
    },
    methods: {
        logout(){
            this.$emit('direcionar', {to:'login',from:'pagamento'})
        },
        voltar() {
            this.$emit('direcionar', {to:'inicio',from:'pagamento'})
        },
        registrarPagamento(dadoscartao) {
            const dados = {
                ...dadoscartao,
                idreservahospede: this.dados.idreservahospede,
                saldo: this.dados.saldo,
                idhotel: this.dados.idhotel,
                nomehotel: this.dados.nomehotel,
                cabecalhos: this.dados.cabecalhos,
                consumos: this.dados.consumos
            }
            // this.$emit('direcionar', {to: 'avisoself', from: 'pagamento', dados})
            this.$emit('registrarPagamento', dados)
        }
    },
    mounted(){
        //        
    }
}
</script>

