<template>
    <div>
        <div class="wout-login" v-if="componenteatual == 'avisoSelfie'">
            <div class="wout-center">
                <div>
                    <img src="@/assets/img/prelogin.svg" 
                        width="auto" height="130" alt="Pré Login" crossorigin />
                </div>
                <span class="wout-confirmar">Para melhor garantir a segurança desta transação, vamos tirar uma self, OK?</span>
                <button class="wout-continuar" @click="avancar">
                    <span>Continuar</span>
                    <v-icon large class="wout-icone-continuar">mdi-chevron-right</v-icon>
                </button>
            </div>
        </div>
        <template v-if="componenteatual == 'selecionarDoc'" >
            <SelecionarDocumento @etapasConcluidas="capturaDeFotosConcluidas"/>
        </template>
    </div>
</template>
<script>
    import SelecionarDocumento from './SelecionarDocumento.vue';
export default {
    components: {
      SelecionarDocumento
    },
    props:["dados"],
    data: () => {
        return {
            componenteatual: 'selecionarDoc'     
        }
    },
    methods: {
        capturaDeFotosConcluidas() {
            this.componenteatual = 'avisoSelfie';
        },
        avancar() {
            this.$emit('direcionar', {from: 'avisoself', to: 'self'})
        },
        voltar() {
            this.$emit('direcionar', {to:'pagamento',from:'avisoself'})
        },
    },

    mounted() {
        console.log('selecionar doc')
    }
}
</script>
