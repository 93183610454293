<template>
    <div class="wout-principal">
        <!-- Cabeçalho -->
        <Header :nome="dados.nome" :uh="dados.uh" :numeroreserva="dados.numeroreserva"
            @voltar="voltar()" @logout="logout()"
        ></Header>        

        <div class="wout-cartao-pix">
            <h1>Dados para Pagamento PIX:</h1>
            <div class="col-12">
                <label class="cc-label" style="margin-right:10px;">Valor: </label>R$ {{dados.saldo}}<br>
            </div>
            <div class="col-12">
                <label class="cc-label" style="margin-right:10px;">Tipo Chave:</label>{{tipoChave}}<br>
            </div>
            <div class="col-12">
                <label class="cc-label" style="margin-right:10px;">Valor Chave:</label>
                <span ref="textoDiv">{{valorChave}}&nbsp;&nbsp;&nbsp;</span>
                <button @click.prevent="copiarTexto">
                    <span class="material-icons">
                        content_copy
                    </span>
                </button>
            </div>
            <div class="col-12">
                <label class="cc-label" for="i-nometitular" style="margin-right:10px;">Titular:</label>{{titularChave}}<br>
            </div>
        </div>
    </div>
</template>

<script>

//const { PIX } = require('gpix/dist');
import Header from '@/components/webcheckout/Header'

export default {
    name: 'DadosPix',
    props:["dados"],
    data: () =>{
        return{
            tipoChave: '',
            valorChave: '',
            titularChave: '', 
            imgQRCode:'', 
        }
    },
    components: {
        Header
	},
    computed: {
        validInput() {
            return (
                typeof(this.validarNome()) == 'boolean'
                && typeof(this.validarNumeroCartao()) == 'boolean'
                && typeof(this.validarValidade()) == 'boolean'
                && typeof(this.validarCVCCVV()) == 'boolean'
            )
        }
    },
    methods: {
        copiarTexto() {
            // Obtém o texto da div
            const textoDiv = this.$refs.textoDiv.innerText;

            // Cria um elemento de texto temporário para copiar o texto
            const inputTemporario = document.createElement('textarea');
            inputTemporario.value = textoDiv;

            // Adiciona o elemento de texto temporário ao DOM
            document.body.appendChild(inputTemporario);

            // Seleciona o texto no elemento de texto temporário
            inputTemporario.select();

            // Copia o texto para a área de transferência
            document.execCommand('copy');

            // Remove o elemento de texto temporário do DOM
            document.body.removeChild(inputTemporario);

            // Exibe uma mensagem indicando que o texto foi copiado (opcional)
            this.$snack('Texto copiado para a área de transferência!');
        },
        logout(){
            this.$emit('direcionar', {to:'login',from:'pagamento'})
        },
        voltar() {
            this.$emit('direcionar', {to:'inicio',from:'pagamento'})
        },
        carregarDados(){
             this.$http.post('webcheckout/buscarParametro',{chave:'PIXCONFIG'})
                .then(async resp =>{
                    this.tipoChave      = resp.data[0].valor2
                    this.valorChave     = resp.data[0].valor3
                    this.titularChave   = resp.data[0].valor4 
                   

                    //let valorsaldo = parseFloat(this.dados.saldo)
                    

                    // let pix = PIX.static()
                    //               .setReceiverName(this.titularChave)
                    //               .setReceiverCity('Natal')
                    //               .setKey(this.valorChave)
                    //               .setAmount(valorsaldo)

                    // this.imgQRCode = await pix.getQRCode()
                }).catch(error =>{
                    console.error(error)
                })
        }
    },
    mounted(){

        this.carregarDados()   
    }
}
</script>

<style lang="scss" scoped>
.cc-principal {
  margin: 0px;
  width:100%;
  height: 100%;
  padding: 2em 1em;
  border-radius: 1em;
  max-width: 450px;
  box-shadow: 0px 10px 20px rgba(128, 128, 128, .25);
  margin: 0 auto;
  @media screen and (min-width: 768px ) {
    height: auto;      
  }
}

.cc-label{
  font-size: 14px;
  text-transform: capitalize;
}

.credit-card-brand{
  width: 4em;
  height: auto;
}

.cc-cartao {
  width: 100%;
  margin: auto;
  padding: 1em;
  display: grid;
  grid-template-rows: .2fr 1fr .2fr;
  grid-template-columns: 1fr;
  border-radius: 1em;
  height: 12em;
  color: #666;
  margin-bottom: 1em;

  background: rgb(25,118,210);
  background: -moz-linear-gradient(31deg, rgba(25,118,210,1) 13%, rgba(19,119,242,0.7707457983193278) 56%, rgba(0,212,255,1) 100%);
  background: -webkit-linear-gradient(31deg, rgba(25,118,210,1) 13%, rgba(19,119,242,0.7707457983193278) 56%, rgba(0,212,255,1) 100%);
  background: linear-gradient(31deg, rgba(25,118,210,1) 13%, rgba(19,119,242,0.7707457983193278) 56%, rgba(0,212,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1976d2",endColorstr="#00d4ff",GradientType=1);

  color: #f9f9f2;
  font-weight: bold;

  box-shadow: 0px 10px 20px rgba(128, 128, 128, .25);
//   backdrop-filter: blur(2px);

  .cc-cartao_corpo{
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    margin-bottom: 1em;
  }
  .cc-cartao_rodape{
    z-index: 3;
    display: flex;
    justify-content: flex-end;
  }
}
.cc-pagar-btn {
  font-size: 1rem!important;
  font-weight: bold;
  background-color: #1976d2!important;
  color: white;
  padding: 1.5vh;
  line-height: 1.5;
  border-radius: 1em;
  width: 100%;
  height: 3em;
  display: flex;
}
.cc-pagar-btn:disabled {
  background-color: #80808020 !important;
  .cc-pagar-btn_texto{
    color: #BABAB5 !important;
  }
}



.cc-center {
  color: #70708C;
  text-align: center;
  margin: 0px;
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
}
.cc-form {
  display: flex;
  flex-direction: column;
}
.cc-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cc-col-6 {
  width: 50%;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
.cc-col-12 {
  width: 100%;
}
.sim-btn, .nao-btn {
    display: flex;
    align-items: center;
    padding: 10px;
}
label {
    color: #70708C;
}



//css vuetify

.v-text-field--rounded {
  border-radius: 1em !important;
}


.v-application p {
  margin-bottom: unset !important;
}

.v-text-field__details {
    margin-bottom: 3px !important;
}
</style>