<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <div>
          <v-card class="pa-2">
            <v-toolbar color="light-blue" class="ma-2"
              style="color: white;display:flex;flex-direction:column;padding-top:5px;padding-bottom:5px;">
              <div style="min-width:100%!important;display:flex;justify-content:space-between;">
                <span class="toolbar-title" style="width:50%;padding:10px;"> 
                  Usuários
                </span> 
                <v-spacer></v-spacer>
                <v-btn @click="novoUsuario()" data-cy="novoUsuario" small class="mr-2" style="padding:10px;color:white;font-weight:bold;background-color:#0e5b7d;height:40px!important;">
                  + Novo Usuário
                </v-btn> 
                <v-btn @click="setModalPerfisUsuarios()" data-cy="setModalPerfisUsuarios" small class="mr-2" style="padding:10px;color:white;font-weight:bold;background-color:#0e5b7d;height:40px!important;">
                  Perfis
                  <v-icon class="ml-1">mdi-account-child-circle</v-icon>
                </v-btn> 
                <span style="width:5%;padding:10px;"> 
                  <BotaoTutorial urlVideo="" categoria="" legenda="Dúvidas Usuários"/>
                </span>
               </div>
            </v-toolbar>
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Buscar"
              data-cy="filtro"
              single-line
              hide-details
            />
            <v-dialog v-model="dialog" max-width="70vw">
              <v-card height="70vh">
                <!-- Conteúdo do Diálogo -->
                <div style="height:80%;">
                  <v-card-text>
                    <template v-if="step==1">
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 sm6 md4>
                            <v-text-field
                              id="textApelido"
                              v-model="editedItem.apelido"
                              data-cy="editedItem.apelido"
                              label="Login"
                            />
                          </v-flex>
                          <template v-if="editedItem?.id == 0">
                            <v-flex xs12 sm6 md4>
                              <v-text-field
                                v-model="senha0"
                                label="Senha"
                                type="password"
                              />
                            </v-flex>
                            <v-flex xs12 sm6 md4>
                              <v-text-field
                                v-model="senha1"
                                label="Confirmação"
                                type="password"
                              />
                            </v-flex>
                          </template>                     
                          <v-flex xs12 sm6 md4>
                            <v-text-field v-model="editedItem.nome" data-cy="editedItem.nome" label="Nome" />
                          </v-flex>
                          <v-flex xs12 sm6 md4>
                            <span data-cy="editedItem.idioma-span" >
                              <v-select
                                label="Idioma"
                                v-model="editedItem.idioma"
                                data-cy="editedItem.idioma"
                                :items="idiomas"
                                item-text="descricao"
                                item-value="codigoidioma"
                              ></v-select>
                            </span>
                          </v-flex>                      
                          <v-flex xs12 sm6 md3>
                            <v-text-field
                              id="textEmail"
                              v-model="editedItem.email"
                              data-cy="editedItem.email"
                              label="Email"
                              :rules="emailRules"
                            />
                          </v-flex>
                          <v-flex xs12 sm6 md3>
                            <v-text-field type="number"
                              v-model="editedItem.telefone"
                              data-cy="editedItem.telefone"
                              label="DDI + DDD + Telefone"
                              placeholder="DDI + DDD + Telefone"
                            />
                          </v-flex>
                          <v-flex xs12 sm6 md3>
                            <v-text-field 
                              v-model="senha0" data-cy="senha0" type="password"
                              label="Senha"
                              placeholder="Senha"
                            />
                          </v-flex>
                          <v-flex xs12 sm6 md3>
                            <v-text-field 
                              v-model="senha1" data-cy="senha1" type="password"
                              label="Repetir Senha"
                              placeholder="Repetir Senha"
                            />
                          </v-flex>
                          <v-flex xs12 sm12 md12>
                            <!-- <span data-cy="editedItem.hoteis-span">
                              <v-select dense
                                v-model="editedItem.hoteis"
                                data-cy="editedItem.hoteis"
                                :items="hoteisStr"
                                attach
                                chips
                                label="Hotéis"
                                multiple
                                @change="teste"
                              ></v-select>
                            </span> -->
                          </v-flex>
                          <v-flex xs12 sm12 md12>
                            <!-- <span data-cy="hotelPadrao-span"> 
                              <v-select dense id="selectHotelPadrao"
                                v-model="hotelPadrao"
                                data-cy="hotelPadrao"
                                :items="editedItem.hoteis"
                                attach
                                chips
                                label="Hotel Padrão"
                              ></v-select>
                            </span> -->
                            <span data-cy="idtipousuario-span">
                              <v-select dense id="selectTipoUsuario"  
                                v-model="idtipousuario"  
                                data-cy="idtipousuario"                         
                                :items="perfis"
                                item-text="desctipousuario"
                                item-value="id"
                                attach
                                chips
                                label="Perfil de Usuário"
                              ></v-select>
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </template>
                  </v-card-text>
                </div>
                <!-- Botões -->
                <div style="height:20%;">
                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="error" @click="fecharAtualizacao" data-cy="fecharAtualizacao"
                      >Cancelar</v-btn
                    >
                    <v-btn color="primary" @click="atualizarUsuario" data-cy="atualizarUsuario"
                      >Salvar</v-btn
                    >
                  </v-card-actions>
                </div>
              </v-card>
            </v-dialog>
            <v-data-table
              :headers="headers"
              :items="usuarios"
              :search="search"
              class="elevation-1"
            >
              <template slot="headerCell" slot-scope="{ header }">
                <span
                  class="subheading font-weight-light text-general text--darken-3"
                  v-text="header.text"
                />
              </template>
              <template v-slot:[`item.ativo`]="{ item }">
                <v-simple-checkbox v-model="item.ativo" disabled>
                </v-simple-checkbox>
              </template>
              <template v-slot:[`item.nome`]="{ item }">
                <p data-cy="item.nome">{{item.nome}}</p>
              </template>

              <template v-slot:[`item.id`]="{ item }">
                <p data-cy="item.id">{{item.id}}</p>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  color="green darken-1"
                  small style="color:white!important;height:30px!important;"
                  @click="editarUsuario(item)"
                  :data-cy="`editarUsuario_${item.id}`"
                  >
                  Editar
                </v-btn>
              </template>
              <template v-slot:items="props">
                <td>{{ props.item.id }}</td>
                <td class="justify-center">
                  <v-icon medium class="mr-2" @click="editarUsuario(props.item)"
                    >edit</v-icon
                  >
                  <v-icon medium @click="deleteItem(props.item)">delete</v-icon>
                </td>
                <td class="">{{ props.item.apelido }}</td>
                <td>
                  <v-edit-dialog
                    :return-value.sync="props.item.email"
                    large
                    lazy
                    persistent
                    @save="saveInline"
                    @cancel="cancelInline"
                    @open="openInline"
                    @close="closeInline"
                  >
                    <div>{{ props.item.email }}</div>
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.email"
                        label="Edit"
                        single-line
                        counter
                        autofocus
                      />
                    </template>
                  </v-edit-dialog>
                </td>
                <td>
                  <div>{{ props.item.nome }}</div>
                </td>
                <td class="">{{ props.item.telefone }}</td>
                <td class="">{{ props.item.hoteis }}</td>
                <td class="">{{ props.item.ativo }}</td>
              </template>
            </v-data-table>
            <ModalPerfisUsuarios 
            v-if="exibirModalPerfisUsuarios" 
            @setModalPerfisUsuarios="setModalPerfisUsuarios()"
            :exibirModalPerfisUsuarios="exibirModalPerfisUsuarios"
            />
            <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                  <span>{{ snackText }}</span>
                  <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
                </div>
            </v-snackbar>
          </v-card>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ModalPerfisUsuarios from '@/components/DashViews/Modals/ModalPerfisUsuarios'
import BotaoTutorial from '../material/BotaoTutorial.vue';
export default {
  data: () => ({
    step: 1,
    emailRules: [
      (v) => !!v || "E-mail é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
    ],
    snack: false,
    snackColor: "",
    usuarios: [],
    snackText: "",
    exibirModalPerfisUsuarios : false,
    dialog: false,
    search: "",
    perfis : [],
    senha1: null,
    senha0: null,
    headers: [
      { text: "Id", value: "id" },
      { text: "Actions", value: "actions", sortable: false },
      { text: "Login", value: "apelido" },
      { text: "Email", value: "email" },
      { text: "Perfil", value: "desctipousuario" },
      { text: "Nome", value: "nome" },
      { text: "Telefone", value: "telefone" },
      { text: "Ativo", value: "ativo" }
    ],
    editedIndex: -1,
    editedItem: {},
    idiomas: [],
    hoteis: [],
    hoteisStr: [],
    hotelPadrao: '',
    
    idtipousuario : 0,

    defaultItem: {
      id : 0,
      ativo: true,
      apelido: "",
      senha: "",
      email: "",
      nome: "",
      criadopor: 1,
      telefone: "",
      idioma: "pt-BR",
      hoteis: [],
      setores: [],
      acessos: [],
      avatar: null,
      hotel: null,
      desctipousuario : '',
      idtipousuario : 0
    },
  }),
  components :{
    ModalPerfisUsuarios,
    BotaoTutorial
},
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  async created() {
    await this.loadUsuarios();
    await this.loadPerfis();
    await this.loadIdiomas();
    await this.loadHoteis();
  
  },
  methods: {
  teste() {
    console.log(this.editedItem.hoteis)
    console.log(this.hoteisStr)
  },
    novoUsuario() {
      this.editedItem = {};
      this.senha0 = ''
      this.senha1 = ''  
      this.editedItem.hoteis = this.hoteisStr;
      this.hotelPadrao = this.editedItem.hoteis[0]
      this.editedIndex = -1;
      this.dialog=true;
     // this.idtipousuario = 0
    },
    editarUsuario(item, dbox = true) {
      this.editedIndex = this.usuarios.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.senha0 = ''
      this.senha1 = ''
      this.hotelPadrao = this.getHotelPadrao(this.editedItem.hotel,'id','nome')
      this.idtipousuario = this.editedItem.idtipousuario
      this.dialog = dbox;
    },
    loadUsuarios() {
      this.$http
        .post("/perfil/usuario/select")
        .then((response) => {
          this.usuarios = response.data;
        })
        .catch((error) => console.log(error));
    },
    loadIdiomas() {
      this.$http
        .post("/geo/idiomas/suportados")
        .then((response) => {
          this.idiomas = response.data;
        })
        .catch((error) => console.log(error));
    },
    loadHoteis() {
      this.$http
        .post("/perfil/hotel/select")
        .then((response) => {
          this.hoteis = response.data;
          for (var i = 0; i < this.hoteis.length; i++) {
            this.hoteisStr.push(this.hoteis[i]['nome'])
          }
        })
        .catch((error) => console.log(error));
    },
    getHotelPadrao(value,find,ret) {
      var selected = -1
      for (var i = 0; i < this.hoteis.length; i++) {
        if (value == this.hoteis[i][find]) {
          selected = this.hoteis[i][ret]
          break
        }
      }
      return selected
    },
    getPerfilUsuario(value,find,ret) {
      var selected = -1
      for (var i = 0; i < this.perfis.length; i++) {
        if (value == this.perfis[i][find]) {
          selected = this.perfis[i][ret]
          break
        }
      }
      return selected
    },
    getHoteisSelecionados() {
      var hoteisSels = []
      for (var i = 0; i < this.editedItem.hoteis.length; i++) {
        for (var j = 0; j < this.hoteis.length; j++) {
          if (this.editedItem.hoteis[i] == this.hoteis[j]['nome']) {
            hoteisSels.push(this.hoteis[j]['id'])
            break
          }
        }
      }
      return hoteisSels
    },
    loadPerfis(){
        let body = {
            ativo : this.ativos
        }
        this.$http.post('/auth/listartiposusuarios', body).then(response =>{
            if(response.status == 200){
                this.perfis = response.data
            }else{
                this.perfis = []
            }
        }).catch(error =>{
            console.log(error)
        })
    },
    // validatefieds() {
    //   this.btn = this.$refs.form.validate();
    // },
    focus(id) {
      var idEl = document.getElementById(id)
      if (idEl) idEl.focus()
    },
    validarFormulario(){

      if(!this.editedItem.apelido){
            this.snack = true;
            this.snackColor = "error";
            this.snackText = "Apelido é um Campo Obrigatório";
            return false
      }

      if(!this.editedItem.id){

          if(!this.senha0){
                this.snack = true;
                this.snackColor = "error";
                this.snackText = "Senha é um Campo Obrigatório";
                return false
          }

          if(!this.senha1){
                this.snack = true;
                this.snackColor = "error";
                this.snackText = "Confirmação de Senha é um Campo Obrigatório";
                return false
          }

          
          if (this.senha0 && this.senha1) {
              if (this.senha0 != this.senha1) {
                this.snack = true;
                this.snackColor = "error";
                this.snackText = "As senhas estão diferentes";
                return false
              }
          }

      }

      if(!this.editedItem.nome){
            this.snack = true;
            this.snackColor = "error";
            this.snackText = "Nome é um Campo Obrigatório";
            return false
      }

     

      if(!this.editedItem.idioma){
            this.snack = true;
            this.snackColor = "error";
            this.snackText = "Idioma é um Campo Obrigatório";
            return false
      }
      
      if(!(/.+@.+\..+/.test(this.editedItem.email))){
            this.snack = true;
            this.snackColor = "error";
            this.snackText = "Email Inválido";
            return false
      }

      if(!this.editedItem.telefone){
            this.snack = true;
            this.snackColor = "error";
            this.snackText = "Telefone é um Campo Obrigatório";
            return false
      }

      if(this.editedItem.telefone.length < 13){
            this.snack = true;
            this.snackColor = "error";
            this.snackText = "Telefone Inválido, informe o DDI + DDD + Telefone";
            return false
      }

      if(this.getHoteisSelecionados().length == 0){
            this.snack = true;
            this.snackColor = "error";
            this.snackText = "Vincule pelo menos um hotel ao usuario";
            return false
      }

      if(!this.hotelPadrao){
            this.snack = true;
            this.snackColor = "error";
            this.snackText = "Escolha um hotel padrão.";
            this.focus('selectHotelPadrao')
            return false
      }
      

      
      return true
      
    },
    atualizarUsuario() {
      if(this.validarFormulario()){
        var params = {
          tipo: 'F',
          nome: this.editedItem.nome,
          apelido: this.editedItem.apelido,
          idioma: this.editedItem.idioma?this.editedItem.idioma:'pt-BR',
          email: this.editedItem.email,
          telefone: this.editedItem.telefone,
          idtipousuario : this.idtipousuario
        }
        if (this.editedItem.id>0) {
          params.id = this.editedItem.id
        }
        if (this.senha0 && this.senha1) {
          if (this.senha0 === this.senha1) {
            params.senha = this.senha0;
          } else {
            this.snack = true;
            this.snackColor = "error";
            this.snackText = "Senhas não estão iguais";
            return
          }
        }
        params.hoteis = this.getHoteisSelecionados()
        params.hotel = this.getHotelPadrao(this.hotelPadrao,'nome','id')
        // Acessos padrão
        const idhotel = localStorage.getItem('hotel')
        params.acessos = [
            {"hotel": idhotel, "menu": 50, "nivel": 4 },
            {"hotel": idhotel, "menu": 51, "nivel": 4 },
            {"hotel": idhotel, "menu": 52, "nivel": 4 },
            {"hotel": idhotel, "menu": 53, "nivel": 4 },
            {"hotel": idhotel, "menu": 54, "nivel": 4 },
            {"hotel": idhotel, "menu": 55, "nivel": 4 },
            {"hotel": idhotel, "menu": 56, "nivel": 4 },
            {"hotel": idhotel, "menu": 57, "nivel": 4 },
            {"hotel": idhotel, "menu": 58, "nivel": 4 },
            {"hotel": idhotel, "menu": 59, "nivel": 4 },
            {"hotel": idhotel, "menu": 60, "nivel": 4 },
            {"hotel": idhotel, "menu": 61, "nivel": 4 },
            {"hotel": idhotel, "menu": 62, "nivel": 4 },
            {"hotel": idhotel, "menu": 63, "nivel": 4 },
            {"hotel": idhotel, "menu": 64, "nivel": 4 }
        ];
        console.log(JSON.stringify(params))
        this.$http
          .post("/perfil/usuario/upinsert", params)
          .then((response) => {
            if (response.status == 200) {
              this.loadUsuarios();
              this.saveInline();
              this.fecharAtualizacao();
              this.enviarEmail(this.editedItem.nome,this.editedItem.email,this.senha0)
            } else {
              // Verificar se é o caso de Email ou apelido já existente.
              if (response.status == 206) {
                this.snack = true;
                this.snackColor = "error";
                this.snackText = response.data.message;
              } else {
                console.log(JSON.stringify(response))
              }
            }
          })
          .catch(error =>{
            console.log(error)
            console.log(JSON.stringify(params))
          });
      }
    },
    enviarEmail(nome,email,senha){
      this.$http.post("/auth/emailusuarionovo", {  nome:nome,email:email,senha:senha,hotel:localStorage.getItem("hotel")})
        .then((response) => {
              this.ReservasList = response.data;
        })
        .catch((error) => {
          console.log(error)
        });
    },
    callTableAction(item, endpoint, method) {
      const index = this.usuarios.indexOf(item);
      let tableItem = this.editedItem;
      this.$store
        .dispatch("updateTableItem", { endpoint, tableItem, method })
        .then((response) => {
          console.log(response)
          this.usuarios.splice(index, 1);
          this.saveInline();
        })
        .catch((error) => {
          console.log(error);
          this.cancelInline;
        });
    },
    deleteItem(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        this.editedItem = Object.assign({}, item);
        let endpoint = `users/delete/${this.editedItem.username}`;
        let method = "delete";
        this.callTableAction(item, endpoint, method);
      }
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    fecharAtualizacao() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    saveInline() {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Registro salvo com Sucesso!";
    },
    cancelInline() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Operação Cancelada";
    },
    reset() {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data reset to default";
    },
    openInline() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    errorSave() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Erro ao salvar usuário";
    },
    closeInline() {
      console.log("Dialog closed");
    },
    setModalPerfisUsuarios(){
      this.exibirModalPerfisUsuarios = !this.exibirModalPerfisUsuarios
    }
  },
};
</script>
<style>
table.v-table thead tr {
  color: red !important;
}
.snackClass {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
  justify-content: center;
}
</style>