<template>
    <v-dialog v-model="exibirModalEditarCheckinPayGeral" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Editar Configurações Geral Checkin Pay
                    </span>
                    <span style="width:5%">
                        <BotaoTutorial style="margin-right:30px;" urlVideo="" categoria="" legenda="Dúvidas Configurações Geral Checkin Pay"/>
                    </span> 
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="setModalPassosCheckinPayGeral()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>
<!-- 
                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="exibirPassosCovid" data-cy="exibirPassosCovid" style="border-radius:5px;padding:5px;"></v-switch>
                        <label >Exibir Passo <b>Formulario Covid</b> ?</label>  
                        
                    </div> -->

                   

                    


<!-- 
                    <div class="cardInputs" style="margin-top:10px;">
                        <label class="labels">Email Adm <span style="font-size:15px!important;">( Recebe Notificações De Web Checkins Realizados)</span></label>
                        <input type="text" class="inputs" v-model="emailAdmWebCheckin" data-cy="emailAdmWebCheckin" placeholder="Informe o email que receberá notificações de webcheckin realizados" />
                    </div>

                    <div class="cardInputs">
                        <label class="labels">Email Adm Secundário <span style="font-size:15px!important;">( Recebe Notificações De Web Checkins Realizados)</span></label>
                        <input type="text" class="inputs" v-model="emailAdmWebCheckinSecundario" data-cy="emailAdmWebCheckinSecundario" placeholder="Informe um segundo email que receberá notificações de webcheckin realizados" />
                    </div> -->

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="flReconhecimentoFacial" style="border-radius:5px;padding:2px;" id="checkbox-credito"></v-switch>
                        <label>Liberar Reconhecimento Facial</label>    
                    </div>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="flCapturaFotoCartao" style="border-radius:5px;padding:2px;" id="checkbox-credito"></v-switch>
                        <label>Capturar frente e verso do cartão</label>    
                    </div>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="flCapturaComprovanteEndereco" style="border-radius:5px;padding:2px;" id="checkbox-credito"></v-switch>
                        <label>Capturar comprovante de endereço</label>    
                    </div>
                    <div class="cardInputs" style="">
                        <label class="labels">Enviar Checkin Pay Somente para Hospedes com Empresa Reservante </label>
                        <span data-cy="botaocor-span" style="">
                            <v-select  outlined style="border-radius:15px;" id="canal-venda-cpay"
                                :items="listaempresareservantes" 
                                v-model="empresareservanteselecionada"
                                item-value="id"
                                item-text="nome" return-object
                                data-cy="botaocor" required>
                            </v-select>
                        </span>
                    </div>

                    <div class="cardInputs">
                        <label class="labels">Cancelar Voucher de Pagamento apos quantos dias depois do envio?</label>
                        <input type="number" class="inputs" v-model="qtdediascancelamento" data-cy="qtdediascancelamento" id="qtde-dias-cancelamento-cpay" />
                    </div>

                  
                    <div class="cardInputs">
                        <label class="labels">Assunto do Email do WebCheckin</label>
                        <input type="text" class="inputs" v-model="assuntodocheckinpay" data-cy="assuntodocheckinpay" placeholder="Assunto" id="texto-assunto-cpay" />
                        <label class="labels">Texto do Email do WebCheckin</label>
                        <v-textarea outlined name="input-7-4" style="border-radius:10px;" v-model="textodocheckinpay" data-cy="textodocheckinpay" id="texto-email-cpay" ></v-textarea>
                    </div>
                    

                    

                   

                    <div style="display:flex;justify-content:flex-end;margin-top:50px;">
                        <v-btn @click="salvar()" data-cy="salvar" color="primary" id="btn-cpay-salvar-gerais">
                            {{textobotaosalvar}}
                        </v-btn>
                    </div>

            </v-card-text>
            
                        
            
        </v-card>
    </v-dialog>
</template>          

<script>

import chroma from "chroma-js";
import BotaoTutorial from "../../material/BotaoTutorial.vue";

export default {
    data: () => ({
        flReconhecimentoFacial: false,
        flCapturaFotoCartao: false,
        flCapturaComprovanteEndereco: false,
        textoEmail:"Olá @nome, te desejamos um feliz aniversário repleto de felicidades.",
        isImg:null,
        imgSrc:null,
        imgTipo:null, 
        exibirPassosCovid:null,
        exibirPassosObservacao:null,
        exibirPassosHorario:null,
        emailAdmWebCheckin:null,
        emailAdmWebCheckinSecundario:null,
        idadelimitecrianca:null,
        estilos:['AZUL','VERDE','VERMELHO'],
        estilo:'AZUL',
        botaocores:['AZUL','VERDE','VERMELHO'],
        botaocor:'AZUL',
        layoutbotaoselecionado:'AZUL',
        assuntodocheckinpay:null,
        textodocheckinpay:null,
        textobotaosalvar:"SALVAR",
        horariocheckin:null,
        horariocheckout:null,
        finalizarFicha:false,
        necessidadeSelfie: false,
        infohorarioCheckin: null,
        infohorarioCheckout: null,
        infohorario: {
            checkin: null,
            checkout: null
        },
        corPrincipal: null,
        corSecundaria: null,
        corFundo: null,
        listaempresareservantes:[],
        empresareservanteselecionada:null,
        qtdediascancelamento:1,
    }),
    props:["exibirModalEditarCheckinPayGeral"],
    components:{
    BotaoTutorial
},
    watch: {
        corPrincipal: function(){
            this.corSecundaria = chroma.scale([this.corPrincipal.hex, 'white']).colors(6)[2]
            this.corFundo = chroma.scale([this.corSecundaria, 'white']).colors(6)[4]
        }
    },
    methods: {
        mudarCorBotao(cor){

            if(cor == 'AZUL'){
                this.layoutbotaoselecionado = `<button onclick="alert('teste')" title="Faça agora seu Checkin Digital"
                                        style="position: fixed;bottom: 20px;right: 30px;
                                        z-index: 9999;border: none;outline: none;background-color: #1560e5;color: white;
                                        cursor: pointer;padding: 15px;border-radius: 10px;height:4em;width:10em;
                                        display:flex;justify-content: center;align-items: center;">Fazer Check-In</button>`
            }

            if(cor == 'VERDE'){
                this.layoutbotaoselecionado = `<button onclick="alert('teste')" title="Faça agora seu Checkin Digital"
                                        style="position: fixed;bottom: 20px;right: 30px;
                                        z-index: 9999;border: none;outline: none;background-color: #15e57d;color: white;
                                        cursor: pointer;padding: 15px;border-radius: 10px;height:4em;width:10em;
                                        display:flex;justify-content: center;align-items: center;">Fazer Check-In</button>`
            }

            if(cor == 'VERMELHO'){
                this.layoutbotaoselecionado = `<button onclick="alert('teste')" title="Faça agora seu Checkin Digital"
                                        style="position: fixed;bottom: 20px;right: 30px;
                                        z-index: 9999;border: none;outline: none;background-color: #e51515;color: white;
                                        cursor: pointer;padding: 15px;border-radius: 10px;height:4em;width:10em;
                                        display:flex;justify-content: center;align-items: center;">Fazer Check-In</button>`
            }

        },
        salvar(){
            
            this.textobotaosalvar = "SALVANDO..."
            this.salvarConfig()
            this.salvarTextoWebCheckin().then(()=>{
                this.salvarEmpresaReservante().then(()=>{
                    this.setModalPassosCheckinPayGeral()
                })
            })
        },
        async salvarConfig() {
            try {
                await this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'CONFIGGERALCAPTURASCHECKINPAY',
                    "valor" : this.flReconhecimentoFacial === true ? 'true' : 'false',
                    "valor2" : this.flCapturaFotoCartao === true ? 'true' : 'false',
                    "valor3" : this.flCapturaComprovanteEndereco === true ? 'true' : 'false',
                })
            } catch (error) {
                console.log('Erro ao salvar configuração');
            }
        },
        salvarTextoWebCheckin(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'TEXTOCHECKINPAY',
                    "valor" : this.assuntodocheckinpay,
                    "valor2" : this.textodocheckinpay
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
            
        },
        salvarEmpresaReservante(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'ENVIOCHECKINPAYEMPRESAESERVANTE',
                    "valor" : this.empresareservanteselecionada.id,
                    "valor2" : this.empresareservanteselecionada.nome,
                    "valor3" : this.qtdediascancelamento
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
            
        },
        setModalPassosCheckinPayGeral(){
            this.textobotaosalvar = "SALVAR"
            this.$emit('setModalEditarCheckinPayGeral')
        },
    },
    async mounted() {

        this.listaempresareservantes = await this.$http.post("reserva/listarempresareservante").then( resp => resp.data)
        // alert(JSON.stringify(this.listaempresareservantes,null,2))

        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'ENVIOCHECKINPAYEMPRESAESERVANTE'
            }).then(resp =>{
                if(resp.status == 200){
                    // this.empresareservanteselecionada = resp.data[0].valor
                    this.empresareservanteselecionada = {
                            id:parseInt(resp.data[0].valor),
                            nome:resp.data[0].valor2
                    }

                    this.qtdediascancelamento = resp.data[0].valor3

                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })
        

        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'TEXTOCHECKINPAY'
            }).then(resp =>{
                if(resp.status == 200){
                    this.assuntodocheckinpay = resp.data[0].valor
                    this.textodocheckinpay = resp.data[0].valor2
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

            
        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'CONFIGGERALCAPTURASCHECKINPAY'
            }).then(resp =>{
                if(resp.status == 200){
                    this.flReconhecimentoFacial = resp.data[0].valor === 'true' ? true : false
                    this.flCapturaFotoCartao = resp.data[0].valor2 === 'true' ? true : false
                    this.flCapturaComprovanteEndereco = resp.data[0].valor3 === 'true' ? true : false
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

    },
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
    height:200px;
}

  .inputs{
    background-color:white;
    border-radius:15px;
    min-height:8vh;
    padding:5px 20px 5px 20px;
    border: 1px solid #819ab4;
  }

  .cardInputs{
    display:flex;
    flex-direction:column;
    justify-content:left;
    width:100%;
    margin-bottom:20px;
    /* background-color:red; */
    
  }

  
  .inputsHorario{
    background-color:white;
    text-align:center;
    border-radius:15px;
    min-height:8vh;
    font-size:25px;
    padding:10px;
    /* padding:5px 20px 5px 20px; */
    border: 1px solid #819ab4;
    width:100px; 
    margin-right: 20px;
    margin-top:10px;    
    
  }
</style>