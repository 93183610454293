<template>
    <div class="wout-principal">
        <!-- Cabeçalho -->
        <div class="wout-topo">
            <div style="width:15%;">
                <button class="wout-menu-voltar-btn" >
                    <v-icon color="white">mdi-arrow-left</v-icon>
                </button>
            </div>
            <div class="white--text text-subtitle-2 px-2" style="width:70%;">
                <div v-if="dados.nome">{{dados.nome}}</div>
                <div class="wout-apto-reserva" v-if="dados">Apto.{{dados.uh}} Reserva: {{dados.numeroreserva}}</div>
            </div>
            <div class="wout-power">
                <v-icon style="font-size: 28px!important;" color="white" >mdi-power</v-icon>
            </div>
        </div>
        <!-- Conteúdo -->
        <div class="wout-conteudo">
            <!-- Resumo -->
            <br>
           
            <div class="wout-cartao" >
                <div class="wout-row">
                    <div class="mx-2">
                        <div class="wout-label"><b>Data check-in:</b></div>
                        <div class="wout-campo">
                            {{dados.datacheckinprevisto | formatarDataYYHora}}
                            <v-icon small>mdi-calendar</v-icon>
                        </div>
                    </div>
                    <div class="mx-2">
                        <div class="wout-label"><b>Data check-out:</b></div>
                        <div class="wout-campo">
                            {{dados.datacheckoutprevisto | formatarDataYYHora}}
                            <v-icon small>mdi-calendar</v-icon>
                        </div>
                    </div>
                </div>
                <div class="wout-row-center">
                    <div class="wout-campo-saldo">
                        <div class="wout-saldo">-R$ {{dados.saldo | formatarMoeda}}</div>
                        <div class="wout-label-saldo"><b>Saldo em aberto</b></div>
                    </div>
                </div>
            </div>
            <div class="wout-toggler">Acessar Consumo Individual 
                <v-icon>mdi-chevron-down</v-icon></div>
            <!-- Individual -->
            <div v-if="exibirDetalhes">
                <div class="wout-scrollable">
                    <div class="wout-cartao-item" v-for="(hospede, index) in dados.hospedes" :key="index">
                        <div class="wout-row" style="cursor:pointer;"
                           
                        >
                            <div style="width:70%;padding:0px;padding-left:0.5rem;">
                                <div>{{hospede.nome}} {{hospede.sobrenome}}</div>
                                <div class="wout-green-label">Abrir extrato</div>
                            </div>
                            <div style="width:30%;text-align:right;padding:0px;padding-right:0.5rem;align-self:center;">
                                <span class="wout-saldo-individual">-R$ {{hospede.saldoaberto | formatarMoeda}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Pagar Total -->
            <div style="display:flex;justify-content:center;padding-top:2vh;">
                <button class="wout-pagar-btn" :disabled="dados.itenscontestados > 0 || dados.saldo <= 0.0 || dados.checagens > 0 || checagemenviada > 0"                    
                    id="start_button"
                >
                    <span class="wout-sinal-moeda">$</span>
                    Efetuar Pagamento <span class="green--text">Total</span>
                    <v-icon>mdi-arrow-right</v-icon>
                </button>
            </div>
            <div class="wout-toggler" style="display:flex;justify-content:center;padding-top:2vh;"
                v-if="dados.itenscontestados > 0">
                Há ítens de consumo em contestação.
            </div>
            <!-- Consumo Frigobar -->
            <div style="display:flex;justify-content:center;padding-top:2vh;">
                <button class="wout-pagar-btn" v-if="!dados.checarFrigobar">
                    <v-icon>mdi-fridge-industrial-outline </v-icon>
                    Consumo do Frigobar
                    <v-icon>mdi-arrow-right</v-icon>
                </button>

                <button :disabled="dados.checagens > 0 || checagemenviada > 0" class="wout-pagar-btn"  v-else>
                    <v-icon>mdi-fridge-industrial-outline </v-icon>
                    Solicitar Checagem Frigobar
                    <v-icon>mdi-arrow-right</v-icon>
                </button>

            </div>
        </div>
    </div>
</template>

<script>

export default {      
    props:['dados'],
    data: () =>{
        return{
            mostrarCarregando:false,
            exibirDetalhes: true,
            checagemenviada:0
        }
    },
    watch: {
        dados(val) {
            this.dados = val;
        },
    },
    methods: {

        
    },
    mounted(){
        this.$emit('importarConsumoPreInicio', {})
    }
}
</script>