<template>
    <div>
        <IntroWOut        v-if="componenteatual == 'introwout'"       v-bind:dados="dados" @direcionar="(valor) => direcionar(valor)" />
        <PreLogin         v-if="componenteatual == 'prelogin'"        v-bind:dados="dados" @direcionar="(valor) => direcionar(valor)" />
        <!-- <Login            v-if="componenteatual == 'login'"           v-bind:dados="dados" @direcionar="(valor) => direcionar(valor)" /> -->
        <component :is="componenteLogin" v-if="componenteatual == 'login'" v-bind:dados="dados" @direcionar="(valor) => direcionar(valor)"></component>
        <PreInicio        v-if="componenteatual == 'preinicio'"       v-bind:dados="dados" @direcionar="(valor) => direcionar(valor)" @importarConsumoPreInicio="()=>importarConsumoPreInicio()" :mostrarCarregando="mostrarCarregando" />
        <Inicio           v-if="componenteatual == 'inicio'"          v-bind:dados="dados" @direcionar="(valor) => direcionar(valor)" :mostrarCarregando="mostrarCarregando" />
        <Individual       v-if="componenteatual == 'individual'"      v-bind:dados="dados" @direcionar="(valor) => direcionar(valor)" />
        <PrePagamento     v-if="componenteatual == 'prepagamento'"    v-bind:dados="dados" @direcionar="(valor) => direcionar(valor)" />
        <ConsumoFrigobar  v-if="componenteatual == 'consumofrigobar'" v-bind:dados="dados" @direcionar="(valor) => direcionar(valor)" />
        <Pagamento        v-if="componenteatual == 'pagamento'"       v-bind:dados="dados" @direcionar="(valor) => direcionar(valor)" @registrarPagamento="(dadoscartao) => registrarPagamento(dadoscartao)" />
        <DadosPix         v-if="componenteatual == 'dadospix'"        v-bind:dados="dados" @direcionar="(valor) => direcionar(valor)" />
        <AvisoSelf        v-if="componenteatual == 'avisoself'"       v-bind:dados="dados" @direcionar="(valor) => direcionar(valor)" />
        <Self             v-if="componenteatual == 'self'"            v-bind:dados="dados" @direcionar="(valor) => direcionar(valor)" @registrarPagamento="(dadoscartao) => registrarPagamento(dadoscartao)" />
        <FalhaReconhecimento  v-if="componenteatual == 'falhareconhecimento'"   v-bind:dados="dados" @direcionar="(valor) => direcionar(valor)" />
        <Processando      v-if="componenteatual == 'processando'"     v-bind:dados="dados" @direcionar="(valor) => direcionar(valor)" />
        <PgtoAprovado     v-if="componenteatual == 'pgtoaprovado'"    v-bind:dados="dados" @direcionar="(valor) => direcionar(valor)" />
        <PgtoNegado       v-if="componenteatual == 'pgtonegado'"      v-bind:dados="dados" @direcionar="(valor) => direcionar(valor)" />
        <Pronto           v-if="componenteatual == 'pronto'"          v-bind:dados="dados" @direcionar="(valor) => direcionar(valor)" />
        <Desconto         v-if="componenteatual == 'desconto'"        v-bind:dados="dados" @direcionar="(valor) => direcionar(valor)" />
        <Proxima          v-if="componenteatual == 'proxima'"         v-bind:dados="dados" @direcionar="(valor) => direcionar(valor)" />
        <BoaViagem        v-if="componenteatual == 'boaviagem'"       v-bind:dados="dados" @direcionar="(valor) => direcionar(valor)" />
        <pagamentos-confirmar :show="mostrarCarregando" 
            :titulo="'Carregando...'"
            :texto="'Aguarde, estamos carregando o consumo.'"
            :hidebuttons="true" />
    </div>
</template>

<script>
  import IntroWOut from './webcheckout/IntroWOut'
  import PreLogin from './webcheckout/PreLogin'
//   import Login from './webcheckout/Login'
  import Inicio from './webcheckout/Inicio'
  import PreInicio from './webcheckout/PreInicio'
  import Individual from './webcheckout/Individual'
  import PrePagamento from './webcheckout/PrePagamento'
  import ConsumoFrigobar from './webcheckout/ConsumoFrigobar'
  import Pagamento from './webcheckout/Pagamento'
  import DadosPix from './webcheckout/DadosPix'
  import AvisoSelf from './webcheckout/AvisoSelf'
  import Self from './webcheckout/Self'
  import FalhaReconhecimento from './webcheckout/FalhaReconhecimento'
  import Processando from './webcheckout/Processando'
  import PgtoAprovado from './webcheckout/PgtoAprovado'
  import PgtoNegado from './webcheckout/PgtoNegado'
  import Pronto from './webcheckout/Pronto'
  import Desconto from './webcheckout/Desconto'
  import Proxima from './webcheckout/Proxima'
  import BoaViagem from './webcheckout/BoaViagem'
  import PagamentosConfirmar from '@/components/DashViews/PagamentosConfirmar.vue';
  import { BUILD_TIPO_INTEGRACAO } from '../constantes';
  import Login_Documento from './webcheckout/Login_Documento'
  import Login_UH from './webcheckout/Login_UH'

  export default {
    name: 'Webcheckout',

    components: {
      IntroWOut,
      PreLogin,
      //Login,
      Inicio,
      PreInicio,
      Individual,
      PrePagamento,
      ConsumoFrigobar,
      Pagamento,
      DadosPix,
      AvisoSelf,
      Self,
      FalhaReconhecimento,
      Processando,
      PgtoAprovado,
      PgtoNegado,
      Pronto,
      Desconto,
      Proxima,
      BoaViagem,
      PagamentosConfirmar,
      Login_Documento,
      Login_UH
    },
    data: () =>{
        return{
            imgSrc:null,
            imgTipo:null,
            usuariologado:false,
            componenteatual:"",
            dados:{},
            idhotel: null,
            mostrarCarregando:false,
            componenteLogin: 'Login_Documento'
        }
    },
    async mounted() {
        document.title = 'Checkout Express'
        this.componenteatual = 'introwout'
        this.$socket.disconnect();

            this.$socket.io.opts.query.usuario = localStorage.getItem('checkout-email')
            this.$socket.io.opts.query.tipo = 'WEBCHECKOUT'
            this.$socket.io.opts.query.tipo_acesso = 'WEBCHECKOUT_'+localStorage.getItem('checkout-email')
            this.$socket.connect();

            // this.sockets.unsubscribe("recarregarDadosIniciais");
            this.sockets.subscribe("recarregarDadosIniciais",  async (dados) => {

                console.log('recarregarDadosIniciais  dados  ',dados)
                this.carregarDadosInicio({from:'preinicio',to:'inicio'})
                if(dados && dados.msg){
                        alert(dados.msg)
                }

                if(BUILD_TIPO_INTEGRACAO === 'HITS'){

                        // let params = {
                        //     idhotel:localStorage.getItem('checkout-hotel'),
                        //     uh:localStorage.getItem('checkout-uh'),
                        //     numeroreserva:localStorage.getItem('checkout-numeroreserva'),
                        //     idreservahospede:localStorage.getItem('checkout-idreservahospede')
                        // }
                        //await this.$http.post('/webcheckout/importarConsumosCheckoutApi', params,{ headers: {'x-access-token': localStorage.getItem('checkout-token')}})
                        //        .then(() =>{
                                    this.mostrarCarregando=false
                                    this.carregarDadosInicio({to:'inicio', from:'inicio'})
                        //        }).catch(()=>{
                        //            this.mostrarCarregando=false
                        //        })

                }else{
                    this.$socket.emit("webckout_back_importarConsumo", {
                        idhotel:localStorage.getItem('checkout-hotel'),
                        numeroreserva:localStorage.getItem('checkout-numeroreserva'),
                        usuario:localStorage.getItem('checkout-email'),
                        idreservahospede:localStorage.getItem('checkout-idreservahospede'),
                        uh:localStorage.getItem('checkout-uh')
                    });
                }

            });
    },
    async created(){
        this.idhotel = await this.$route.params.token
        if (!this.idhotel) this.idhotel = 1
        await this.buscarLoginConfig()
    },
    async beforeMount(){
        var params = {
            "id": this.idhotel?this.idhotel:1,
            "logo": true
        }
        await this.$http.post('/perfil/hotel/basic/select', params)
        .then(async resp => {
            try {
                await import('./webcheckout/folhadeestilos/'+resp.data[0].params.WEBCKOUT_ESTILO.toLowerCase()+'.css')
            } catch {
                await import('./webcheckout/folhadeestilos/'+resp.data[0].nome_estilo.toLowerCase()+'.css')
            }
            var imgObj = resp.data[0].logotipo
            if (imgObj) {
                this.imgSrc = Buffer.from(resp.data[0].logodados).toString()
                this.imgTipo = resp.data[0].logotipo;
            }
        })
        .catch(async error =>{
            await import('./webcheckout/folhadeestilos/azul.css')
            console.log(error) 
        })
    },
    methods: {
        async buscarLoginConfig() {
            this.$http
            .post("/webcheckout/login/config", {
                idhotel: localStorage.getItem("hotel"),
                chave: "WEBCHECKOUT_CONFIG_LOGIN",
            })
            .then((resp) => {
                if (resp.status == 200) {
                if (resp.data.length > 0) {
                    const obj = resp.data[0]
                    const cfg = JSON.parse(obj.valor)
                    this.componenteLogin = cfg.tipo === 'Documento'?'Login_Documento':'Login_UH'
                    this.dados.exibirLGPD = cfg.exibirLGPD;
                }
                } else {
                console.error("Não foi possivel buscar a configuração.");
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        direcionar(rota){
            switch (rota.to) {
                case 'login':
                    this.carregarHoteis(this.$route.params.token)
                    break;
                case 'inicio':
                    this.carregarDadosInicio(rota)
                    break;
                case 'individual':
                    this.dados.checagens = rota.checagens
                    this.carregarDadosIndividual(rota)
                    break
                case 'prepagamento':
                    this.carregarDadosPrepagamento(rota)
                    break;
                case 'consumofrigobar':
                    this.carregarDadosFrigobar(rota)
                    break;
                case 'pagamento':
                    this.carregarDadosPagamento(rota)
                    break;
                case 'avisoself':
                    // this.carregarDadosPagamento(rota)
                    this.dados.dadoscartao = rota.dados
                    this.componenteatual = rota.to
                    break;
                default:
                    //console.error('default')
                    this.componenteatual = rota.to
            }            
        },
        calcularSaldo(hospedes) {
            var total = 0.0
            if (hospedes && Array.isArray(hospedes)) {
                for (var i = 0; i < hospedes.length; i++) {
                    var umHosp = hospedes[i]
                    var saldo
                    try {saldo = parseFloat(umHosp.saldoaberto)}
                        catch (e) {saldo = 0}
                    total += saldo
                }
            }
            return total.toFixed(2)
        },
        somarItensContestados(hospedes) {
            var totalItensContestados = 0
            if (hospedes && Array.isArray(hospedes)) {
                for (var i = 0; i < hospedes.length; i++) {
                    var umHosp = hospedes[i]
                    totalItensContestados += parseInt(umHosp.itenscontestados)
                }
            }
            return totalItensContestados
        },
        somarChecagens(hospedes) {
            var totalChecagens = 0
            if (hospedes && Array.isArray(hospedes)) {
                for (var i = 0; i < hospedes.length; i++) {
                    var umHosp = hospedes[i]
                    totalChecagens += parseInt(umHosp.checagens)
                }
            }
            return totalChecagens
        },
        async importarConsumo() {
            this.mostrarCarregando = true
            this.$socket.disconnect();
            this.$socket.io.opts.query.usuario = localStorage.getItem('checkout-email')
            this.$socket.io.opts.query.tipo = 'WEBCHECKOUT'
            this.$socket.io.opts.query.tipo_acesso = 'WEBCHECKOUT_'+localStorage.getItem('checkout-email')
            this.$socket.connect();

                if(BUILD_TIPO_INTEGRACAO === 'HITS'){

                        let params = {
                            idhotel:localStorage.getItem('checkout-hotel'),
                            uh:localStorage.getItem('checkout-uh'),
                            numeroreserva:localStorage.getItem('checkout-numeroreserva'),
                            idreservahospede:localStorage.getItem('checkout-idreservahospede')
                        }
                        await this.$http.post('/webcheckout/importarConsumosCheckoutApi', params,{ headers: {'x-access-token': localStorage.getItem('checkout-token')}})
                                .then(() =>{
                                    this.mostrarCarregando=false
                                    this.carregarDadosInicio({to:'inicio', from:'inicio'})
                                }).catch(()=>{
                                    this.mostrarCarregando=false
                                })

                }else{
                                
                        this.$socket.emit("webckout_back_importarConsumo", {
                            idhotel:localStorage.getItem('checkout-hotel'),
                            numeroreserva:localStorage.getItem('checkout-numeroreserva'),
                            usuario:localStorage.getItem('checkout-email'),
                            idreservahospede:localStorage.getItem('checkout-idreservahospede'),
                            idhospedepms: localStorage.getItem('checkout-idhospedepms'),
                        idhospede: localStorage.getItem('checkout-idhospede'),
                        });
                        this.sockets.subscribe("fecharModalCarregando", (dados) => {
                        this.mostrarCarregando=false
                        this.sockets.unsubscribe("fecharModalCarregando")
                        console.log(dados.msg)
                            //   this.$emit('direcionar', {to:'inicio', from:'inicio'})
                            this.carregarDadosInicio({to:'inicio', from:'inicio'})
                        });

                }

            // this.sockets.subscribe("recarregarDadosIniciais", () => {
            //     alert('recarregando')
            // //   this.carregarDadosInicio({from:'preinicio',to:'inicio'})
            // });
            
        },
        async importarConsumoPreInicio() {
            
            this.mostrarCarregando = true
            this.$socket.disconnect();
            this.$socket.io.opts.query.usuario = localStorage.getItem('checkout-email')
            this.$socket.io.opts.query.tipo = 'WEBCHECKOUT'
            this.$socket.io.opts.query.tipo_acesso = 'WEBCHECKOUT_'+localStorage.getItem('checkout-email')
            this.$socket.connect();

            if(BUILD_TIPO_INTEGRACAO === 'HITS'){

                    let params = {
                        idhotel:localStorage.getItem('checkout-hotel'),
                        uh:localStorage.getItem('checkout-uh'),
                        numeroreserva:localStorage.getItem('checkout-numeroreserva'),
                        idreservahospede:localStorage.getItem('checkout-idreservahospede')
                    }
                    console.error(JSON.stringify(params))
                    await this.$http.post('/webcheckout/importarConsumosCheckoutApi', params,{ headers: {'x-access-token': localStorage.getItem('checkout-token')}})
                            .then(() =>{
                                this.mostrarCarregando=false
                                this.carregarDadosInicio({to:'inicio', from:'inicio'})
                            }).catch(()=>{
                                this.mostrarCarregando=false
                            })

            }else{

                if (BUILD_TIPO_INTEGRACAO === 'ADMH') {
                    console.log('admh')
                    const body = {
                        idhotel:localStorage.getItem('checkout-hotel'),
                        numeroreserva:localStorage.getItem('checkout-numeroreserva'),
                        usuario:localStorage.getItem('checkout-email'),
                        idreservahospede:localStorage.getItem('checkout-idreservahospede'),
                        uh:localStorage.getItem('checkout-uh'),
                        idhospedepms: localStorage.getItem('checkout-idhospedepms'),
                        idhospede: localStorage.getItem('checkout-idhospede'),
                    }
                    console.log('body admh', body)
                    this.$socket.emit("webckout_back_importarConsumo_admh", body);
                    this.sockets.subscribe("importarConsumosRetorno", (dados) => {
                        this.mostrarCarregando=false
                        this.sockets.unsubscribe("importarConsumosRetorno")
                        console.log('em importarConsumosRetorno... '+dados.msg)
                        //   this.$emit('direcionar', {to:'inicio', from:'inicio'})
                        this.direcionar({from:'preinicio',to:'inicio'})
                    });

                } else {
                    this.$socket.emit("webckout_back_importarConsumo", {
                        idhotel:localStorage.getItem('checkout-hotel'),
                        numeroreserva:localStorage.getItem('checkout-numeroreserva'),
                        usuario:localStorage.getItem('checkout-email'),
                        idreservahospede:localStorage.getItem('checkout-idreservahospede'),
                        uh:localStorage.getItem('checkout-uh')
                    });
                    this.sockets.subscribe("fecharModalCarregando", (dados) => {
                        this.mostrarCarregando=false
                        this.sockets.unsubscribe("fecharModalCarregando")
                        console.log('em fecharModalCarregando... '+dados.msg)
                        this.direcionar({from:'preinicio',to:'inicio'})
                    });
                }
            }
        },
        carregarDadosInicio (rota) {
            var numeroReserva = localStorage.getItem('checkout-numeroreserva')
            var idHotel = localStorage.getItem('checkout-hotel')
            this.$http.post("/webcheckout/getHospedesdaReserva",{
                    "numeroreserva": numeroReserva,
                    "idhotel": idHotel
                }, { headers: {'x-access-token': localStorage.getItem('checkout-token')}})
                .then(async result => {
                    
                    var cabecalhos = []
                    for (var i = 0; i < result.data.length; i++) {
                        var cabecalhos_hospede = result.data[i]['cabecalhos'].split(',')
                        for (var j = 0; j < cabecalhos_hospede.length; j++) {
                            cabecalhos.push(cabecalhos_hospede[j])
                        }
                    }
                    if (result.status === 200) {
                        this.dados = { hospedes: result.data, doc: this.dados.doc, docType: this.dados.docType }
                        console.log('dados>>>> ',this.dados)
                        try {
                        
                            await this.$http.post("webcheckout/buscarParametro", {  
                                        "idhotel" : idHotel,"chave" : 'WEBCKOUTCHECARFRIGOBAR'})
                                    .then(async resp =>{
                                        this.dados.checarFrigobar = resp.data[0].valor == 'true' ? true : false
                                    }).catch(error =>{ this.dados.checarFrigobar = false; console.log(error); })

                        } catch (error) {
                            console.log(error)   
                        }

                        this.dados.saldo = this.calcularSaldo(this.dados.hospedes) 
                        this.dados.nome = localStorage.getItem('checkout-nome')
                        this.dados.nomehotel = localStorage.getItem('checkout-nomehotel')
                        this.dados.uh = localStorage.getItem('checkout-uh')
                        this.dados.numeroreserva = numeroReserva
                        this.dados.datacheckinprevisto = localStorage.getItem('checkout-datacheckinprevisto')
                        this.dados.datacheckoutprevisto = localStorage.getItem('checkout-datacheckoutprevisto')
                        this.dados.idhotel = idHotel
                        this.dados.idreservahospede = localStorage.getItem('checkout-idreservahospede')
                        this.dados.idhospede = localStorage.getItem('checkout-idhospede')
                        this.dados.idhospedepms = localStorage.getItem('checkout-idhospedepms')
                        this.dados.globalcodehits = this.dados.hospedes[0].globalcodehits ? this.dados.hospedes[0].globalcodehits : 0
                        this.dados.email =  localStorage.getItem('checkout-email')
                        this.dados.cabecalhos = cabecalhos
                        this.dados.itenscontestados = this.somarItensContestados(this.dados.hospedes)
                        this.dados.checagens = this.somarChecagens(this.dados.hospedes)
                        console.log(rota.from)
                        console.log(process.env.NODE_ENV)

                        console.log('Dados montados ',JSON.stringify(this.dados))

                        this.componenteatual = rota.to

                    } else {
                        console.error(JSON.stringify(result))
                    }
                })
            .catch((e) => {
                console.error(e)
            })
        },
        carregarDadosIndividual(valor) {
            let saldoTotal = this.dados.saldo;
            this.$http.post("/webcheckout/getConsumos",{
                    "numeroreserva": valor.dados.numeroreserva,
                    "idhotel": valor.dados.hotel,
                    "idreservahospede": valor.dados.idreservahospede,
                    "statusconsumo": "1,2"
                }, { headers: {'x-access-token': localStorage.getItem('checkout-token')}})
                .then(result => {
                    this.componenteatual = valor.to
                    if (result.status == 200) {
                        this.dados = { consumos: result.data }
                        this.dados.cabecalhos = [result.data[0]['idcabecalho']]
                        // Caso o saldo individual seja > que o saldo em aberto, deixamos como saldo o salto total
                        // pois só podemos aceitar pagamento até o valor do saldo total da conta.
                        this.dados.saldo = valor.dados.saldoaberto > saldoTotal ? saldoTotal : valor.dados.saldoaberto
                        this.dados.nome = valor.dados.nome + ' ' + valor.dados.sobrenome
                        this.dados.uh = valor.dados.uh
                        this.dados.numeroreserva = valor.dados.numeroreserva
                        this.dados.datacheckinprevisto = valor.dados.datacheckinprevisto
                        this.dados.datacheckoutprevisto = valor.dados.datacheckoutprevisto
                        this.dados.checagens = parseInt(valor.dados.checagens)
                        this.dados.doc = valor.dados.documento
                        this.dados.docType = valor.dados.tipodoc
                        this.dados.idhospede = valor.dados.idhospede
                        this.dados.idreservahospede = valor.dados.idreservahospede
                    } else {
                        console.error(JSON.stringify(result))
                    }
                })
            .catch((e) => {
                console.error(e)
            })
        },
        carregarDadosPrepagamento(rota) {
            this.dados = { 
                idhotel: rota.dados.idhotel,
                nomehotel: rota.dados.nomehotel,
                nome: rota.dados.nome,
                numeroreserva: rota.dados.numeroreserva,
                saldo: rota.dados.saldo,
                uh: rota.dados.uh,
                datacheckinprevisto: rota.dados.datacheckinprevisto,
                datacheckoutprevisto: rota.dados.datacheckoutprevisto,
                taxaturismo: 0.05,
                taxacolaboradores: 0.05,
                cabecalhos: rota.dados.cabecalhos
            }
            this.componenteatual = rota.to
        },
        carregarHoteis(idhotel) {
            this.hoteis = []
            this.$http.post("/perfil/hotel/basic/select",{  })
            .then((response) => {
                if (response.status == 200) {
                    this.dados.hoteis = response.data
                    this.dados.exibirSelectHotel = this.dados.hoteis.length > 1
                    if (this.dados.hoteis.length > 0) {
                        this.dados.hotel = idhotel?idhotel:this.dados.hoteis[0]['id']
                    }
                    this.componenteatual = 'login'
                } else {
                    console.error(JSON.stringify(response))
                }
            })
            .catch((error) => {
                console.log(error);
            }); 
        },
        carregarDadosFrigobar(rota) {
            this.$http.post("/webcheckout/getItensFrigobar",{
                    "idhotel": rota.dados.idhotel
                }, { headers: {'x-access-token': localStorage.getItem('checkout-token')}})
                .then(result => {
                    this.componenteatual = rota.to
                    if (result.status == 200) {
                        this.dados = { 
                            nome: rota.dados.nome,
                            uh: rota.dados.uh,
                            numeroreserva: rota.dados.numeroreserva,
                            datacheckinprevisto: rota.dados.datacheckinprevisto,
                            datacheckoutprevisto: rota.dados.datacheckoutprevisto,
                            idhotel: rota.dados.idhotel,
                            idreservahospede: rota.dados.idreservahospede,
                            idhospedepms: rota.dados.idhospedepms,
                            globalcodehits: rota.dados.globalcodehits,
                            email: rota.dados.email,
                            itens: result.data 
                        }
                        for (var i = 0; i < this.dados.itens.length; i++) {
                            this.dados.itens[i].quantidade = 0
                        }
                    } else {
                        console.error(JSON.stringify(result))
                    }
                })
            .catch((e) => {
                console.error(e)
            })
        },
        carregarDadosPagamento(rota) {
            this.componenteatual = rota.to
            this.$nextTick(() => {
                this.$refs.nomeCartao?.focus();
            });

        },
        removerCaracteres(palavra){
            let retorno = palavra.replace('.','').replace('.','').replace('.','').replace('.','').replace('.','').replace('.','')
            retorno = retorno.replace('-','').replace('-','').replace('-','').replace('-','').replace('-','').replace('-','')
            retorno = retorno.replace(' ','').replace(' ','').replace(' ','').replace(' ','').replace(' ','').replace(' ','')

            return retorno
        },
        removerCaracteresMantendoEspaco(palavra){
            let retorno = palavra.replace('.','').replace('.','').replace('.','').replace('.','').replace('.','').replace('.','')
            retorno = retorno.replace('-','').replace('-','').replace('-','').replace('-','').replace('-','').replace('-','')
            return retorno
        },
        registrarPagamento(dadoscartao) {
            var params = {
                "idhotel": localStorage.getItem('checkout-hotel'),
                "valor": dadoscartao.saldo,
                "nucartao": this.removerCaracteres(dadoscartao.numerocartao),
                "nomecartao": this.removerCaracteresMantendoEspaco(dadoscartao.nometitular),
                "dtexpiracaocartao": dadoscartao.validade,
                "nucvvcartao": dadoscartao.cvccvv,
                "bandeiracartao": this.removerCaracteres(dadoscartao.bandeira),
                "cabecalhos": dadoscartao.cabecalhos,
                "consumos": this.dados.consumosextras,
                "tipoPagamento":localStorage.getItem('tipoPgto'),
                "parcelas":dadoscartao.parcelas,

                "enderecoTitularAntiFraude":this.removerCaracteresMantendoEspaco(dadoscartao.enderecoTitularAntiFraude),
                "numeroEnderecoAntiFraude":dadoscartao.numeroEnderecoAntiFraude,
                "complementoAntiFraude":this.removerCaracteresMantendoEspaco(dadoscartao.complementoAntiFraude?dadoscartao.complementoAntiFraude:''),
                "bairroAntiFraude":this.removerCaracteresMantendoEspaco(dadoscartao.bairroAntiFraude),
                "cepAntiFraude":this.removerCaracteres(dadoscartao.cepAntiFraude),
                "pais":dadoscartao.pais,
                "estado":dadoscartao.estado,
                "cidade":dadoscartao.cidade,
                "nomeTitularAntiFraude":this.removerCaracteresMantendoEspaco(dadoscartao.nomeTitularAntiFraude),
                "emailTitularAntiFraude":dadoscartao.emailTitularAntiFraude.trim(),
                "celularTitularAntiFraude":this.removerCaracteres(dadoscartao.celularTitularAntiFraude),
                "cpfTitularAntiFraude":this.removerCaracteres(dadoscartao.cpfTitularAntiFraude),
                "cnpjEmissaoNota":this.removerCaracteres(dadoscartao.cnpjEmissaoNota),
                "nomeHospede": this.dados.nome,
                "idEntity": parseInt(this.dados.idhospedepms),
                "idReservation": parseInt(this.dados.numeroreserva),
                "tipoIntegracao": localStorage.getItem('TIPOINTEGRACAO'),
                "docHospede": dadoscartao.docHospede,
                "docType": dadoscartao.docType?dadoscartao.docType:this.dadoscartao.docType,
                "nomeEmpresa": dadoscartao.nomeEmpresa,
                "idhospede": dadoscartao.idhospede,
                "idreservahospede": dadoscartao.idreservahospede
            }
            console.error(`efetuarPagamento, params: ${JSON.stringify(params,null,2)}`)
            this.componenteatual = 'processando'
            this.$http.post("/webcheckout/efetuarPagamento",params, 
                { headers: {'x-access-token': localStorage.getItem('checkout-token')}})
                .then(result => {
                    if (result.status == 200) {
                        this.componenteatual = 'pgtoaprovado'
                        setTimeout(()=>{
                            this.componenteatual = 'pronto'
                            setTimeout(()=>{
                                this.componenteatual = 'desconto'
                                setTimeout(()=>{
                                    this.componenteatual = 'proxima'
                                    setTimeout(()=>{
                                        this.componenteatual = 'boaviagem'
                                    },3000)
                                },3000)
                            },3000)
                        },3000)
                    } else {
                        this.dados.dadoscartao = dadoscartao
                        this.componenteatual = 'pgtonegado'
                        console.error(JSON.stringify(result))
                    }
                })
            .catch((e) => {
                console.error(e)
                this.componenteatual = 'pgtonegado'
            })
        },
    },
  }
</script>

