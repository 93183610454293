<template>
  <v-card>
      <v-toolbar color="blue">
          <div style="width:100%;height:100%;display:flex;align-items:center;">
              <div style="width:10%">
                  Ficha Digital
              </div>
              <div style="width:85%;display:flex;align-items:center;padding-right:30px;justify-content:flex-end;">
                 


              </div>

          </div>            
      </v-toolbar>

  <div  id="exportPdf" ref="exportPdf" style="padding: 100px; margin: 30px; margin-bottom: 100px!important; margin-top: 100px!important;" v-html="contrato">
    
  </div>


  </v-card>
</template>

<script>
import dayjs from 'dayjs'
import {BUILD_TIPO_INTEGRACAO} from '@/constantes.js'
export default {
  name : "FichaDigital",
  props:["id", "possuiEmpresaReservante"],
  data: ()=>({
      reserva: {},
      formcovid: {},
      docfrente: null,
      docverso: null,
      assinatura: null,
      textobtnFinalizar:'Finalizar Ficha',
      imgSrcFrente:'',
      imgSrcVerso:'',
      imgSrcAssinatura:'',
      imgSrcLogoHotel:'',
      dadosHotel:null,
      relatorioexibido:'FNRH',
      nomedoRelatorio:'Relatorio',
      btnFinalizarHabilitado:false,
      contrato: ''
  }),
  computed: {
      desabilitarFNRH () {
          var result;
          // result += (this.reserva.iconepreenchido=='')
              result = (this.reserva.docfrente == null)
              || (this.reserva.docverso == null)
              || (this.reserva.assinatura == null);
          return result;
      }
  },
  watch:{
      dadosHotel(val){
          this.dadosHotel =val
      }
  },
  methods: {
      exibirRelatorio(valor){
          this.relatorioexibido = valor
          // this.nomedoRelatorio = this.relatorioexibido+"_"+this.dadosHotel[0].nome.replace(' ','_')+"_"+this.reserva.nome.replace(' ','_')+"_"+dayjs().format('DDMMYYYYHHmmss')
          this.nomedoRelatorio = this.reserva.numeroreserva+"-"+this.reserva.nome.replace(' ','-')+"-"+this.reserva.sobrenome.replace(' ','-')+"-"+this.relatorioexibido
      },
      savePdf(){
              // this.$PDFSave(this.$refs.exportPdf, "Relatorio

      this.$PDFSave(this.$refs.exportPdf, 'Contrato');
      },
      editarFicha(){
          let body = {
                          reserva: {
                              id: this.reserva.idreserva,
                              hotel: localStorage.getItem("hotel"),
                              iniciofd:true
                          },
                          hospede: {
                              id: this.reserva.idhospede,
                              idhospedepms: this.reserva.idhospedepms,
                              enviadopmstimestamp: null
                          },
                      };

                      this.$http.post("/reserva/upinsert", body).then((response) => {
                          if (response.status == 200) {
                              this.btnFinalizarHabilitado = true
                              this.reserva.enviadopms = false
                          } else {
                              console.log(JSON.stringify(response))
                          }
                          })
                          .catch((error) => {
                              console.log(error);
                              console.log(JSON.stringify(body));
                          });
      },
      finalizarFicha(){
          this.textobtnFinalizar = "Finalizando..."
          this.reserva.usuarioemail = localStorage.getItem('useremail')
          console.log("reserva")
          console.log(this.reserva)
          if(BUILD_TIPO_INTEGRACAO == "HITS"){
              this.reserva.ufnome = this.reserva.ufsigla
          }
          //  this.$socket.emit('dash_backend_importa_reserva', {reserva:this.filtroReserva})  
           this.$socket.emit('dash_to_backend_finalizarficha', {reserva:this.reserva})  

           this.sockets.subscribe("backend_to_dash_finalizarficharetorno", (dados) => {
                 
                
                  
                  this.sockets.unsubscribe("backend_to_dash_finalizarficharetorno")
                  if(dados.statusCode == 200){

                      let body = {
                          reserva: {
                              id: this.reserva.idreserva,
                              hotel: localStorage.getItem("hotel"),
                              fimfd:true
                          },
                          hospede: {
                              id: this.reserva.idhospede,
                              idhospedepms: this.reserva.idhospedepms,
                              enviadopmstimestamp: dayjs().format()
                          },
                      };

                      this.$http.post("/reserva/upinsert", body).then((response) => {
                          if (response.status == 200) {
                              this.btnFinalizarHabilitado = false
                              this.reserva.enviadopms = true
                          
                              

                              let bodyRemoverTempMerge = {
                                  numeroreserva:this.reserva.numeroreserva,
                                  idhospedepms:this.reserva.idhospedepms
                              } 
                     

                              this.$http.post("/reserva/removerReservaTemp", bodyRemoverTempMerge).then(() => {
                                  this.$emit('update')
                                  this.$emit('fechar')
                              }).catch(()=>{
                                  this.$emit('update')
                                  this.$emit('fechar')
                              })

                              
                          } else {
                              console.log(JSON.stringify(response))
                          }
                          })
                          .catch((error) => {
                              console.log(error);
                              console.log(JSON.stringify(body));
                          });

                  }else{
                      // alert(dados.resposta)
                      alert("Robô não respondeu de Forma Satisfatoria")
                  }

                  this.textobtnFinalizar = "Finalizar Ficha"

              });

          //  this.$socket.subscribe("backend_to_dash_finalizarficharetorno", (dados) => {
          //     this.qtdeNotificacoes += 1
          //     console.log(dados)
          // });
      },
      formatarData(aDate) {
          //this.moment(aDate).locale(this.$i18n.locale).format('L')
          return this.moment(aDate).format('DD/MM/YYYY')
      },
      formatarHora(aDate) {
          return this.moment(aDate).format('HH:MM')
      },
      gerarFNRH() {
          //this.$http.post('/reserva/fnrh/create', { id: this.id, modo: 'base64' })
          this.$http.post('/reserva/fnrh/create', { id: this.id }, 
              {headers: {"Access-Control-Allow-Origin": "*"}})
          .then((response) => {
              if(response.status == 200){
                  // Desta forma modo: 'base64', abre em outra janela mas não faz o download
                  // let pdfWindow = window.open('','FNRH')
                  // pdfWindow.document.write(
                  //     "<iframe width='100%' height='100%' frameborder='0' marginwidth='0' marginheight='0' scrolling='no' style='border:1px solid #CCC; border-width:1px;' src='data:application/pdf;base64, " +
                  //     encodeURI(response.data) + "'></iframe>"                        
                  // )
                  // pdfWindow.document.close();
                  //console.log(JSON.stringify(response.data))
                  //document.getElementById('iframeFNRH').src = response.data.link
                  var alink = document.getElementById('downloadFNRH')
                  if (alink) {
                      alink.setAttribute("target", "_blank");
                      alink.href = response.data.link;
                      alink.click();
                  }
              }
          })
      },
      gerarFormularioSanitario() {
          //this.$http.post('/reserva/formulariosanitario/create', { id: this.id, modo: 'base64' })
          this.$http.post('/reserva/formulariosanitario/create', { id: this.id })
          .then((response) => {
              if(response.status == 200){
                  // Desta forma modo: 'base64', abre em outra janela mas não faz o download
                  // let pdfWindow = window.open('','COVID')
                  // pdfWindow.document.write(
                  //     "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                  //     encodeURI(response.data) + "'></iframe>"
                  // )
                  // pdfWindow.document.close();
                  var alink = document.getElementById('downloadFormSanitario')
                  if (alink) {
                      alink.setAttribute("target", "_blank");
                      alink.href = response.data.link;
                      alink.click();
                  }
              }
          })
      },
      buscarDadosReserva() {
          this.$http.post('/reserva/select', { id: this.$route.params.id })
          .then(async (response) => {
              console.log(response.data)
              if (response.status == 200) {
                  if(response.data && Array.isArray(response.data) && response.data.length > 0) {
                      this.reserva = response.data[0]
                      if(this.reserva.enviadopms){
                          this.btnFinalizarHabilitado = true
                      }else{
                          this.btnFinalizarHabilitado = false
                      }
                
                      await this.$http.post('perfil/hotel/select', { id: localStorage.getItem('hotel') }).then(async (response) => {
                          this.dadosHotel = response.data

                          if(this.dadosHotel[0].logo){
                          
                              await this.$http.post('imagem/select', { id: this.dadosHotel[0].logo }).then(async (imghotel) => {

                                  this.imgSrcLogoHotel = await Buffer.from(imghotel.data[0].dados).toString()
                                  // this.nomedoRelatorio = this.relatorioexibido+"_"+this.dadosHotel[0].nome.replace(' ','_')+"_"+this.reserva.nome.replace(' ','_')+"_"+dayjs().format('DDMMYYYYHHmmss')
                                  this.nomedoRelatorio = this.reserva.numeroreserva+"-"+this.reserva.nome.replace(' ','-')+"-"+this.reserva.sobrenome.replace(' ','-')+"-"+this.relatorioexibido
                                  this.buscarDadosCovid();
                                  await this.buscarImagem('docfrente')
                                  await this.buscarImagem('docverso')
                                  await this.buscarImagem('assinatura')
                                  
                              })

                          }else{
                                  // this.nomedoRelatorio = this.relatorioexibido+"_"+this.dadosHotel[0].nome.replace(' ','_')+"_"+this.reserva.nome.replace(' ','_')+"_"+dayjs().format('DDMMYYYYHHmmss')
                                  this.nomedoRelatorio = this.reserva.numeroreserva+"-"+this.reserva.nome.replace(' ','-')+"-"+this.reserva.sobrenome.replace(' ','-')+"-"+this.relatorioexibido
                                  this.buscarDadosCovid();
                                  await this.buscarImagem('docfrente')
                                  await this.buscarImagem('docverso')
                                  await this.buscarImagem('assinatura')
                          }

                          // var _self = this
                          // setTimeout(() => {
                          //     _self.savePdf()
                          // }, 1000);
                          
                          var _self = this
                          var donwloadRealizado = false
                          if(this.relatorioexibido === 'FNRH'){
                              setInterval(async () => {
                                  var imgDocFrente = document.getElementById('imgDocFrente')
                                  var isLoadedDocFrente = imgDocFrente.complete && imgDocFrente.naturalHeight !== 0; 

                                  var imgDocVerso = document.getElementById('imgDocVerso')
                                  var isLoadedDocVerso = null
                                  if(imgDocVerso){
                                      isLoadedDocVerso = imgDocVerso.complete && imgDocVerso.naturalHeight !== 0; 
                                  }else{
                                      isLoadedDocVerso = true
                                  }

                                  var imgAssinatura = document.getElementById('imgAssinatura')
                                  var isLoadedAss = imgAssinatura.complete && imgAssinatura.naturalHeight !== 0; 

                                  if( isLoadedDocFrente && isLoadedDocVerso && isLoadedAss ){
                                      if(!donwloadRealizado){

                                          donwloadRealizado = true

                                          await _self.savePdf()
                                          

                                      }
                                  }
                              }, 500);
                          }else{
                              setTimeout(async () => {
                                  await _self.savePdf()
                                  donwloadRealizado = true
                              }, 2000);
                              
                          }
                          
                          setInterval(async () => {
                              // alert('1')
                              if(donwloadRealizado){
                                  // alert('2')
                                   window.close()
                              }
                          },3000)
                          

                      })

                      

                      //  setTimeout(() => {
                      //     window.close()
                      // }, 3000);
                       

                      
                  }
              }
          })
      },
      buscarDadosCovid() {
          if (this.reserva.idpessoa) {
              var params = { 
                  chave: 'COVID', 
                  resposta: {
                      idrespondente: this.reserva.idpessoa
                  }
              }
              this.$http.post('/formulario/resposta/select', params)
              .then((resp) => {
                  if (resp.status == 200) {
                      if(resp.data && Array.isArray(resp.data) && resp.data.length > 0) {
                          this.formcovid = resp.data[0]
                      }
                  }
              })
          } else {
              alert('ainda sem reserva...')
          }
      },
      bin2String(array) {
          return String.fromCharCode.apply(String, array);
      },
      async buscarImagem(campo) {

          if(campo == "docfrente"){

              // imagem frente
              await this.$http.post("/imagem/select", {hotel: localStorage.getItem("hotel"),id: this.reserva[campo],})
              .then(async (responseimg) => {
                if (Array.isArray(responseimg.data) && responseimg.data.length>0) {
                  this.imgSrcFrente =  await Buffer.from(responseimg.data[0].dados).toString()
                  // var img = document.getElementById(campo)
                  // img.src = await Buffer.from(responseimg.data[0].dados).toString()
                }
              }).catch((error) => { 
                  console.log(error); 
              });

          }

          if(campo == "docverso"){
              
              // imagem verso
              this.$http.post("/imagem/select", {hotel: localStorage.getItem("hotel"),id: this.reserva[campo],})
                  .then(async (responseimg) => {
                  if (Array.isArray(responseimg.data) && responseimg.data.length>0) {
                      this.imgSrcVerso = Buffer.from(responseimg.data[0].dados).toString()
                      // var img = document.getElementById(campo)
                      // img.src = await Buffer.from(responseimg.data[0].dados).toString()
                  }
                  })
                  .catch((error) => { console.log(error); });
          }

          if(campo == "assinatura"){
          
              if (this.reserva.idpessoa && this.reserva.id) {
                  this.$http.post('/imagem/select', { 
                      id: this.reserva[campo]
                  })
                  .then((resp) => {
                      if (resp.status == 200) {
                          this.imgSrcAssinatura = Buffer.from(resp.data[0].dados).toString()
                          // if(resp.data && Array.isArray(resp.data) && resp.data.length > 0) {
                          //     this[campo] = resp.data[0]
                          //     var img = document.getElementById(campo)
                          //     //img.src = "data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg=="
                          //     var mystr = this.bin2String(this[campo]['dados']['data'])
                          //     img.src = `data:${this[campo]['tipo']};base64, ${mystr}`
                          // }
                      }
                  })
              } else {
                  alert('ainda sem reserva...')
              }

          }

      },
      async buscarContrato() {
        try {
          const result = await this.$http.post('/contrato/buscarcontratoassinado', {
            idpessoa: localStorage.getItem('idpessoa')
          });
          this.contrato = result.data[0].contrato;
          console.log(this.contrato)
        } catch (error) {
          console.log('erro');
        }
      }
  },
  async created() {
      this.relatorioexibido = this.$route.params.tipo
      // setInterval(() => {
      //     window.scrollTo(0,0);
      // }, 500);
      // await this.buscarDadosReserva()
         await this.buscarContrato();
         await this.savePdf()
  },
  mounted() {
      //
  }
}
</script>

<style scoped>
.label {
  font-size: 8pt!important;
  font-weight: bold;
}
.field {
  font-size: 11pt!important;
  font-weight: bold;
}
.section {
  font-size: 11pt!important;
  color: white;
  background-color: #6187a5;
  padding: 0.2rem;
  border-radius: 5px 5px 0px 0px;
}
</style>