<template>
        <v-card>
        <v-toolbar color="blue">
          <span style="width:95%">Assinatura</span>
          <span style="width:5%;padding:10px;"> 
            <BotaoTutorial urlVideo="" legenda="Dúvidas Assinatura" categoria=""/>
          </span>
          <span style="width:5%"> 
              <v-btn x-small color="red" @click="$emit('fechar')" title="Fechar">
                  <v-icon x-small style="color:white;">mdi-close</v-icon>
              </v-btn>
          </span>
        </v-toolbar>
        <div style="color:orange;padding-left:10px;text-align:center;"><span v-if="!possuiEmpresaReservante">Bloqueado para edição: sem empresa reservante.</span></div>
        <v-card-text style="">
          
      

          <!-- <img v-if="image" class="imgUpload" :src="image" style="max-width:50vw;" > -->
          <div style="display:flex;align-items:center;justify-content:center;">
            <img v-if="imgSrcAssinatura" :src="'data:image.png;base64,' + imgSrcAssinatura" class="imgUpload" />
          </div>

          <div style="display:flex;justify-content:center">
            <div style="height:100px;display:flex;justify-content:center;align-items:center;">
                <!-- <v-btn v-if="btnSalvarHabilidado&&possuiEmpresaReservante" color="blue" v-on:click="solicitarCapturaAssinatura()" style="color:white;font-weight:bold;width:200px;font-size:14px!important;">Capturar assinatura</v-btn>
                <v-btn v-else disabled="true" color="blue" style="color:white;font-weight:bold;width:200px;font-size:14px!important;">
                  {{possuiEmpresaReservante?'FICHA ESTÁ FINALIZADA':'FICHA BLOQUEADA'}}
                </v-btn> -->
                <v-btn color="blue" v-on:click="solicitarCapturaAssinatura()" data-cy="assinatura-btncapturar" style="color:white;font-weight:bold;width:200px;font-size:14px!important;">Capturar assinatura</v-btn>
                
            </div>
          </div>


        </v-card-text>
        
        </v-card>
</template>
<script>
  import BotaoTutorial from '../material/BotaoTutorial.vue';

export default {
  name: "Assinatura",
  components: {
    BotaoTutorial
  },
  data: () => ({
    ladodocnome:null,
    ladodocfk:null,
    image: '',
    imgSrc:'',
    imgSrcAssinatura:'',
    isImg:false,
    btnSalvarHabilidado:true
  }),
  props: ["id", "idhospede", "idReservaHospede", "idreserva", "possuiEmpresaReservante"],
  methods :{
    solicitarCapturaAssinatura(){

        let objeto = {
                          usuario: localStorage.getItem('useremail'), 
                          msg: '',
                          idHospede: this.idhospede,
                          idReserva: this.idreserva,
                          idReservaHospede: this.id
        }

        let tag = localStorage.getItem('tag')
        
        if(tag){
          objeto.tag = tag
        }


        this.$socket.emit('dash_to_leitor_solicitar_captura_assinatura',objeto )  
    },
    async setImage(e) {
      const file = e.target.files[0];

        if (!file.type.includes("image/")) {
            alert("Please select an image file");
            return;
        }

        if (typeof FileReader === "function") {
            const reader = new FileReader();

            reader.onload = (event) => {
            this.isImg = true;
            this.imgSrc = event.target.result;
            let index = event.target.result.indexOf(",") + 1;
            this.imgSrc = event.target.result.substring(index,event.target.result.length);
            if(this.imgSrc){
                this.salvaLogo(file.type)
            }
            };
            reader.readAsDataURL(file);
        } else {
            alert("Sorry, FileReader API not supported");
        }
    },

    async salvaLogo(tipoImg) {
        if (!this.imgSrc.length > 0) {
            console.log("invalido");
            return;
        }

        let dados =   {
                        "token":'3c8478c895580214e0ff389448054854',
                        "tokenInfo":{"usuario":{"id":localStorage.getItem('userid')} },
                        "tipo" : tipoImg,
                        "nome" : this.ladodocnome,
                        "dados" : this.imgSrc,
                        "tabela": "hospede",
                        "fk": this.ladodocfk,
                        "chave": "id",
                        "valor": this.idhospede
                    }
        await this.$http.post("/imagem/upinsertAuthSimples",dados,
            {timeout:60000 }
            )
            .then((response) => {
                if(response.status == 200){
                    this.imgSrcAssinatura = this.imgSrc
                    console.log("Assinatura salva com Sucesso!");
                    // this.manipularDirecionamento(response.data.result.imagem[0])
                    this.$emit("update")
                }else{
                    console.log("Não foi possivel salvar Documento Frente!")
                }
            })
            .catch((error) => {
            console.log(error.response);
            });
    },

    listarDados() {
      this.imgSrcAssinatura = null
      if(this.idhospede){
        this.$http
          .post("/reserva/select", {
            hotel: localStorage.getItem("hotel"),
            idhospede: this.idhospede,
            idreserva:this.idreserva
          })
          .then((response) => {
            this.btnSalvarHabilidado = !response.data[0].enviadopms
            //imagem frontal
            this.$http.post("/imagem/select", {hotel: localStorage.getItem("hotel"),id: response.data[0].assinatura,})
                .then(async (responseimg) => {
                  if (Array.isArray(responseimg.data) && responseimg.data.length>0) {
                    this.imgSrcAssinatura =  await Buffer.from(responseimg.data[0].dados).toString()
                  }
                })
                .catch((error) => { alert(error); console.log(error); });

          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

  

  },
  watch: {
    idhospede() {
      this.listarDados()
    },
  },
  mounted(){
    this.listarDados();
    this.sockets.subscribe("avancar", () => {
        console.log("AVANCANDO")
        this.$emit('update')
        this.$emit('avancar')
    });

    this.sockets.subscribe("backend_to_atualizar_assinatura", () => {
      this.listarDados();
    });

  }

};
</script>

<style scoped>
.imgUpload{
        max-width:200px;
        max-height: 200px;
        max-width:90vw;
        margin-top:20px;
        margin-left:20px;
        margin-right:20px;
    }
</style>