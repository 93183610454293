<template>
  <v-container fill-height fluid grid-list-xl>
    <v-dialog v-model="showDialog" width="70%" style="min-height:90vh!important;" persistent>
      <v-card class="pa-2" height="100%">
        <!-- Título -->
        <v-card-title>
          {{titulo}}
        </v-card-title>
        <v-card-text>
          <br>
          <h1>{{texto}}</h1>
        </v-card-text>
        <br>
        <v-card-actions>
          <template v-if="!hidebuttons">
            <v-spacer />
            <v-btn
              align-center
              justify-center id="btn-confirmar-estorno"
              @click="$emit('confirmar')"
              ><strong>Confirmar</strong>
              <v-icon color="green">mdi-check</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
              align-center
              justify-center
              @click="$emit('cancelar')"
              ><strong>Cancelar</strong>
              <v-icon color="red">mdi-close</v-icon>
            </v-btn>
            <v-spacer />
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

export default {
  props: ['show','titulo','texto','hidebuttons'],
  data: () => ({
    showDialog: false
  }),
  watch: {
    show(val) {
      this.showDialog = val
    }
  }
};
</script>
