import axios from "axios";
import { BUILD_FD_API_URL } from '@/constantes.js'

async function serializarImagem(e,percentualReducaoScale){

    await registrarLog({
        texto:'dentro do serializarImagem'
    })

    return new Promise((resolve, reject) => {

        try {
            


            var file = e
    
            // Faço a leitura do arquivo
            var reader = new FileReader()
            
            reader.onloadend = () => {
    
                var imagem = new Image()

                imagem.onload = function() {  
                        
                        let canvas = document.createElement("canvas");
                        canvas.width  = this.width * percentualReducaoScale
                        canvas.height = this.height * percentualReducaoScale
    
                        let contextoDoCanvas = canvas.getContext("2d");
                                           
                        contextoDoCanvas.scale(percentualReducaoScale, percentualReducaoScale);
                        contextoDoCanvas.drawImage(this, 0, 0)
    
                        var imagemescalada = canvas.toDataURL('image/jpeg', percentualReducaoScale);
                        
                        let tipoImagem = imagemescalada.split(",")[0]
                        tipoImagem = tipoImagem.split(";")[0]
                        tipoImagem = tipoImagem.split(":")[1]

                        let dados = imagemescalada.split(",")[1]
                        resolve({tipo:tipoImagem,dados:dados})
    
                } 

                imagem.src = reader.result
    
    
    
    
            }
    
    
            reader.readAsDataURL(file);
            
    
        } catch (error) {
            console.log(error)   

            registrarLog({
                texto:'error dentro do serializarImagem'
            })

            registrarLog({
                texto:JSON.stringify(error.message,null,2)
            })
            
            reject(error)
        }

    })
    
}


// function serializarImagemNew(e,larguraNova){
async function serializarImagemNew(e){
    
        var sucesso = null;
        try {

            await registrarLog({
                texto:'dentro da serializarImagemNew: Iniciou'
            })

            const DOISMEGAS = 2000000
            const QUATROMEGAS = 4000000
            var tamanhoArquivo = 0
            
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                alert("Sem Imagem")
            }

            var src = await URL.createObjectURL(files[0]);
   
            let larguraNova = {}
            tamanhoArquivo = files[0].size

            if(tamanhoArquivo > QUATROMEGAS){
                // larguraNova = {width: 600}
                larguraNova = {width: 1000}
            }else{
                if(tamanhoArquivo > DOISMEGAS){
                    // larguraNova = {width: 600}
                    larguraNova = {width: 1000}
                }else{
                    // larguraNova = {width: 400}
                    larguraNova = {width: 650}
                }
            }

            

            await registrarLog({
                texto:'Vai iniciar o resizeImage'
            })

            
            await resizeImage(src, larguraNova).then(async function (blob) {

                await registrarLog({
                    texto:'Concluiu o resize imagem'
                })

                try {
                    
                        var reader = new FileReader()
                        // document.querySelector("#resized").src = URL.createObjectURL(blob)
                        await reader.readAsDataURL(blob); 

                        let percentualReducao = 0.8
                        if(tamanhoArquivo > QUATROMEGAS){
                            percentualReducao = 0.7
                        }else{
                            if(tamanhoArquivo > DOISMEGAS){
                                percentualReducao = 0.8
                            }else{
                                percentualReducao = 0.9
                            }
                        }


                        await serializarImagem(blob,percentualReducao).then(resp =>{
                            console.log(resp)
                                registrarLog({
                                    texto:'concluiu o serializarImagem'
                                })

                                // resolve(resp)
                                sucesso = resp
                            })
                
                } catch (error) {

                    registrarLog({
                        texto:'caiu no catch do resizeImage'
                    })

                }
  
            })
            
    
        } catch (error) {

            registrarLog({
                texto:'Caiu no catch do serializarImagemNew'
            })
            registrarLog({
                texto:JSON.stringify(error.message,null,2)
            })

            console.log(error)   
            // reject(error)
            sucesso = false
        }

    return new Promise((resolve, reject) => {
        if(sucesso){
            resolve(sucesso)
        }else{
            reject(sucesso)
        }
    })
    
}

async function registrarLog(dados){
    
    await axios.post(BUILD_FD_API_URL+"/imagem/registrarLog",dados,{timeout:60000 })
    .then((response) => {
        console.log('log registrado ',response)
    })
    .catch((error) => {
        console.log(error.response);
    });
}

function loadImage(img, src) {
    return new Promise((resolve, reject) => {
     
        img.src = src;  
        img.completed ? resolve(img) : img.addEventListener('load', function () {
            resolve(img)
        });

        img.addEventListener('error', reject);

    })
}

async function resizeImage(src, options) {

    await registrarLog({
        texto:'Dentro do resizeImage: Iniciou'
    })

    return loadImage(document.createElement('img'), src).then(async function (image) {

        try {
            
            await registrarLog({
                texto:'Iniciou loadImage'
            })
            
            var canvas = document.createElement('canvas');

            if (options.width && !options.height) {
                options.height = image.height * (options.width / image.width)
            } else if (!options.width && options.height) {
                options.width = image.width * (options.height / image.height)
            }

            Object.assign(canvas, options);
            // canvas.getContext('2d').drawImage(image, 0, 0, canvas.width, canvas.height);
            let ctx = canvas.getContext('2d');
            ctx.filter = "contrast(200%)";
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
            return new Promise(function (resolve) {
                canvas.toBlob(resolve, options.type || 'image/png', options.quality)
                registrarLog({
                    texto:'terminou  loadImage'
                })
            })

        } catch (error) {
            registrarLog({
                texto:JSON.stringify(error.message,null,2)
            })
        }

    })
    

}

export  {

    serializarImagem,
    serializarImagemNew,
    resizeImage,
    registrarLog,
}