<template>
    <v-dialog v-model="exibirModalEditarPassosWebCheckinPergunta" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Pergunta Extra WebCheckin
                    </span>
                    <span style="width:5%">
                        <BotaoTutorial style="margin-right:30px;" urlVideo="" categoria="" legenda="Dúvidas Pergunta Extra WebCheckin"/>
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="setModalEditarPassosWebCheckinPergunta()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>
            <div style="display: flex; justify-content: space-between;padding: 20px;">
                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="switch1" @click="exibirPergunta" style="border-radius:5px;padding:5px;"></v-switch>
                        <label >Exibir Pergunta Extra?</label>  
                    </div>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="switch2"  @click="exibirPerguntaExtraObrigatorio" style="border-radius:5px;padding:5px;"></v-switch>
                        <label >Pergunta extra obrigatório?</label>  
                    </div>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-btn @click="() => adicionarPergunta()"  title="Adicionar nova pergunta" color="#1377f2" elevation="2" icon fab>
                            <v-icon size="28s" color="#1377f2" >mdi-plus-circle-outline</v-icon>
                        </v-btn>
                        <label style="margin-left: 10px;">Adicionar nova pergunta</label>  
                    </div>
              
                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-btn @click="() => modalTelaDeAgrupamento = true"  title="Criar tela de agrupamento" color="#1377f2" elevation="2" icon fab>
                            <v-icon size="28s" color="#1377f2" >mdi-plus-circle-outline</v-icon>
                        </v-btn>
                        <label style="margin-left: 10px;">Criar tela de agrupamento</label>  
                    </div>
                
                    
                    <ModalTelaDeAgrupamento :show="modalTelaDeAgrupamento" @fecharModalTelaDeAgrupamento="modalTelaDeAgrupamento = false"/>
                </div>
            <div style="width: 30%; margin: 0 0 0 10px;">
                <v-select
                    label="Idioma"
                    v-model="idiomaSelecionado"
                    :items="listaDeIdiomas"
                    item-value="sigla"
                    item-text="idioma"
                    @input="buscarPerguntas"
                return-object>
                </v-select>  
            </div>
            <v-data-table
              :headers="headers"
              :items="dataPerguntas"
              class="elevation-1"
            >
              <template slot="headerCell" slot-scope="{ header }">
                <span
                  class="subheading font-weight-light text-general text--darken-3"
                  v-text="header.text"
                />
              </template>
              <template v-slot:[`item.ativo`]="{ item }">
                <v-simple-checkbox v-model="item.ativo" @click="() => desativarPergunta(item)">
                </v-simple-checkbox>
              </template>
              <template v-slot:[`item.obrigatorio`]="{ item }">
                <v-simple-checkbox v-model="item.obrigatorio" @click="() => respostaObrigatoria(item)">
                </v-simple-checkbox>
              </template>
              <template v-slot:[`item.id`]="{ item }">
                <p data-cy="item.id">{{item.id}}</p>
              </template>
              <template v-slot:[`item.descricao`]="{ item }">
                <p data-cy="item.descricao">{{item.descricao}}</p>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  color="green darken-1"
                  small style="color:white!important;height:30px!important;"
                  @click="editarPergunta(item)"
                  :data-cy="`editarOpcional_${item.id}`"
                  >
                  Editar
                </v-btn>
              </template>
              <template v-slot:items="props">               
                <td>
                  <div>{{ props.item.descricao }}</div>
                </td>
                <td class="">{{ props.item.ativo }}</td>
              </template>
            </v-data-table>
            <v-dialog v-model="modelEditarPergunta" width="80vw" max-width="600px">
                <div style="width: 100%;background-color: #2196f3;display:flex; justify-content: space-between;padding: 20px;">
                     <span style="width:95%;color:white;font-weight:bold;" >
                     {{perguntaVisualizar.id !== null ? 'Editar pergunta' : 'Adicionar pergunta'}}
                     </span>
                     <span style="width:5%"> 
                     <v-btn x-small color="red" @click="() => this.modelEditarPergunta  = false"  title="Fechar">
                       <v-icon x-small style="color:white;">mdi-close</v-icon>
                     </v-btn>
                     </span>
                </div>
                <div style="background-color:white;width:100%;display:flex;flex-direction:column;padding: 10px;justify-content: center;align-items: center;text-align: center;">
                <v-card-text>
                <div class="cardInputs" style="margin-top:10px;">
                  <label class="labels">Pergunta:</label>
                   <input type="text" class="inputs" v-model="perguntaVisualizar.pergunta" data-cy="pergunta" placeholder="Informe a pergunta extra" />
                </div>
                <div class="cardInputs" style="margin-top:10px;">
                  <label class="labels">Observação:</label>
                   <input type="text" class="inputs" v-model="perguntaVisualizar.obs" data-cy="pergunta" placeholder="Opcional" />
                </div>
                <label class="labels">Resposta:</label>
                <div style="margin: 10px 0 20px; display: flex; flex-wrap: wrap;justify-content: center; align-items: center;">
                    <label>
                        <input type="radio" name="tipoPergunta" value="Resposta objetiva" v-model="tipoPergunta"  @click="(e) => selecionarPergunta(e)"/>
                        Objetiva
                    </label>
                    <label style="margin: 0 0 0px 20px">
                        <input type="radio" name="tipoPergunta" value="Resposta aberta"  v-model="tipoPergunta"  @click="(e) => selecionarPergunta(e)"/>
                        Aberta
                    </label>
                    <label style="margin: 0 0 0px 20px">
                        <input type="radio" name="tipoPergunta" value="Resposta Horário"  v-model="tipoPergunta" @click="(e) => selecionarPergunta(e)"/>
                        Horário
                    </label>
                    <label style="margin: 0 0 0px 20px">
                        <input type="radio" name="tipoPergunta" value="Resposta Data"  v-model="tipoPergunta" @click="(e) => selecionarPergunta(e)"/>
                        Data
                    </label>
                    <label style="margin: 0 0 0px 20px">
                        <input type="radio" name="tipoPergunta" value="Resposta horário de check-in e check-out"  v-model="tipoPergunta" @click="(e) => selecionarPergunta(e)"/>
                        Horário de check-in e check-out
                    </label>
                </div>
                <div class="cardInputs" v-if="tipoPergunta === 'Resposta objetiva'">
                  <label class="labels">Possiveis respostas separadas por PONTO E VIRGULA ( ; )</label>
                  <v-textarea outlined name="input-7-4" style="border-radius:10px;" placeholder="SIM ; NÃO ; TALVEZ" v-model="perguntaVisualizar.resposta" data-cy="resposta"></v-textarea>
                </div>
                <div style="width: 50%; margin: 0 0 0 10px;">
                <v-select
                    label="Idioma"
                    v-model="idiomaSelecionado"
                    :items="listaDeIdiomas"
                    item-value="sigla"
                    item-text="idioma"
                return-object>
                </v-select>  
            </div>
                <div style="display:flex;flex-direction: column;" >
                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;">
                        <v-switch v-model="switch3" @click="exibirPergunta" style="border-radius:5px;padding:5px;"></v-switch>
                        <label >Agrupar pergunta extra</label> 
                    </div>
                    
                    <v-select v-if="switch3" label="Selecionar tela" v-model="agrupamento" :items="telasDeAgrupamento" item-value="id" item-text="descricao"
                            style="width:80%!important">
                    </v-select> 
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <div style="display:flex;justify-content:space-between;margin-top:50px;">
                        <v-btn @click="() => salvarPergunta(perguntaVisualizar)" data-cy="salvarPergunta" style="color:white;font-weight:bold;background-color:#2196f3;">
                                {{textobotaosalvar}}
                        </v-btn>
                    </div>
                </div>
                </v-card-text>
            </div>
        </v-dialog>                        
        </v-card>
        <v-dialog v-model="modalAlerta" width="80vw" max-width="500px">
      <div style="background-color:white;width:100%;display:flex;flex-direction:column;padding: 15px;justify-content: center;align-items: center;text-align: center;">
        <h1 style="margin-top: 5px; font-size: 20px!important;">Atenção</h1>
        <p style="margin-top: 5px;">Preencha as possiveis respostas separadas por PONTO E VIRGULA(;)</p>
        <div style="display: flex; justify-content: space-around; width: 100%;">
          <button class="confirmar" style="background-color: #329EF4; width: 40%; margin-top: 5px;padding: 5px;" @click="() => this.modalAlerta = false">Fechar</button>
        </div>
      </div>
    </v-dialog>
    </v-dialog>
</template>          

<script>

import BotaoTutorial from '../../material/BotaoTutorial.vue';
import ModalTelaDeAgrupamento from './ModalTelaDeAgrupamento.vue';


export default {
    data: () => ({
        dataPerguntas: [],
        pergunta:null,
        objetoPerguntaRetornado:null,
        respostas:[],
        resposta:'',
        switch1:false,
        switch3: false,
        textobotaosalvar:"SALVAR",
        modelEditarPergunta: false,
        modalAlertaHorario: false,
        modalAlerta: false,
        modalTelaDeAgrupamento: false,
        tipoPergunta: 'Resposta objetiva',
        switch2: false,
        cardInputs: true,
        perguntaVisualizar: {
                        id: null,
                        pergunta: null,
                        resposta: null,
                        obs: '',
                        resposta_horario: null,
                        resposta_data: null,
                        resposta_aberta: null,
                        resposta_checkincheckout: null,
                    },
        headers: [
            { text: "Id", value: "id" },
            { text: "Actions", value: "actions", sortable: false },
            { text: "Descrição", value: "pergunta" },
            { text: "Ativo", value: "ativo" },
            { text: "Resposta obrigatória", value: "obrigatorio" }
        ],
        telasDeAgrupamento: [],
        agrupamento: null,
        listaDeIdiomas: [{
          id: 1,
          sigla: 'pt-BR',
          idioma: 'Português'
        },
        {
          id: 2,
          sigla: 'en',
          idioma: 'Inglês'
        },
        {
          id: 3,
          sigla: 'es',
          idioma: 'Espanhol'
        }],
        idiomaSelecionado: {
          id: 1,
          sigla: 'pt-BR',
          idioma: 'Português'
        },
    }),
    props:["exibirModalEditarPassosWebCheckinPergunta"],
    components:{
    BotaoTutorial,
    ModalTelaDeAgrupamento,
    },
    methods: {
        
        editarPergunta(pergunta) {
            this.modelEditarPergunta = true
            this.perguntaVisualizar.id = pergunta.id
            this.perguntaVisualizar.pergunta = pergunta.pergunta
            this.perguntaVisualizar.resposta = pergunta.respostas
            this.perguntaVisualizar.obs = pergunta.obs
            this.agrupamento = pergunta.idagrupamento;
            this.switch3 = pergunta.agrupar;
           if (pergunta.resposta_aberta) {
            this.tipoPergunta = 'Resposta aberta'
           }
           if (pergunta.resposta_data ) {
            this.tipoPergunta = 'Resposta Data'
           }

           if (pergunta.resposta_horario ) {
            this.tipoPergunta = 'Resposta Horário'
           }
           console.log(pergunta)

           if(pergunta.resposta_checkincheckout) {
            console.log('entrrou aqui')
            this.tipoPergunta = 'Resposta horário de check-in e check-out'
           }
           
           if(!pergunta.resposta_horario && !pergunta.resposta_data && !pergunta.resposta_aberta && !pergunta.resposta_checkincheckout) {
            this.tipoPergunta = 'Resposta objetiva'
           }
        },

        adicionarPergunta() {
            this.perguntaVisualizar = {
                        id: null,
                        pergunta: null,
                        resposta: null,
                    }
            this.agrupamento = null;
            this.switch3 = false;
            this.modelEditarPergunta = true;
            // this.dataPerguntas = [...this.dataPerguntas, {
            //             id: null,
            //             pergunta: null,
            //             resposta: null,
            //         }]
        },

        async buscarPerguntas(){
            await this.$http.post("formulario/select",{chave:'PERGUNTAEXTRA', idioma: this.idiomaSelecionado.sigla}).then(async resp =>{
                console.log(resp.data);
                this.dataPerguntas = [];
                resp.data.forEach((e) => {
                    const obj = {
                        id: e.id,
                        pergunta: e.pergunta,
                        respostas: e.respostasaceitas.tipo,
                        ativo: e.ativo,
                        obrigatorio: e.obrigatorio,
                        resposta_aberta: e.resposta_aberta,
                        resposta_data: e.resposta_data,
                        resposta_horario: e.resposta_horario,
                        resposta_checkincheckout: e.resposta_checkincheckout,
                        obs: e.obs,
                        idagrupamento: e.agrupamento,
                        agrupar: e.agrupamento && true,
                    }
                    this.dataPerguntas = [...this.dataPerguntas, obj]
                })
                if(resp.data.length === 0 || !resp.data) {
                    this.dataPerguntas = [{
                        id: null,
                        pergunta: null,
                        resposta: null,
                    }]
                }
            })
        },
        salvarParametroPasso(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'WCHECKINEXIBIRPERGUNTAEXTRA',
                    "valor" : this.exibirPerguntaExtraLocal 
                }).then(resp =>{
                    this.textobotaosalvar = "SALVAR"
                    this.dataPerguntas = [];
                    this.buscarPerguntas()
                    // this.setModalEditarPassosWebCheckinPergunta()
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
            
        },

        validarPerguntaIgual(pergunta) {
            const verificarPerguntaIgual = this.dataPerguntas.some((e) => e.pergunta == pergunta.pergunta);
            return verificarPerguntaIgual;
        },

        salvarPergunta(pergunta){

            if(!pergunta.id) {
                const validar = this.validarPerguntaIgual(pergunta)
                console.log(validar)
                if(validar) {
                    return
                }
            }

            
            if(this.tipoPergunta === 'Resposta aberta') {
                pergunta.resposta = null;
            }
            
            if(this.tipoPergunta === 'Resposta Horário') {
                pergunta.resposta = null;
            }

            if(this.tipoPergunta === 'Resposta Data') {
                pergunta.resposta = null;
            }

            if(this.tipoPergunta === 'Resposta horário de check-in e check-out') {
                pergunta.resposta = null;
            }
            
            this.textobotaosalvar = "SALVANDO..."
            let respostassplit = pergunta.pergunta.split(';')
            let objresposta = ''
            for(var i=0; i < respostassplit.length; i++ ){
                if(respostassplit[i].trim()){
                    objresposta += respostassplit[i].trim().toUpperCase()+";"
                }
            }
            if(objresposta[objresposta.length - 1].trim() == ';'){
                objresposta = objresposta.substr(0,objresposta.length-1)
            }
            

            if (this.tipoPergunta == 'Resposta objetiva') {
                if (!pergunta.resposta) {
                    this.textobotaosalvar = "SALVAR"
                    return this.modalAlerta = true;
                }
            }
            try {
                    objresposta = objresposta.trim()


                    let body = {
                        id: 2,
                        chave: 'PERGUNTAEXTRA',
                        descricao: 'formulariodeperguntaextranowebcheckin',
                        ativo: true,
                        idioma: this.idiomaSelecionado.sigla,
                        pergunta:{
                            texto:pergunta.pergunta,
                            formulario:2,
                            respostasaceitas:{"tipo":pergunta.resposta},
                            ativo:true,
                            idioma:this.idiomaSelecionado.sigla,
                            resposta_aberta: pergunta.resposta_aberta,
                            resposta_data: pergunta.resposta_data,
                            resposta_horario: pergunta.resposta_horario,
                            resposta_checkincheckout: pergunta.resposta_checkincheckout,
                            obs: pergunta.obs,
                            agrupamento: this.switch3 && this.agrupamento ? this.agrupamento : null,
                        }
                    }

                    if(pergunta.id) {
                        body = {
                        id: 2,
                        chave: 'PERGUNTAEXTRA',
                        descricao: 'formulariodeperguntaextranowebcheckin',
                        ativo: true,
                        idioma: this.idiomaSelecionado.sigla,
                        pergunta:{
                            id: pergunta.id,
                            texto:pergunta.pergunta,
                            formulario:2,
                            respostasaceitas:{"tipo":pergunta.resposta},
                            ativo:true,
                            idioma:this.idiomaSelecionado.sigla,
                            resposta_aberta: pergunta.resposta_aberta,
                            resposta_data: pergunta.resposta_data,
                            resposta_horario: pergunta.resposta_horario,
                            resposta_checkincheckout: pergunta.resposta_checkincheckout,
                            obs: pergunta.obs,
                            agrupamento: this.switch3 && this.agrupamento ? this.agrupamento : null,
                        }
                    }
                    }
                    console.log('pergunta', pergunta)
                    console.log('body', body)
        
                    this.$http.post("formulario/upinsert",body).then(async () =>{
                            this.salvarParametroPasso()
                            this.modelEditarPergunta = false;
                    }).catch(error =>alert(error))
            } catch (error) {
                        console.log(error)
            }
        },

        removerPergunta(pergunta, index){
            if(!pergunta.id || !pergunta.pergunta) {
                let perguntas = [...this.dataPerguntas];
                perguntas.splice(index, 1);
                this.dataPerguntas = perguntas
                return
            }
            
            const body = {
                id: pergunta.id
            }
            this.$http.post("formulario/remover/perguntas",body)
            .then(() => {
                this.dataPerguntas = [];
                this.buscarPerguntas();
            })
            .catch(error => {
                console.log(error)
            })
        },

        setModalEditarPassosWebCheckinPergunta(){
            this.$emit('setModalEditarPassosWebCheckinPergunta')
        },
        // 

        selecionarPergunta(e) {
            this.tipoPergunta = e.target.value
            if(e.target.value === 'Resposta aberta') {
                this.perguntaVisualizar.resposta = null
                this.perguntaVisualizar.resposta_aberta = true;
                this.perguntaVisualizar.resposta_data = false;
                this.perguntaVisualizar.resposta_horario = false;
                this.perguntaVisualizar.resposta_checkincheckout = false;
                return
            }

            if(e.target.value === 'Resposta Horário') {
                this.perguntaVisualizar.resposta = null
                this.perguntaVisualizar.resposta_aberta = false;
                this.perguntaVisualizar.resposta_data = false;
                this.perguntaVisualizar.resposta_horario = true;
                this.perguntaVisualizar.resposta_checkincheckout = false;
                return
            }

            if(e.target.value === 'Resposta Data') {
                this.perguntaVisualizar.resposta = null
                this.perguntaVisualizar.resposta_aberta = false;
                this.perguntaVisualizar.resposta_data = true;
                this.perguntaVisualizar.resposta_horario = false;
                this.perguntaVisualizar.resposta_checkincheckout = false;
                return
            }

            if(e.target.value === 'Resposta objetiva') {
                this.perguntaVisualizar.resposta_aberta = false;
                this.perguntaVisualizar.resposta_data = false;
                this.perguntaVisualizar.resposta_horario = false;
                this.perguntaVisualizar.resposta_checkincheckout = false;
                return
            }
            console.log(e.target.value);
            if(e.target.value === 'Resposta horário de check-in e check-out') {
                this.perguntaVisualizar.resposta = null
                this.perguntaVisualizar.resposta_aberta = false;
                this.perguntaVisualizar.resposta_data = false;
                this.perguntaVisualizar.resposta_horario = false;
                this.perguntaVisualizar.resposta_checkincheckout = true;
            }
        },

        async desativarPergunta(pergunta) {
         await this.$http.post("formulario/desativar/pergunta", pergunta).then(async resp =>{
                console.log(resp.data);
            })
            .catch((error) => {
                console.log(error.response.message);
            })
        },

        async respostaObrigatoria(pergunta) {
           await this.$http.post("formulario/desativar/pergunta/obrigatorio", pergunta).then(async resp =>{
                console.log(resp.data);
            })
            .catch((error) => {
                console.log(error.response.message);
            })
        },

        async exibirPergunta() {
            const body = {
                ativo: this.switch1
            }
            await this.$http.post("formulario/desativar/pergunta/exibir", body).then(async () =>{
                await this.buscarConfiguracoesFormulario()
            })
            .catch((error) => {
                console.log(error.response.message);
            })
        },

        async exibirPerguntaExtraObrigatorio() {
            const body = {
                ativo: this.switch2
            }
            await this.$http.post("formulario/desativar/pergunta/exibirobrigatorio", body).then(async () =>{
                await this.buscarConfiguracoesFormulario()
            })
            .catch((error) => {
                console.log(error.response.message);
            })
        },

       async buscarConfiguracoesFormulario() {
            await this.$http.post("formulario/config/formulario").then(async resp =>{
                console.log(resp.data[0])
                this.switch2 = resp.data[0].obrigatorio;
                this.switch1 = resp.data[0].ativo;
            })
            .catch((error) => {
                console.log(error.response.message);
            })
        },

    async requisitarTelas() {
        try {
         const result = await this.$http.post('/formulario/buscar/telasdeagrupamento')
         console.log(result.data)
         this.telasDeAgrupamento = result.data;
        } catch (error) {
          console.log(error)
        }
      },
    },
    async mounted() {
        await this.buscarConfiguracoesFormulario();

        await this.buscarPerguntas();

        await this.requisitarTelas();

        await  this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'WCHECKINEXIBIRPERGUNTAEXTRA'
            }).then(resp =>{
                if(resp.status == 200){
                    // alert("11")
                    // alert(resp.data[0].valor)
                    // this.exibirPerguntaExtraLocal = resp.data[0].valor === 'true' ? true : false
                    // alert("22")
                    // alert(this.exibirPerguntaExtraLocal)
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

    },
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
    height:200px;
}

  .inputs{
    background-color:white;
    border-radius:15px;
    min-height:8vh;
    padding:5px 20px 5px 20px;
    border: 1px solid #819ab4;
  }

  .cardInputs{
    display:flex;
    flex-direction:column;
    justify-content:left;
    width:100%;
    margin-bottom:0px;
    /* background-color:red; */
    
  }

  .confirmar {
    text-align: center;
    width: 65vw;
    font-weight: normal;
    color: white!important;
    padding: 10px;
}
</style>