<template>
  <v-dialog v-model="exibirModalPreCheckin" width="1000" max-width="80vw">
    <v-card style="max-width: 100%">
      <v-toolbar color="blue" class="mb-8">
        <v-flex style="display: flex" class="pa-2">
          <span style="width: 95%; color: white; font-weight: bold">
            Pré Checkin (Cadastro antecipado de hóspedes)
          </span>
          <span style="width: 5%">
            <v-btn
              x-small
              color="red"
              @click="() => this.$emit('setModalPreCheckin')"
              title="Fechar"
            >
              <v-icon x-small style="color: white">mdi-close</v-icon>
            </v-btn>
          </span>
        </v-flex>
      </v-toolbar>

      <v-card-text>
        <div
          style="
            font-size: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
          "
        >
          <div style="display: flex; align-items: center">
            <v-switch
              v-model="capturarDocs"
              style="border-radius: 5px; padding: 5px"
            ></v-switch>
            <label>Capturar Documentos e Assinatura?</label>
          </div>
          <!-- <div style="display: flex; align-items: center">
            <v-switch
              v-model="capturarAssinatura"
              style="border-radius: 5px; padding: 5px"
            ></v-switch>
            <label>Capturar Assinatura?</label>
          </div> -->
        </div>
        <div style="display: flex; justify-content: flex-end; margin-top: 50px">
          <v-btn @click="salvar()" data-cy="salvar" color="primary">
            Salvar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>  

<script>
export default {
  name: "ModalPreCheckin",
  data: () => ({
    capturarDocs: false,
    capturarAssinatura: false,
  }),

  props: ["exibirModalPreCheckin"],

  components: {},

  methods: {

    salvar() {
      this.$http
        .post("configuracao/atualizarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: "CONFIG_QRCODE_HOSPEDE",
          valor: JSON.stringify({
            capturarDocs: this.capturarDocs,
            //capturarAssinatura: this.capturarAssinatura
            capturarAssinatura: this.capturarDocs
          }),
        })
        .then(() => {
          this.$emit('setModalPreCheckin')
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async buscarConfig() {
      this.$http
      .post("configuracao/buscarParametro", {
        idhotel: localStorage.getItem("hotel"),
        chave: "CONFIG_QRCODE_HOSPEDE",
      })
      .then((resp) => {
        if (resp.status == 200) {
          if (resp.data.length > 0) {
            const obj = resp.data[0]
            const cfg = JSON.parse(obj.valor)
            this.capturarDocs = cfg.capturarDocs
            this.capturarAssinatura = cfg.capturarAssinatura
          }
        } else {
          console.error("Não foi possivel buscar a configuração.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    },
  },

  async mounted() {
    await Promise.all([
      this.buscarConfig(),
    ]);
  },
};
</script>

<style scoped>
</style>