<template>
  <v-dialog v-model="exibirModalContratoWebCheckin" width="1000" max-width="80vw">
      <v-card style="max-width: 100%">
           <v-toolbar color="blue" class="mb-8">
              <v-flex  style="display: flex" class="pa-2">
                  <span style="width:95%;color:white;font-weight:bold;" >
                      Contratos
                  </span>
                  <span style="width:5%"> 
                      <v-btn id="fecharModalContrato" x-small color="red" @click="() => this.$emit('setModalContratosWebCheckin')" title="Fechar">
                          <v-icon x-small style="color:white;">mdi-close</v-icon>
                      </v-btn>
                  </span>
              </v-flex>
          </v-toolbar>

          <v-card-text>
            <div style="font-size: 16px;display:flex;justify-content:space-between;align-items:center;padding: 10px;" >
              <div style="display: flex; align-items: center;">
                <v-switch id="checkExibirContrato" :data-ativo="exibirContratoWebCheckin?.toString()" v-model="exibirContratoWebCheckin" @click="desativarAtivarExibicao" style="border-radius:5px;padding:5px;"></v-switch>
                <label >Exibir contrato no Web Check-in?</label>  
              </div>

              <div style="display: flex; align-items: center;">
                <v-switch id="checkEnvioAposEdicao" :data-ativo="cadastroAutomaticoContratoWebCheckin?.toString()"  v-model="cadastroAutomaticoContratoWebCheckin" @click="desativarAtivarExibicao" style="border-radius:5px;padding:5px;"></v-switch>
                <label>Envios de Web Checkin-in somente após edição de contrato?</label>  
              </div>

              <div style="display: flex; align-items: center;">
                <v-btn data-cy="btn-cadastrar-contrato" @click="() => setModalCadastrarContratoWebCheckin()"  title="Cadastrar Contratos" color="#1377f2" elevation="2" icon fab>
                  <v-icon size="28s" color="#1377f2" >mdi-plus-circle-outline</v-icon>
                </v-btn>
                  <label style="margin-left: 10px;">Cadastrar Contrato</label>  
              </div>
            </div>
          <v-data-table
            :headers="headers"
            :items="contratos"
            class="elevation-1 ma-2"
            no-data-text="Nenhum contrato encontrado."
          >
          <template v-slot:[`item.ativo`]="{ item, index }">
                <v-simple-checkbox :data-ativo="item.ativo.toString()" :id="'buttonAtivarDesativar'+index" v-model="item.ativo" @click="desativarContratoEspecifico(item)">
                </v-simple-checkbox>
          </template>

          <template v-slot:[`item.Editar`]="{ item, index }">
                <button :id="'buttonEditarVisualizar'+index" class="buttonEditarVisualizar" @click="() => editarVisualizarContrato(item)">Editar/Visualizar</button>
          </template>
          </v-data-table>
          </v-card-text>
          <ModalCadastrarContratoWebCheckin
          v-if="exibirModalCadastrarContratoWebCheckin"
          :exibirModalCadastrarContratoWebCheckin="exibirModalCadastrarContratoWebCheckin"
          :dados="dados"
          @setModalCadastrarContratoWebCheckin="() => setModalCadastrarContratoWebCheckin()"
          @buscarContratosAtualizados="() => buscarContratos()"
          />
      </v-card>
  </v-dialog>
</template>  

<script>

import ModalCadastrarContratoWebCheckin from "@/components/DashViews/Modals/ModalCadastrarContratosWebCheckin.vue"

 export default {
  name: 'ModalContratosWebCheckin',
  data: () => ({
    exibirModalCadastrarContratoWebCheckin: false,
    headers: [
      { text: "Contrato", value: "titulo" },
      { text: "Canal de venda", value: "empresareservante" },
      { text: "Ativo", value: "ativo" },
      { text: "Editar", value: "Editar" },
    ],
    contratos: [],
    dados: null,
    exibirContratoWebCheckin: false,
    cadastroAutomaticoContratoWebCheckin: false,
  }),

  props: ['exibirModalContratoWebCheckin'],

  components: {
    ModalCadastrarContratoWebCheckin
  },

  methods: {

    async desativarContratoEspecifico(contrato) {
      try {
        const body = {
          ativo: contrato.ativo,
          id: contrato.id
        }
        await this.$http.post('/contrato/desativarcontratoespecifico', body);
      } catch (error) {
        alert('Erro ao atualizar, tente novamente mais tarde.');
      }
    },

    async desativarAtivarExibicao() {
      try {
        const body = {
          ativo: this.exibirContratoWebCheckin ? 'true' : 'false',
          cadastroAutomatico: this.cadastroAutomaticoContratoWebCheckin ? 'true' : 'false'
        }
        await this.$http.post('/contrato/desativarContratoWebCheckin', body);
      } catch (error) {
        alert('Erro ao atualizar, tente novamente mais tarde.');
      }
    },

    editarVisualizarContrato(contrato) {
      console.log('contrato', contrato)
      contrato.editar = true;
      this.dados = contrato;
      this.exibirModalCadastrarContratoWebCheckin = true;
    },

    async setModalCadastrarContratoWebCheckin(){
      this.dados = null;
      this.exibirModalCadastrarContratoWebCheckin = !this.exibirModalCadastrarContratoWebCheckin
    },

    async buscarContratos() {
      try {
        const result = await this.$http.post('/contrato/buscarContratos');
        console.log(result.data)
        this.contratos = result.data;
      } catch (error) {
        console.log('erro ao buscar contratos');
      }
    },

    async buscarExibirContratoWebCheckin() {
      try {
        const result = await this.$http.post('/contrato/exibirContratoWebCheckin');
        if (result.data.length > 0) {
          if (result.data[0]?.valor === 'true') {
            this.exibirContratoWebCheckin = true;
          } else {
            this.exibirContratoWebCheckin = false;
          }

          if (result.data[0]?.valor2 === 'true') {
            this.cadastroAutomaticoContratoWebCheckin = true;
          } else {
            this.cadastroAutomaticoContratoWebCheckin = false;
          }
        } else {
          this.cadastroAutomaticoContratoWebCheckin = true;
        }
      } catch (error) {
        this.exibirContratoWebCheckin = false;
        console.log('erro ao buscar contratos');
      }
    }
  },

  async mounted() {
    await Promise.all([this.buscarContratos(), this.buscarExibirContratoWebCheckin()])
  }
 }
</script>

<style scoped>

.buttonEditarVisualizar {
  background-color: rgb(13, 153, 13);
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 10px!important;
}

</style>