<template>
  <div class="wout-login" @click="avancar">
    <div class="wout-center">
      <img
        v-if="loaded"
        src="@/assets/img/logowout.png"
        width="100%"
        height="auto"
        alt="Web Checkout!"
        crossorigin
        @load="onImageLoad"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loaded: false,
    };
  },
  mounted() {
    //this.loaded = true;
    setTimeout(() => {
      this.loaded = true;
    }, 500);
  },
  methods: {
    onImageLoad() {
      this.loaded = true;
      setTimeout(() => {
        this.avancar();
      }, 2000);
    },
    avancar() {
      this.$emit("direcionar", { from: "introwout", to: "prelogin" });
    },
  },
};
</script>
