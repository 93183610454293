<template>
  <v-dialog v-model="exibirModalLogin" width="1000" max-width="80vw">
    <v-card style="max-width: 100%">
      <v-toolbar color="blue" class="mb-8">
        <v-flex style="display: flex" class="pa-2">
          <span style="width: 95%; color: white; font-weight: bold">
            Login dos Hóspedes
          </span>
          <span style="width: 5%">
            <v-btn
              x-small
              color="red"
              @click="() => this.$emit('setModalLogin')"
              title="Fechar"
            >
              <v-icon x-small style="color: white">mdi-close</v-icon>
            </v-btn>
          </span>
        </v-flex>
      </v-toolbar>

      <v-card-text>
        <div
          style="
            font-size: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
          "
        >
          <div style="display: flex; align-items: center">
            <v-radio-group
              v-model="loginConfig.tipo"
              column
            >
              <v-radio
                label="Hóspede deve informar o documento principal para o login."
                color="indigo"
                value="Documento"
              ></v-radio>
              <v-radio
                label="Hóspede deverá ralizar o login a partir da UH, dos últimos quatro dígitos do documento e da data de nascimento."
                color="indigo"
                value="UH"
              ></v-radio>
            </v-radio-group>
          </div>
        </div>
        <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
            <v-switch v-model="loginConfig.exibirLGPD" style="border-radius:5px;padding:2px;"></v-switch>
            <label>Exibir aceite da LGPD?</label>    
        </div>
        <div style="display: flex; justify-content: flex-end; margin-top: 50px">
          <v-btn @click="salvar()" data-cy="salvar" color="primary">
            Salvar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>  

<script>
export default {
  name: "ModalLogin",
  data: () => ({
    
  }),

  props: ["exibirModalLogin", "loginConfig"],

  components: {},

  methods: {
    salvar() {
      this.$emit('salvar')
    }
  },

  async mounted() {
  },

};
</script>

<style scoped>
</style>