<template>
  <v-dialog v-model="exibirModalFnrh" width="1000" max-width="80vw" style="background-color: white;">
         <v-card style="max-width: 100%; background-color: white">
              <v-toolbar color="blue" class="mb-8">
                 <v-flex  style="display: flex" class="pa-2">
                     <span style="width:95%;color:white;font-weight:bold;" >
                         FNRH's
                     </span>
                     <div style="margin-right:50px;">
                     </div>
                     <span style="width:5%;"> 
                         <v-btn x-small color="red" @click="() => this.$emit('fecharModalFNRH')"  title="Fechar">
                             <v-icon x-small style="color:white;">mdi-close</v-icon>
                         </v-btn>
                     </span>
                 </v-flex>
              </v-toolbar>                      
             <div>
                <v-data-table
                      v-if="!loading"
                      :headers="headers"
                      :items="dados"
                      class="elevation-1"
                    >
                    <template v-slot:[`item.acoes`]="{ item }">
                      <button class="button" @click="() => visualizar(item)">Visualizar</button>
                    </template>
                
              </v-data-table> 
             </div>
         </v-card>
         <VisualizarFNRH v-if="visualizarFNRH" :visualizarFNRH="visualizarFNRH" :dados="fnrhSelecionada" @fecharModalVisualizarFNRH="fecharModalVisualizarFNRH"/>
     </v-dialog>
 </template>
 
 <script>
  import VisualizarFNRH from './VisualizarFNRH.vue'
   export default {
     props: ['exibirModalFnrh', 'urlDownloadContrato', 'idreservahospede'],
     components: {
      VisualizarFNRH
     },
     data() {
       return {
        loading: false,
        dados: [],
        visualizarFNRH: false,
        headers: [
          { text: "id", value: "id" },
          { text: "Finalizada", value: "createdat" },
          { text: "Ações", value: "acoes" },
        ],
        fnrhSelecionada: null,
       }
     },
     methods: {
      fecharModalVisualizarFNRH() {
        this.visualizarFNRH = false;
      },
      visualizar(item) {
        this.fnrhSelecionada = item.dados_fnrh;
        this.visualizarFNRH = true;
      },
      async buscarFNRH() {
      try {
       const result = await this.$http.post('reserva/buscar/fnrh/hospede', {
        id: this.idreservahospede
       })
       console.log(result.data)
       this.dados = result.data;
      } catch (error) {
        console.log(error)
      }
    },
     },
     async mounted() {
      await this.buscarFNRH();
     }
   }
 </script>

 <style scoped>
.button {
  background-color: #2B81D6;
  color: white;
  border-radius: 5px;
  padding: 5px;
  font-size: small;
  font-weight: 100;
  width: 100px;
}
</style>