<template>
  <div class="password-recovery">
    <v-card elevation="12" class="recovery-card">
      <div class="imagem">
        <img
          src="/img/FichaLogo.png"
          width="200"
          height="120"
          alt="Ficha Digital"
        />
      </div>
      <form @submit.prevent="passwordRecovery">
        <div class="form-group">
          <v-text-field
            autofocus
            style="color: black"
            ref="useremail"
            v-model="useremail"
            :rules="[() => !!useremail || 'Este campo é obrigatório']"
            prepend-icon="mdi-account"
            label="Email / Login"
            placeholder="Usuário ou email"
            required
          />
          <div class="my-2">
            Enviaremos um email para recuperação de sua senha.
          </div>
          <div class="my-2">
            <v-spacer />
            <a @click="modalajuda = true">Ajuda</a>
          </div>
        </div>
        <v-card-actions class="m-2 pa-2">
          <v-spacer />
          <v-btn
            type="submit"
            align-center
            justify-center
            color="primary"
            @click="passwordRecovery"
            >OK
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
    <v-dialog
      v-model="modalajuda"
      width="50%"
      min-width="400px"
      style="min-height: 90vh"
    >
      <v-card
        style="
          min-height: 50vh;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <h1 class="mb-5">Fale com a Gente e Tire suas Dúvidas</h1>
        <v-btn width="50%" class="info mb-5">
          {{ dadoscontatoeconomy.valor2 }}
        </v-btn>
        <v-btn width="50%" class="warning mb-5">
          <v-icon right dark class="mr-3">mdi-phone</v-icon>
          {{ dadoscontatoeconomy.valor }}
        </v-btn>
        <v-btn width="50%" class="success mb-5" @click="direcionarWhatsapp()">
          <v-icon right dark class="mr-2">mdi-whatsapp</v-icon>
          {{ dadoscontatoeconomy.valor3 }}
        </v-btn>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      <div
        style="
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: space-between;
        "
      >
        <span>{{ snackText }}</span>
        <v-btn
          style="background-color: white; color: grey"
          @click="snack = false"
          >FECHAR</v-btn
        >
      </div>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      useremail: "",
      snack: false,
      snackColor: "success",
      snackText: "",
      color: "general",
      showPassword: false,
      dadoscontatoeconomy: null,
      modalajuda: false,
    };
  },
  mounted() {
    this.$http
      .post("configuracao/buscarDadosContato")
      .then(async (resp) => {
        this.dadoscontatoeconomy = resp.data[0];
      })
      .catch((error) => {
        alert(error);
        console.log(error);
      });
  },
  // Sends action to Vuex that will log you in and redirect to the dash otherwise, error
  methods: {
    direcionarWhatsapp() {
      window.open(
        "https://wa.me/" +
          this.dadoscontatoeconomy.valor3 +
          "?text=Ola,%20Preciso de uma ajudinha.",
        "_blank"
      );
    },
    passwordRecovery: function () {
      setTimeout(() => {}, 1000);
      if (this.useremail.length < 1) {
        this.snackText = "Informe seu usuário ou email cadastrado";
        this.snackColor = "warning";
        this.snack = true;
        setTimeout(() => {
          this.snack = false;
        }, 3000);
        return;
      }
      let email = this.useremail;
      this.$store
        .dispatch("passwordRecovery", { email })
        .then(() => {
          this.snackText = "Email enviado";
          this.snackColor = "success";
          this.snack = true;
          setTimeout(() => {
            this.snack = false;
            this.$router.push("/");
          }, 3000);
        })
        .catch((err) => {
          console.log(err);
          this.snackText = "Dados inválidos";
          this.snackColor = "warning";
          this.snack = true;
          setTimeout(() => {
            this.snack = false;
          }, 3000);
        });
    },
  },
  metaInfo() {
    return {
      title: "Ficha Digital | Recuperando sua senha",
    };
  },
};
</script>

<style scoped>
.password-recovery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #1d4596;
  background-size: cover;
}

.card {
  background-color: aliceblue;
}

.imagem {
  background-color: #1d4596;
  text-align: center;
}

.form-group {
  padding: 1rem;
  margin-bottom: 1rem;
}

input[type="email"] {
  width: 100%;
  padding: 0.5rem;
}
.recovery-card {
  border-radius: 8px;
  max-width: 400px;
  background-color: #fff;
}
</style>