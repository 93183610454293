<template>
    <v-dialog v-model="exibirModalEditarPassosWebCheckin" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Editar Passos do Web Checkin
                    </span>
                    <span style="width:5%">
                        <BotaoTutorial style="margin-right:30px;" urlVideo="" categoria="" legenda="Dúvidas Passos do Web Checkin"/>
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="setModalPassosWebCheckin()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>
                    <v-container class="">
                        <v-row>
                            <v-col>
                                <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;padding:0px;margin:0px;" >
                                    <v-switch hide-details v-model="finalizarFichaJob" data-cy="finalizarFicha" style="border-radius:5px;padding:0px;margin:0px;" @click="finalizarFichaJobWebCheckin"></v-switch>
                                    <label style="padding:0px;margin:0px;" >Finalizar Ficha no Web Checkin?</label>  
                                </div>
                            </v-col>
                            <!-- <v-col>
                                <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;padding:0px;margin:0px;" >
                                    <v-switch hide-details v-model="liberarNovoWebCheckin" data-cy="exibirPassosCovid" style="border-radius:5px;padding:0px;margin:0px;" @click="liberarWebCheckin"></v-switch>
                                    <label style="padding:0px;margin:0px;" >Liberar novo Web Check-in?</label>  
                                </div>
                            </v-col> -->
                            <v-col>
                                <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;padding:0px;margin:0px;" >
                                    <v-switch hide-details v-model="profissaoObrigatorio.obrigatorio" data-cy="exibirPassosCovid" style="border-radius:5px;padding:0px;margin:0px;" @click="() => salvarCamposObrigatorios(profissaoObrigatorio)"></v-switch>
                                    <label style="padding:0px;margin:0px;" >Exibir campo de profissão?</label>  
                                </div>
                            </v-col>
                            <v-col>
                                <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;padding:0px;margin:0px;" >
                                    <v-switch  hide-details v-model="cepObrigatorio.obrigatorio" data-cy="exibirPassosObservacao" style="border-radius:5px;padding:0px;margin:0px;" @click="() => salvarCamposObrigatorios(cepObrigatorio)"></v-switch>
                                    <label style="padding:0px;margin:0px;" >Captura de CEP <b>obrigatória?</b></label>  
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-container>
                        <v-row>
                        <v-col style="display: flex;">
                            <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;padding:0px;margin:0px;" >
                                <v-switch  hide-details v-model="configAceiteTermos" data-cy="exibirPassosObservacao" style="border-radius:5px;padding:0px;margin:0px;" @click="() => salvarConfigAceiteTermo(configAceiteTermos)"></v-switch>
                                <label style="padding:0px;margin:0px;" >Exibir campos de aceite termos no webcheckin?</label>  
                            </div>
                            <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;padding:0px;margin:0px;" >
                                <v-switch  hide-details v-model="configNomeHospede" data-cy="exibirPassosObservacao" style="border-radius:5px;padding:0px;margin:0 0 0 30px;" @click="() => salvarConfigPermitirNomeTitular()"></v-switch>
                                <label style="padding:0px;margin:0px;" >Permitir alteração nome do titular?</label>  
                            </div>
                            <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;padding:0px;margin:0px;" >
                                <v-switch  hide-details v-model="configEtapaWebCheckinDocumento" data-cy="exibirPassosObservacao" style="border-radius:5px;padding:0px;margin:0 0 0 30px;" @click="() => salvarConfigEtapaBuscarDocumentoWebCheckin()"></v-switch>
                                <label style="padding:0px;margin:0px;" >Etapa Buscar documento web checkin</label>  
                            </div>
                        </v-col>
                    </v-row>
                    </v-container>

                    <v-container>
                        <v-row>
                        <v-col style="display: flex;">
                            <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;padding:0px;margin:0px;" >
                                <v-switch  hide-details v-model="configTelefoneHospede" data-cy="exibirPassosObservacao" style="border-radius:5px;padding:0px;margin:0px;" @click="() => salvarConfigTelefoneHospede()"></v-switch>
                                <label style="padding:0px;margin:0px;" >Usar o telefone principal do hóspede como contato de emergência.</label>  
                            </div>
                        </v-col>
                    </v-row>
                    </v-container>

                    <div class="cardInputs" style="margin-top:10px;">
                        <label class="labels">Horario Padrão de Checkin / Checkout:</label>
                        <div style="display:flex; flex-direction: column; align-items: flex-start; width: 100%">
                            <div style="display: flex; align-items: strech; width: 100%;">
                                <v-flex xs11 sm5 style="margin-right: 10px;">
                                    <v-menu
                                        ref="menu"
                                        v-model="menu2"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        :return-value.sync="horariocheckin"
                                        lazy
                                        transition="scale-transition"
                                        offset-y
                                        full-width
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                        v-model="horariocheckin"
                                        label="Checkin"
                                        prepend-icon="access_time"
                                        readonly
                                        v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-if="menu2"
                                        v-model="horariocheckin"
                                        full-width
                                        @click:minute="$refs.menu.save(horariocheckin)"
                                    ></v-time-picker>
                                    </v-menu>
                                </v-flex>
                                <textarea 
                                    style="
                                        width: 100%; 
                                        border: 1px solid #819ab4; 
                                        border-radius: 15px;
                                        margin-top: 10px;
                                        resize: none;
                                        padding: 1em;
                                        height:60px;
                                        "
                                    v-model="infohorario.checkin"
                                    maxlength="119"
                                    placeholder="deixe sua informação aqui"/>
                            </div>
                            <div style="display: flex; align-items: strech; width: 100%;">
                                <v-flex xs11 sm5 style="margin-right: 10px;">
                                    <v-menu
                                        ref="menuaux"
                                        v-model="menu3"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        :return-value.sync="horariocheckout"
                                        lazy
                                        transition="scale-transition"
                                        offset-y
                                        full-width
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                        v-model="horariocheckout"
                                        label="Checkout"
                                        prepend-icon="access_time"
                                        readonly
                                        v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-if="menu3"
                                        v-model="horariocheckout"
                                        full-width
                                        @click:minute="$refs.menuaux.save(horariocheckout)"
                                    ></v-time-picker>
                                    </v-menu>
                                </v-flex>
                                <textarea 
                                    style="
                                        width: 100%; 
                                        border: 1px solid #819ab4; 
                                        border-radius: 15px;
                                        margin-top: 10px;
                                        resize: none;
                                        padding: 1em;
                                        height:60px;
                                        "
                                    v-model="infohorario.checkout"
                                    maxlength="119"
                                    placeholder="deixe sua informação aqui"/>
                            </div>
                        </div>
                    </div>


      
                    <div class="cardInputs" style="margin-top:10px;" v-if="tipointegracao == 'HITS'">
                        <label class="labels">Tipo de Observacao Hits</label>
                        <v-select color 
                            v-model="obstipo" 
                            :items="obstipos" 
                            item-text="nome"
                            item-value="idnotetype"
                            return-object
                            outlined style="border-radius:15px;"
                            hide-details label="">
                        </v-select>
                    </div>




                    <div class="cardInputs" style="margin-top:10px;">
                        <label class="labels">Email Adm <span style="font-size:15px!important;">( Recebe Notificações De Web Checkins Realizados)</span></label>
                        <input type="text" class="inputs" v-model="emailAdmWebCheckin" data-cy="emailAdmWebCheckin" placeholder="Informe o email que receberá notificações de webcheckin realizados" />
                    </div>

                    <div class="cardInputs">
                        <label class="labels">Email Adm Secundário <span style="font-size:15px!important;">( Recebe Notificações De Web Checkins Realizados)</span></label>
                        <input type="text" class="inputs" v-model="emailAdmWebCheckinSecundario" data-cy="emailAdmWebCheckinSecundario" placeholder="Informe um segundo email que receberá notificações de webcheckin realizados" />
                    </div>

                    <div class="cardInputs">
                        <label class="labels">Considerar Criança até que idade? </label>
                        <input type="number" class="inputs" v-model="idadelimitecrianca" data-cy="idadelimitecrianca" placeholder="Idade" />
                    </div>

                    <div class="cardInputs">
                        <label class="labels">Assunto do Email do WebCheckin</label>
                        <input type="text" class="inputs" v-model="assuntodowebcheckin" data-cy="assuntodowebcheckin" placeholder="Assunto" />
                        <label class="labels">Texto do Email do WebCheckin</label>
                        <v-textarea outlined name="input-7-4" style="border-radius:10px;" v-model="textodowebcheckin" data-cy="textodowebcheckin"></v-textarea>
                    </div>
                    

                    <!-- <div class="cardInputs">
                        <label class="labels">Estilo de Cores Web Checkin? </label>
                        <div style="display: flex; justify-content: space-between">
                            <span data-cy="estilo-span">
                                <label class="labels">Cor Principal</label>
                                <v-color-picker
                                    dot-size="25"
                                    mode="rgba"
                                    swatches-max-height="200"
                                    v-model="corPrincipal"
                                    ref="corPrincipal"
                                    ></v-color-picker>
                            </span>
                            <span data-cy="estilo-span">
                                <label class="labels">Cor Secundária</label>
                                <v-color-picker
                                    dot-size="25"
                                    mode="rgba"
                                    swatches-max-height="200"
                                    v-model="corSecundaria"
                                    ref="corSecundaria"
                                    ></v-color-picker>
                            </span>
                            <span data-cy="estilo-span">
                                <label class="labels">Cor de Fundo</label>
                                <v-color-picker
                                    dot-size="25"
                                    mode="rgba"
                                    swatches-max-height="200"
                                    v-model="corFundo"
                                    ref="corFundo"
                                    ></v-color-picker>
                            </span>
                        </div>
                    </div> -->

                    <div class="cardInputs">
                        <label class="labels">Botao Webcheckin para Seu Site </label>
                        <span data-cy="botaocor-span">
                            <v-select  outlined style="border-radius:15px;"
                                :items="botaocores" @change="mudarCorBotao(botaocor)"
                                v-model="botaocor" data-cy="botaocor" required>
                            </v-select>
                        </span>
                        <label class="labels">Copie e Cole o Codigo do botão dentro do seu site </label>
                        <v-textarea outlined name="input-7-4" style="" v-model="layoutbotaoselecionado" data-cy="layoutbotaoselecionado"></v-textarea>
                    </div>

                    <div style="display:flex;justify-content:flex-end;margin-top:50px;">
                        <v-btn @click="salvar()" data-cy="salvar" color="primary">
                            {{textobotaosalvar}}
                        </v-btn>
                    </div>

            </v-card-text>
            
                        
            
        </v-card>
    </v-dialog>
</template>          

<script>

import chroma from "chroma-js";
import BotaoTutorial from "../../material/BotaoTutorial.vue";
import {BUILD_TIPO_INTEGRACAO} from '@/constantes.js'

export default {
    data: () => ({
        tipointegracao:null,
        textoEmail:"Olá @nome, te desejamos um feliz aniversário repleto de felicidades.",
        isImg:null,
        imgSrc:null,
        imgTipo:null, 
        exibirPassosCovid:null,
        exibirPassosObservacao:null,
        exibirPassosHorario:null,
        emailAdmWebCheckin:null,
        emailAdmWebCheckinSecundario:null,
        idadelimitecrianca:null,
        estilos:['AZUL','VERDE','VERMELHO'],
        estilo:'AZUL',
        botaocores:['AZUL','VERDE','VERMELHO'],
        botaocor:'AZUL',
        layoutbotaoselecionado:'AZUL',
        assuntodowebcheckin:null,
        textodowebcheckin:null,
        textobotaosalvar:"SALVAR",
        horariocheckin:null,
        horariocheckout:null,
        finalizarFicha:false,
        necessidadeSelfie: false,
        infohorarioCheckin: null,
        infohorarioCheckout: null,
        infohorario: {
            checkin: null,
            checkout: null
        },
        corPrincipal: null,
        corSecundaria: null,
        corFundo: null,
        obstipos:[],
        obstipo:null,
        telefoneObrigatorio: {
            id: null,
            chave: null,
            obrigatorio: false
        },
        cepObrigatorio: {
            id: null,
            chave: null,
            obrigatorio: false
        },
        profissaoObrigatorio: {
            id: null,
            chave: null,
            obrigatorio: false
        },
        liberarNovoWebCheckin: false,
        finalizarFichaJob: false,
        configAceiteTermos: false,
        configNomeHospede: false,
        horariocheckinTeste: null,
        menu2: false,
        modal2: false,
        menu3: false,
        moda3: false,
        configEtapaWebCheckinDocumento: false,
        configTelefoneHospede: false,
    }),
    props:["exibirModalEditarPassosWebCheckin"],
    components:{
    BotaoTutorial
},
    watch: {
        corPrincipal: function(){
            this.corSecundaria = chroma.scale([this.corPrincipal.hex, 'white']).colors(6)[2]
            this.corFundo = chroma.scale([this.corSecundaria, 'white']).colors(6)[4]
        }
    },

    methods: {


        horarioTeste() {
            console.log(this.horariocheckin)
            console.log(this.horariocheckinTeste)
            const date = this.selectedDate.toISOString().split('T')[0];
                const time = this.selectedTime;
                console.log(`${date}T${time}.000-03:00`)
        },

        async buscarConfigAceiteTermos() {
            try {
            const result = await this.$http.post("configWebCheckin/buscarconfigCheckAceiteTermos/")
            console.log('config configAceiteTermo', result.data)
            if(result.data.length > 0) {
                if (result?.data[0]?.valor === 'true') {
                    this.configAceiteTermos = true;
                }

                if (result?.data[0]?.valor === 'false') {
                    this.configAceiteTermos = false
                }

                if (!result?.data[0]?.valor || !result || !result?.data[0]) {
                    this.configAceiteTermos = true
                }
            } else {
                this.configAceiteTermos = true
            }
            } catch (error) {
                console.log(error);
            }
        },

        async salvarConfigAceiteTermo() {
            try {
                await this.$http.post("configWebCheckin/configCheckAceiteTermos/", { ativo: this.configAceiteTermos });
            } catch (error) {
                console.log(error);
            }
        },
        
        async finalizarFichaJobWebCheckin() {
            try {
                await this.$http.post("configWebCheckin/finalizarFicha/", { ativo: this.finalizarFichaJob });
            } catch (error) {
                console.log(error);
            }
        },

        async buscarConfigfinalizarFichaJob() {
            try {
                const result = await this.$http.post("configWebCheckin/buscarConfigFinalizarFicha/");
                if(result.data.length > 0) {
                    if(result.data[0].valor === 'true') {
                        this.finalizarFichaJob = true
                    } else {
                        this.finalizarFichaJob = false;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },

        async buscarLiberarWebCheckin() {
            try {
            const result = await this.$http.post("configWebCheckin/buscarLiberarNovoWebCheckin/")
            console.log('config webcheckin', result.data)
            if(result.data.length > 0) {
                this.liberarNovoWebCheckin = result.data[0].valor === 'true' ? true : false
            } else {
                this.liberarNovoWebCheckin = false
            }
            } catch (error) {
                console.log(error);
            }
        },

        async liberarWebCheckin() {
            try {
            const body = {
                ativo: this.liberarNovoWebCheckin ? 'true' : 'false'
            }
            await this.$http.post("configWebCheckin/ativarWebCheckin/", body)
            } catch (error) {
                console.log(error);
            }
        },

        async salvarCamposObrigatorios(item) {
            try {
            await this.$http.post("configWebCheckin/salvarconfig/camposobrigatorios", item)
            } catch (error) {
                console.log(error);
            }
        },

        mudarCorBotao(cor){

            if(cor == 'AZUL'){
                this.layoutbotaoselecionado = `<button onclick="window.location.href='${window.location.origin}/webcheckinacesso/1'" title="Faça agora seu Checkin Digital"
                                        style="position: fixed;bottom: 20px;right: 30px;
                                        z-index: 9999;border: none;outline: none;background-color: #1560e5;color: white;
                                        cursor: pointer;padding: 15px;border-radius: 10px;height:4em;width:10em;
                                        display:flex;justify-content: center;align-items: center;">Fazer Check-In</button>`
            }

            if(cor == 'VERDE'){
                this.layoutbotaoselecionado = `<button onclick="window.location.href='${window.location.origin}/webcheckinacesso/1'" title="Faça agora seu Checkin Digital"
                                        style="position: fixed;bottom: 20px;right: 30px;
                                        z-index: 9999;border: none;outline: none;background-color: #15e57d;color: white;
                                        cursor: pointer;padding: 15px;border-radius: 10px;height:4em;width:10em;
                                        display:flex;justify-content: center;align-items: center;">Fazer Check-In</button>`
            }

            if(cor == 'VERMELHO'){
                this.layoutbotaoselecionado = `<button onclick="window.location.href='${window.location.origin}/webcheckinacesso/1'" title="Faça agora seu Checkin Digital"
                                        style="position: fixed;bottom: 20px;right: 30px;
                                        z-index: 9999;border: none;outline: none;background-color: #e51515;color: white;
                                        cursor: pointer;padding: 15px;border-radius: 10px;height:4em;width:10em;
                                        display:flex;justify-content: center;align-items: center;">Fazer Check-In</button>`
            }

        },
        salvar(){
            this.textobotaosalvar = "SALVANDO..."
            this.salvarExibicaoCovid().then(()=>{
                this.salvarExibicaoObservacao().then(()=>{
                    this.salvarExibicaoHorario().then(()=>{
                        this.salvarEmailAdm().then(()=>{
                            this.salvarEmailAdmSecundario().then(()=>{
                                this.salvarIdadeCrianca().then(()=>{
                                    this.salvarEstiloCor().then(()=>{
                                        this.salvarTextoWebCheckin().then(()=>{
                                            this.salvarTextoHorarioCheckinECheckout().then(()=>{
                                                this.salvarFinalizarFicha().then(() => {
                                                    this.salvarNecessidadeSelfie().then(()=>{
                                                        console.log('chegou aqui')
                                                        this.setModalPassosWebCheckin()
                                                    })
                                                })
                                            })
                                        })
                                    })
                                })
                            })
                        })
                    })
                })
            })
        },
        salvarEstiloCor(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'WCHECKINESTILO',
                    "valor" :  this.$refs.corPrincipal?.internalValue,
                    "valor2" : this.$refs.corSecundaria?.internalValue,
                    "valor3" : this.$refs.corFundo?.internalValue,
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
            
        },
        salvarExibicaoCovid(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'WCHECKINEXIBIRCOVID',
                    "valor" : this.exibirPassosCovid
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
            
        },
        salvarExibicaoObservacao(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'WCHECKINEXIBIROBS',
                    "valor" : this.exibirPassosObservacao
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
            
        },
        salvarExibicaoHorario(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'WCHECKINEXIBIRHORARIO',
                    "valor" : this.exibirPassosHorario
                }).then(resp =>{
                    // if(resp.status == 200){
                    //     this.setModalPassosWebCheckin()
                    // }else{
                    //     alert("Não foi alterar os dados de exibição do termos")
                    // }
                    
                    if(localStorage.getItem('tipointegracao') == 'HITS'){
                        this.$http.post("configuracao/atualizarParametro", {  
                            "idhotel" : localStorage.getItem('hotel'),
                            "chave" : 'TIPOOBSERVACAO',
                            "valor" : this.obstipo.idnotetype
                        }).then(resp =>{
                            resolve(resp)
                        }).catch(error =>{
                            reject(error)
                            console.log(error)
                        })
                    }else{
                        resolve(resp)
                    }
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
        },
        salvarFinalizarFicha(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'WCHECKINFINALIZARFICHA',
                    "valor" : this.finalizarFicha
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
        },
        salvarEmailAdm(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'EMAILADMWEBCHECKIN',
                    "valor" : this.emailAdmWebCheckin
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
            
        },
        salvarEmailAdmSecundario(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'EMAILADMWEBCHECKIN2',
                    "valor" : this.emailAdmWebCheckinSecundario
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
            
        },
        salvarIdadeCrianca(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'IDADECRIANCAWEBCHECKIN',
                    "valor" : this.idadelimitecrianca
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
            
        },
        salvarTextoWebCheckin(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'TEXTOWEBCHECKIN',
                    "valor" : this.assuntodowebcheckin,
                    "valor2" : this.textodowebcheckin
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
            
        },
        salvarTextoHorarioCheckinECheckout(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'HORARIOPADRAOWEBCHECKIN',
                    "valor" : this.horariocheckin,
                    "valor2" : this.horariocheckout,
                    "valor3" : (this.infohorario.checkin != null && this.infohorario.checkin.trim() != '') ? this.infohorario.checkin : null,
                    "valor4" : (this.infohorario.checkout != null && this.infohorario.checkout.trim() != '') ? this.infohorario.checkout : null,
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
            
        },
        salvarNecessidadeSelfie(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'NECESSIDADESELFIE',
                    "valor" : this.necessidadeSelfie,
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
        },
        setModalPassosWebCheckin(){
            this.textobotaosalvar = "SALVAR"
            this.$emit('setModalEditarPassosWebCheckin')
        },

        buscarTiposObservacoes() {
            this.$http.get("/integracao/getTipoObs/HITS/"+localStorage.getItem('hotel')).then(resp =>{
                if(resp.status == 200){
                    console.log(resp)
                this.obstipos = resp.data
                }else{
                    alert("Não foi possivel buscar os dados")
                }

                })
                .catch(error =>{
                alert(error)
                console.log(error)
                })
        },
        async buscarConfigNomeHospede() {
            try {
                const result = await this.$http.post("reserva/buscar/permitir/nome/titular")
                    this.configNomeHospede = result?.data?.value;
                    console.log(this.configNomeHospede)
            } catch (error) {
                alert(error);
                console.log(error);
            }
        },
        async salvarConfigPermitirNomeTitular() {
            try {
                await this.$http.post("reserva/permitir/nome/titular")
            } catch (error) {
                alert(error);
                console.log(error);
            }
        },
        salvarConfigEtapaBuscarDocumentoWebCheckin() {
            return new Promise( (resolve,reject) =>{
                const dataAtual = new Date();
                const dia = String(dataAtual.getDate()).padStart(2, '0');
                const mes = String(dataAtual.getMonth() + 1).padStart(2, '0'); // Mês começa do zero
                const ano = dataAtual.getFullYear();
                const horas = String(dataAtual.getHours()).padStart(2, '0');
                const minutos = String(dataAtual.getMinutes()).padStart(2, '0');
                const segundos = String(dataAtual.getSeconds()).padStart(2, '0');
                const dataFormatada = dia + '/' + mes + '/' + ano + ' ' + horas + ':' + minutos + ':' + segundos;
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'CONFIGETAPAWEBCHECKINBUSCARDOCUMENTO',
                    "valor" : this.configEtapaWebCheckinDocumento ? 'true' : 'false',
                    "valor2" : dataFormatada,
                    "valor3": localStorage.getItem('userid'),
                    "valor4": localStorage.getItem('user'),
                    "valor5": localStorage.getItem('useremail'),
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
        },
        salvarConfigTelefoneHospede() {
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'CONFIGTELEFONEHOSPEDEPRINCIPAL',
                    "valor" : this.configTelefoneHospede ? 'true' : 'false',
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
        },
        async buscarConfigEtapaWebCheckin() {
            
            this.$http.post("configuracao/buscarParametro", {  
                        "idhotel" : localStorage.getItem('hotel'),
                        "chave" : 'CONFIGETAPAWEBCHECKINBUSCARDOCUMENTO'
                    }).then(resp =>{
                        
                        if(resp.status == 200){
                            this.configEtapaWebCheckinDocumento = resp.data[0].valor === 'true' ? true : false
                        }else{
                            alert("Não foi possivel buscar os dados")
                        }
                    })
                    .catch(error =>{
                        console.log(error)
                    })

            this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'CONFIGTELEFONEHOSPEDEPRINCIPAL'
            }).then(resp =>{
                
                if(resp.status == 200){
                    this.configTelefoneHospede = resp.data[0].valor === 'true' ? true : false
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })
        }
    },
   async mounted() {
    await this.buscarConfigfinalizarFichaJob();
    await this.buscarLiberarWebCheckin()
    await this.buscarConfigAceiteTermos();
    await this.buscarConfigNomeHospede();
    await this.buscarConfigEtapaWebCheckin()
    console.log(BUILD_TIPO_INTEGRACAO)
    if(BUILD_TIPO_INTEGRACAO === 'HITS') {
        await this.buscarTiposObservacoes();
    }
        await this.$http.post("configWebCheckin/buscarconfig/camposobrigatorios").then(resp =>{
               console.log('data >>>>>>>>>>>>>', resp.data[0]);
               if(resp.data.length > 0) {
                resp.data.forEach((e) => {
                    if(e.chave === "CEPENDERECOHOSPEDE") {
                        this.cepObrigatorio = e
                    } 
                    if(e.chave === "TELEFONEPRINCIPALHOSPEDE") {
                        this.telefoneObrigatorio = e
                    }

                    if(e.chave === "PROFISSAOHOSPEDE") {
                        this.profissaoObrigatorio = e
                    }
                })
               }
            })
            .catch(error =>{
                console.log(error)
            })

        // this.tipointegracao = localStorage.getItem('TIPOINTEGRACAO');
         this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'TIPOINTEGRACAO'
            }).then(resp =>{
                if(resp.status == 200){
                    this.tipointegracao = resp.data[0].valor
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

        this.layoutbotaoselecionado = `<button onclick="window.location.href='${window.location.origin}/webcheckinacesso/1'" title="Faça agora seu Checkin Digital"
                                        style="position: fixed;bottom: 20px;right: 30px;
                                        z-index: 9999;border: none;outline: none;background-color: #1560e5;color: white;
                                        cursor: pointer;padding: 15px;border-radius: 10px;height:4em;width:10em;
                                        display:flex;justify-content: center;align-items: center;">Fazer Check-In</button>`

        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'WCHECKINESTILO'
            }).then(resp =>{
                if(resp.status == 200){
                    this.corPrincipal = JSON.parse(resp.data[0].valor)
                    this.corSecundaria = JSON.parse(resp.data[0].valor2)
                    this.corFundo = JSON.parse(resp.data[0].valor3)
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })
        
        //busca de dados 
        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'WCHECKINEXIBIROBS'
            }).then(resp =>{
                if(resp.status == 200){
                    this.exibirPassosObservacao = resp.data[0].valor == 'true' ? true : false
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

            
        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'WCHECKINEXIBIRCOVID'
            }).then(resp =>{
                if(resp.status == 200){
                    this.exibirPassosCovid = resp.data[0].valor == 'true' ? true : false
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'WCHECKINEXIBIRHORARIO'
            }).then(resp =>{
                if(resp.status == 200){
                    this.exibirPassosHorario = resp.data[0].valor == 'true' ? true : false
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'EMAILADMWEBCHECKIN'
            }).then(resp =>{
                if(resp.status == 200){
                    this.emailAdmWebCheckin = resp.data[0].valor
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'EMAILADMWEBCHECKIN2'
            }).then(resp =>{
                if(resp.status == 200){
                    this.emailAdmWebCheckinSecundario = resp.data[0].valor
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'IDADECRIANCAWEBCHECKIN'
            }).then(resp =>{
                if(resp.status == 200){
                    this.idadelimitecrianca = resp.data[0].valor
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'TEXTOWEBCHECKIN'
            }).then(resp =>{
                if(resp.status == 200){
                    this.assuntodowebcheckin = resp.data[0].valor
                    this.textodowebcheckin = resp.data[0].valor2
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'HORARIOPADRAOWEBCHECKIN'
            }).then(resp =>{
                if(resp.status == 200){
                    const [datePart, timePart] = resp.data[0].valor.split('T');
                    const [datePart2, timePart2] = resp.data[0].valor2.split('T');
                    console.log(timePart)
                    console.log(datePart)
                    console.log(datePart2)
                    if (timePart?.split('.')[0]) {
                        this.horariocheckin = timePart.split('.')[0]
                    } else {
                        this.horariocheckin = resp.data[0].valor
                    }

                    if (timePart2?.split('.')[0]) {
                        this.horariocheckout = timePart2.split('.')[0]
                    } else {
                        this.horariocheckout = resp.data[0].valor2
                    }
                    this.infohorario = {
                      checkin: resp.data[0].valor3,
                      checkout: resp.data[0].valor4
                    }
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

            this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'WCHECKINFINALIZARFICHA'
            }).then(resp =>{
                if(resp.status == 200){
                    this.finalizarFicha = resp.data[0].valor == 'true' ? true : false
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

            this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'NECESSIDADESELFIE'
            }).then(resp =>{
                if(resp.status == 200){
                    this.necessidadeSelfie = resp.data[0].valor == 'true' ? true : false
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

            

            console.log('tipo de integração >>>>>>>>>>>>>>>>>', localStorage.getItem('tipointegracao'))
            if(localStorage.getItem('tipointegracao') == 'HITS'){


                    // await this.$http.get("/integracao/getTipoObs/"+localStorage.getItem('TIPOINTEGRACAO')+"/"+localStorage.getItem('hotel')).then(resp =>{

                    //     if(resp.status == 200){
                    //     this.obstipos = resp.data
                    //     }else{
                    //         alert("Não foi possivel buscar os dados")
                    //     }

                    // })
                    // .catch(error =>{
                    //     alert(error)
                    //     console.log(error)
                    // })

            

                    this.$http.post("configuracao/buscarParametro", {  
                        "idhotel" : localStorage.getItem('hotel'),
                        "chave" : 'TIPOOBSERVACAO'
                    }).then(resp =>{
                        
                        if(resp.status == 200){
                            this.obstipo = parseInt(resp.data[0].valor)
                        }else{
                            alert("Não foi possivel buscar os dados")
                        }
                    })
                    .catch(error =>{
                        console.log(error)
                    })


            }

            



    },
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
    height:200px;
}

  .inputs{
    background-color:white;
    border-radius:15px;
    min-height:8vh;
    padding:5px 20px 5px 20px;
    border: 1px solid #819ab4;
  }

  .cardInputs{
    display:flex;
    flex-direction:column;
    justify-content:left;
    width:100%;
    margin-bottom:20px;
    /* background-color:red; */
    
  }

  
  .inputsHorario{
    background-color:white;
    text-align:center;
    border-radius:15px;
    min-height:8vh;
    font-size:25px;
    padding:10px;
    /* padding:5px 20px 5px 20px; */
    border: 1px solid #819ab4;
    width:100px; 
    margin-right: 20px;
    margin-top:10px;    
    
  }
</style>