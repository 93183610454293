<template>
        
        <div class="" style="flex-wrap:wrap;width:100%;">
            
            <h2 style="text-align:center;padding-top:3vh;margin-bottom:20px;font-size:20px!important;" >{{ "Selecione o Hospede para Fazer Checkin" }}</h2>

                    <div class="resumoInicialHospedes"  v-for="hospede in hospedesprincipais" :key="hospede.id"
                        style="margin-bottom:30px;padding-bottom:0px!important;" @click="direcionarParaReservas(hospede.numeroreserva)">
                        <div style="margin-bottom:10px;display:flex;">
                            <div style="margin-right:20px;font-weight:bold!important;">Numero da Reserva:</div>
                            <div>{{hospede.numeroreserva}}</div>
                        </div>
                        <div style="margin-bottom:10px;display:flex;">
                            <div style="margin-right:20px;font-weight:normal!important;"><b>Nome: </b></div>
                            <div>{{hospede.nome+' '+hospede.sobrenome}}</div>
                        </div>
                        <div style="margin-bottom:5px;display:flex;justify-content:space-between;">
                            <div style="margin-bottom:10px;">
                                <div><b>Chegada</b></div>
                                <div>{{dados.datacheckinprevisto | formatarData}}</div>
                            </div>
                            <div style="margin-bottom:10px;">
                                <div><b>Saída</b></div>
                                <div>{{dados.datacheckoutprevisto | formatarData}}</div>
                            </div>
                        </div>

                        <div>
                            Formas de Acessar Web Checkin
                        </div>

                        <div style="display:flex;justify-content:space-between;padding-top:10px;flex-wrap:wrap;">
                           <v-btn style="width:100%;margin-bottom:0px;" @click="abrirWebCheckin(hospede)" color="primary">Acessar Agora</v-btn>
                        </div>
                        <div style="display:flex;justify-content:space-between;padding-bottom:20px;padding-top:10px;flex-wrap:wrap;">
                           <v-btn style="width:30%;min-width:100px;margin-bottom:20px;" @click="abrirQRCode(hospede)" color="info">QR CODE</v-btn>
                           <v-btn style="width:30%;min-width:100px;margin-bottom:20px; color: white;" @click="abrirEmail(hospede)" color="brown">Email</v-btn>
                           <v-btn style="width:30%;min-width:100px;margin-bottom:20px;" @click="whatsappClickToChat(hospede)" color="success">Whatsapp</v-btn>
                        </div>
                        
                        
                    </div>


                    <v-dialog v-model="modalQRCode" width="70%"  style="min-height:90vh;">
                        <v-card style="min-height:50vh;display:flex;justify-content:center;align-items:center;flex-direction:column;">

                            <VueQrcode  style="width:200px" :value="urlwebcheckinhospedeselecionado" />
                            
                        </v-card>
                    </v-dialog>

                    <v-dialog v-model="modalEmail" width="70%"  style="min-height:90vh;">
                        <v-card style="min-height:50vh;display:flex;justify-content:center;align-items:center;flex-direction:column;">

                            <div style="width:50%;min-width:200px;" >
                                <div class="cardInputs">
                                    <label class="labels">Email:</label>
                                    <input type="email" v-model="email"  class="inputs" placeholder="Informe o email"  />
                                    <v-btn style="width:100%;margin-top:20px;" @click="enviarEmailWebCheckin()" color="primary">Enviar Link</v-btn>
                                </div>
                            </div>
                            
                        </v-card>
                    </v-dialog>


                    <v-dialog v-model="exibirmodalWhatsappApiGratis" width="400px" max-width="80vw">
                        <div style="background-color: white; width: 100%; height: 100%; display: flex; flex-direction: column;">
                            <v-toolbar color="blue" class="mb-8">
                                    <v-flex style="display: flex;padding: 10px;">
                                    <span>
                                        Whatsapp
                                    </span>
                                    <v-spacer></v-spacer>
                                    <v-btn x-small color="red" @click="exibirmodalWhatsappApiGratis = false" title="Fechar"
                                        ><v-icon x-small color="white">mdi-close</v-icon></v-btn
                                    >
                                    </v-flex>
                                </v-toolbar>
                        <div style="padding:20px">
                            <div style="display: flex; flex-direction: row;">
                                            <v-text-field
                                            v-model="ddi"
                                            label="DDI"
                                            style="min-width: 20px !important;"
                                            class="mr-2"
                                            @keypress="isNumber($event)"
                                            />
                                            <v-text-field
                                            v-model="ddd"
                                            label="DDD"
                                            style="min-width: 20px !important;"
                                            class="mr-2"
                                            @keypress="isNumber($event)"
                                            />
                                            <v-text-field
                                            v-model="telefone"
                                            append-icon="mdi-whatsapp"
                                            label="WhatsApp"
                                            style="min-width: 200px !important;"
                                            class="mr-2"
                                            @keypress="isNumber($event)"
                                            />
                            </div>
                            <div style="display: flex; justify-content: flex-end; align-items: flex-end;">
                            <v-btn style="background-color:green;color:white; width: 30%;" @click="abrirWhatsappClickToChat(reservaSelecionadaWhatsapp)">Abrir</v-btn>
                            </div>              
                            </div>       
                        </div>
                    </v-dialog>
        </div>

</template>

<script>

// import moment from 'moment'
import VueQrcode from 'vue-qrcode'

export default { 
    props:["dados"],
    components:{
        VueQrcode 
    },
    data: () =>{
        return {
            // // os dados que salvarei na base do ficha
            hospedesprincipais:[],
            hospedeprincipal:null,
            urlwebcheckin:null,
            urlwebcheckinhospedeselecionado:null,
            modalQRCode:false,
            modalEmail:false,
            email:null,
            emailwebcheckinselecionado:null,
            hospedeselecionado:null,
            liberarNovoWebCheckin: null,
            idhotelEs: null,
            exibirmodalWhatsappApiGratis: false,
            reservaSelecionadaWhatsapp: null,
            ddd: null,
            ddi: null,
            telefone: null,
        }
    },
    methods:{
        whatsappClickToChat(item){
            console.log('hospede >>>>>>>>>>>>>>>>>>>>>>', item)
            this.exibirmodalWhatsappApiGratis = true;
            this.reservaSelecionadaWhatsapp = item
            this.ddd = item.celularddd.trim();
            this.ddi = item.celularddi.trim();
            this.telefone = item.celular.trim().replace('-','').replace('-','')
        },
        isNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
                $event.preventDefault();
            }
        },
        async abrirWhatsappClickToChat() {
            try {
                let item = this.reservaSelecionadaWhatsapp;
                await this.salvarDadosFone(item)
                const telefoneReplace = this.telefone.trim().replace('-','').replace('-','')
                let ddi = this.ddi.trim()
                let ddd = this.ddd.trim()
                let telefone = telefoneReplace
                let telefoneCompleto = ddi+ddd+telefone
                console.log('telefone completo >>>>>>>', telefoneCompleto)
                let urlwebcheckin;
                urlwebcheckin = `https://webcheckin.fd.economysoftware.com.br/${this.idhotelEs}/${item.idreserva}`;
                let urlzap = `https://wa.me/${telefoneCompleto}?text=Olá, evite filas ao chegar no hotel, segue o link para o seu web checkin no *${localStorage.getItem('nomehotel')}*: ${urlwebcheckin}.`
                window.open(urlzap,'_blank')

            } catch (error) {
                alert('Não foi possível enviar, verifique o telefone do hospede.') 
            }
        },
        salvarDadosFone(item) {
            if(!this.ddi.length > 0 && !this.ddd.length > 0 && !this.telefone.length > 0){
                return
            }


            let body = {
                reserva: {
                id: item.idreserva,
                hotel: localStorage.getItem("hotel"),
                },
                hospede: {
                id: item.idhospede,
                celularddi: this.ddi,
                celularddd: this.ddd,
                celular: this.telefone
                },
            };

            this.$http
                .post("/reserva/upinsert", body)
                .then((response) => {
                if (response.status == 200) {
                    console.log("Telefone salvo na ficha do hóspede.")
                } else {
                    console.log(JSON.stringify(response))
                }
                })
                .catch((error) => {
                console.log(error);
                console.log(JSON.stringify(body));
                alert('Erro ao salvar dados na ficha do hóspede.')
                });
        },
        enviarEmailWebCheckin(){
            this.$http.post("reserva/email/webcheckinportal",{
                    id:this.hospedeselecionado.id,
                    hotel:localStorage.getItem("hotel"),
                    emaildirecionar:this.email,
                    urlwebcheckin:this.urlwebcheckinhospedeselecionado,
                    assuntodowebcheckin:this.dados.assuntodowebcheckin,
                    textodowebcheckin:this.dados.textodowebcheckin
                    // hotel:localStorage.getItem('hotel'),
                })
            .then(resp =>{
                    alert(JSON.stringify(resp))
            })
        },
        abrirQRCode(hospede){
            if(!this.liberarNovoWebCheckin) {
                this.urlwebcheckinhospedeselecionado = this.urlwebcheckin+'/'+hospede.id
            } else {
            let urlwebcheckin = `https://webcheckin.fd.economysoftware.com.br/${this.idhotelEs}/${hospede.idreserva}`;
            this.urlwebcheckinhospedeselecionado = urlwebcheckin
            }
            this.modalQRCode = true
        },
        abrirEmail(hospede){
            this.hospedeselecionado = hospede
            if(!this.liberarNovoWebCheckin) {
                this.urlwebcheckinhospedeselecionado = this.urlwebcheckin+'/'+hospede.id
            } else {
            let urlwebcheckin = `https://webcheckin.fd.economysoftware.com.br/${this.idhotelEs}/${hospede.idreserva}`;
            this.urlwebcheckinhospedeselecionado = urlwebcheckin
            }
            this.modalEmail = true
        },

        async buscarLiberarWebCheckin() {
            try {
            const result = await this.$http.post("configWebCheckin/buscarLiberarNovoWebCheckin/")
            if(result.data.length > 0) {
                this.liberarNovoWebCheckin = result.data[0].valor === 'true' ? true : false
                this.idhotelEs = result.data[0].id_hotel_cliente_es
            } else {
                this.liberarNovoWebCheckin = false
            }
            } catch (error) {
                console.log(error);
            }
        },

        abrirWebCheckin(hospede){
        if(!this.liberarNovoWebCheckin) {
            window.open(this.urlwebcheckin+'/'+hospede.id, '_blank').focus();
        } else {
          let urlwebcheckin = `https://webcheckin.fd.economysoftware.com.br/${this.idhotelEs}/${hospede.idreserva}`;
          window.open(urlwebcheckin, '_blank').focus();
        }
        },
        direcionarParaReservas(numeroreserva){
            var dadossalvar     = this.dados
            dadossalvar.numeroreserva   = numeroreserva
            this.$emit('atualizarDados',dadossalvar,'Reservas')
        },
        async listarHospedes(){

            // alert(this.dados.numeroreserva)
            // alert(JSON.stringify(
            //     {
            //     principal:true,
            //     idempresareservante:this.dados.canal,
            //     datacheckinprevisto:this.dados.datacheckinprevisto,
            //     datacheckoutprevisto:this.dados.datacheckoutprevisto,
            //     hotel:1,
            //     // hotel:localStorage.getItem('hotel'),
            //     }
            // ))
            // var nome = null
            // var sobrenome = null

            // if(this.dados.nome){
            //     var nomedividido = this.dados.nome.split(' ')
            //     if(nomedividido.length > 1){
            //         nome = nomedividido[0]
            //         sobrenome = nomedividido[1]
            //     }else{
            //         nome = nomedividido[0]
            //     }
            // }


            await this.$http.post("reserva/select",{
                numeroreserva: `${this.dados.numeroreserva}`
                // principal:true,
                // idempresareservante:this.dados.canal,
                // datacheckinprevisto:this.dados.datacheckinprevisto,
                // datacheckoutprevisto:this.dados.datacheckoutprevisto,
                // hotel:1,
                // nome:nome,
                // sobrenome:sobrenome
                // hotel:localStorage.getItem('hotel'),
                })
            .then(resp =>{
                    // alert(JSON.stringify(resp.data))
                    this.hospedesprincipais = resp.data.filter(p => {
                        return p.nome != undefined;
                    }).sort((a, b) => (a.nome > b.nome) ? 1 : -1);

                    this.hospedeprincipal = this.hospedesprincipais[0]
                    

            })
            .catch((error) => {
                console.log(error)
            })

        },
        
        salvarDados(){
            
            var dadossalvar     = this.dados
            dadossalvar.canal   = this.canal.idempresareservante
           
            this.$emit('atualizarDados',dadossalvar)
            

        },
        async avancar(){

            if( await this.validarFormulario() ){
                this.salvarDados()
            }  
            
        }
    },
  async  mounted(){
        await this.listarHospedes()
        await this.buscarLiberarWebCheckin();
        this.urlwebcheckin = window.location.protocol + "//" + window.location.host+"/webcheckin"
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>