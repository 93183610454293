<template>
  <div class="wout-login">
        <div class="center"  v-if="componenteAtual == 'selecionarLado'">
            <span class="confirmar" style="font-size: 30px;">{{ this.dados.descricao }}</span>
            <span class="confirmar" style="margin-bottom: 10px; margin-top: 5px;">Capturar fotos</span>
            <div class="continuar" v-if="dados.doc_frente">
              <label for="frenteDoc"><span>Frente do documento</span><v-icon large class="icone-continuar">{{!capturasDocumento.docFrente ? 'mdi-chevron-right' : 'mdi-check'}}</v-icon></label>
              <input type="file" name="frenteDoc" id="frenteDoc" accept="image/*"  @change="onFileChange" :disabled="capturasDocumento.docFrente">
            </div>

            <div class="continuar" v-if="dados.doc_verso">
              <label for="versoDoc"><span>Verso do documento</span><v-icon large class="icone-continuar">{{!capturasDocumento.docVerso ? 'mdi-chevron-right' : 'mdi-check'}}</v-icon></label>
              <input type="file" name="versoDoc" id="versoDoc" accept="image/*"  @change="onFileChange" :disabled="capturasDocumento.docVerso">
            </div>
        </div>
      <ConfirmarFotoCapturada v-if="componenteAtual == 'confirmarFotoCapturada'" :dados="imgExibir" @verificar="verificar"/>
    </div>
</template>

<script>
  import ConfirmarFotoCapturada from './ConfirmarFotoCapturada.vue';
  import { serializarImagemNew } from '../../utils/serializar';
  export default {
    components: {
      ConfirmarFotoCapturada,
    },
    props: ['dados'],
    data() {
      return {
        capturasDocumento: {
          docFrente: null,
          docVerso: null,
        },
        componenteAtual: 'selecionarLado',
        imgExibir: null,
        ladoCapturado: null,
      }
    },
    methods: {
      onFileChange(e) {
        serializarImagemNew(e).then(resp =>{
          this.imgExibir = resp.dados;
          this.componenteAtual = 'confirmarFotoCapturada'
          this.ladoCapturado = e.target.name;
        }).catch(error =>{
          console.log(error);
        })
      },

      verificar(e) {
        if(e) {
          this.confirmar(this.ladoCapturado, this.imgExibir);
          return;
        }
        this.ladoCapturado = null;
        this.imgExibir = null;
        this.componenteAtual = 'selecionarLado';
      },

      confirmar(e, img) {
        if(e === 'frenteDoc') {
          this.capturasDocumento.docFrente = img;
          this.componenteAtual = 'selecionarLado';
          this.etapasDeCapturaConcluida();
          return;
        }
        this.capturasDocumento.docVerso = img;
        this.componenteAtual = 'selecionarLado';
        this.etapasDeCapturaConcluida();
      },

     async etapasDeCapturaConcluida() {
        if(this.dados.doc_frente && this.dados.doc_verso) {
          if (this.capturasDocumento.docFrente && this.capturasDocumento.docVerso) {
            console.log('etapda concluida')
            await this.salvarDocs();
            this.$emit('etapasConcluidas');
            return
          } else {
            return;
          }
        }

        if(this.dados.doc_frente || this.dados.doc_verso) {
          if (this.capturasDocumento.docFrente || this.capturasDocumento.docVerso) {
            await this.salvarDocs();
            this.$emit('etapasConcluidas');
          } else {
            return;
          }
        }
      },

      async salvarDocs() {
        if(!localStorage.getItem('checkout-idhospede')) {
          alert('Id do hospede indefinido')
          return
        }
        const body = {
          docFrente: this.capturasDocumento.docFrente,
          docVerso: this.capturasDocumento.docVerso,
          idHospede: localStorage.getItem('checkout-idhospede'),
          tipoDoc: this.dados.id
        }
        console.log(body)
        try {
        await this.$http.post("/webcheckout/salvarDocumentos", body, { headers: {'x-access-token': localStorage.getItem('checkout-token')}});
      } catch (error) {
        console.log(error)
      }
      }

    },
    mounted() {
     console.log('dados >>>>>>>>>', this.dados)
    }
  }
</script>

<style scoped>
.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}
.confirmar {
    text-align: center;
    width: 65vw;
    font-weight: normal;
    color: white!important;
}
.continuar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-weight: normal;
    width: 70vw;
    max-width: 300px;
    color: white;
    background-color: rgb(60,140,241)!important;
    border-radius: 15px;
    margin-bottom: 20px;
}
.icone-continuar {
    font-weight: bold;
    color:#1560e5!important;
}

input[type="file"] {
    display: none;
}
</style>