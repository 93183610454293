<template>
  <v-dialog v-model="exibirModalGeneros" width="1000" max-width="80vw">
    <v-card style="max-width: 100%">
      <v-toolbar color="blue" class="mb-8">
        <v-flex style="display: flex" class="pa-2">
          <span style="width:95%;color:white;font-weight:bold;">
            Gêneros
          </span>
          <span style="width:5%">
            <v-btn id="fecharModalContrato" x-small color="red" @click="() => this.$emit('fecharModalGeneros')" title="Fechar">
              <v-icon x-small style="color:white;">mdi-close</v-icon>
            </v-btn>
          </span>
        </v-flex>
      </v-toolbar>

      <v-card-text>
        <div style="display: flex; align-items: center; background-color: ;">
          <div style="margin: 0 30px 0 0">
            <v-text-field style="width: 100%;" label="Gênero" v-model="novoGenero" outlined hide-details></v-text-field>
          </div>
          <div>
            <v-select
              id="select-empresa-reservante"
              v-model="generoPadrao"
              style="width: 100%;"
              :items="listGeneroPadrao"
              hide-details
              label="Gênero correspondente"
              outlined
            >
            </v-select>
          </div>
          <v-btn color="blue" style="color: white; margin: 0 0 0 10px" @click="adicionarGenero">Adicionar</v-btn>
        </div>
        <v-data-table
          :headers="headers"
          :items="generos"
          no-data-text="Nenhuma empresa reservante encontrado."
        >
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>

 export default {
  name: 'ModalGeneros',
  data: () => ({
    headers: [
      { text: "Id", value: "id" },
      { text: "Gênero", value: "genero" },
      { text: "Correspondente", value: "correspondente" },
    ],
    generoPadrao: 'M',
    listGeneroPadrao: ['M', 'F'],
    generos: [],
    novoGenero: null
  }),

  props: ['exibirModalGeneros'],

  methods: {
    async buscarGeneros() {
      try {
        const result = await this.$http.get('generos/')
        console.log('result >>>>>>>>>>>>>>', result);
        return result.data;
      } catch (error) {
        console.log(error.response)
        alert(error.response.message);
      }
    },
    async adicionarGenero() {

      if (!this.novoGenero || !this.novoGenero?.length) return alert('É obrigatório digitar um gênero');

      if (!this.generoPadrao || !this.generoPadrao?.length) return alert('É obrigatório selecionar um gênero correspondente');

      try {
        await this.$http.post('generos/', {
          genero: this.novoGenero,
          correspondente: this.generoPadrao
        })
        this.generos = await this.buscarGeneros();
      } catch (error) {
        alert(error.response.message);
      }
    }
   },

  async mounted() {
    this.generos = await this.buscarGeneros();
    console.log(this.generos)
  }
 }
</script>

<style scoped>
.buttonEditarVisualizar {
  background-color: #2B81D6;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 10px!important;
}
</style>