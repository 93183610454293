<template>
  <v-layout>
    <v-flex>
      <div>
        <v-card>

          <v-toolbar color="light-blue" class="ma-2" style="color: white;display:flex;flex-direction:column;">
            <div style="min-width:100%!important;display:flex;justify-content:space-between;">
              <span class="toolbar-title">Encontre sua Reserva</span>
              <span style="width:5%;">
                <BotaoTutorial style="margin-right:30px;margin-left:30px;" urlVideo="" categoria=""
                  legenda="Dúvidas Encontre sua Reserva" />
              </span>
            </div>
          </v-toolbar>

          <div style="padding:10px;display:flex;flex-wrap:wrap;justify-content:space-between;">

            <v-text-field class="mr-3" type="text" label="Nome" v-model="filtroNome" data-cy="filtro-nome"
              style="width:40%!important;" />

            <v-text-field class="ml-3" type="text" label="Sobrenome" v-model="filtroSobrenome"
              data-cy="filtro-sobrenome" style="width:40%!important;" />

            <!-- <v-text-field class="mr-3" type="text" label="Reserva" v-model="filtroReserva" data-cy="filtro-reserva" style="width:40%!important;" />
            
                <v-text-field class="ml-3" type="text" label="Nome" v-model="filtroNome" data-cy="filtro-nome" style="width:40%!important;" /> -->

            <v-text-field class="mr-3" style="width:40%!important;" type="date" label="Check-IN Inicial"
              data-cy="filtro-datainicial" v-model="filtroDataInicial" />

            <v-text-field class="ml-3" style="width:40%!important;" type="date" label="Check-IN Final"
              data-cy="filtro-datafinal" v-model="filtroDataFinal" />

          </div>
          <!-- <div style="display:flex;justify-content:center;font-size:0.9vw;margin-bottom:5px;">

              <v-select class="mr-2" type="text" label="Status" v-model="filtroStatusReserva" :items="listadestatus"
                item-value="id" item-text="descricao" data-cy="filtro-status" return-object
                style="max-width:95%!important;">
              </v-select>
    
          </div> -->
          <v-card>
            <v-row style="max-width: 100%;display:flex;align-items:center;justify-content:center;">
              <!-- StatusReserva -->

              <!-- Filtro -->
              <!-- <div style="display:flex;justify-content:center;font-size:0.9vw;margin-top:5px;"> -->
              <!-- <v-flex> -->
              <!-- Reserva -->







              <!-- </v-flex> -->
              <!-- </div> -->
              <div style="display:flex;justify-content:center;padding:15px;">
                <!-- Botão Pesquisar -->
                <v-btn @click="buscarReservasPeloBtn()" small color="primary" class="mx-2" :disabled="disabledSearch"
                  style="color:white;min-height:40px;font-weight:bold;" data-cy="btn-filtrar">
                  <v-icon color="white" v-if="textoBtnPequisar == 'Pesquisar'">mdi-magnify</v-icon>
                  {{textoBtnPequisar}}
                </v-btn>
                <!-- Botão Limpar Filtros -->
                <v-btn @click="limparFiltro()" small color="warning" class="mx-2" data-cy="btn-limparfitro"
                  style="color:white;min-height:40px;font-weight:bold;">
                  <v-icon color="white">mdi-broom</v-icon>
                  Limpar Filtros
                </v-btn>

              </div>
            </v-row>

          </v-card>
          <div class="pl-2 pr-2">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details />
          </div>

          <v-dialog v-model="dialog">
            <div v-if="step == 0">
              <ReservaFdExpress v-bind:numeroreserva="editedItem.numeroreserva" @update="buscaReservas()"
                @fechar="dialog=false" :ativarImportacoes="ativarImportacoes" />
            </div>
            <!-- FIM PASSO INICIAL -->
          </v-dialog>

          <!-- DIALOG 2 -->
          <v-dialog v-model="dialog2" width="500">
            <v-card style="max-width: 500px">
              <v-toolbar color="blue" class="mb-8">
                <v-flex style="display: flex;padding: 10px;">
                  <span>
                    Reserva: {{ editedItem.numeroreserva }}
                    <!-- {{ editedItem.datacheckinprevisto }}  ||
                    {{ editedItem.datacheckoutprevisto }} -->
                    <br />
                    Hóspede: {{ editedItem.nome }} {{ editedItem.sobrenome }}
                  </span>
                  <v-spacer></v-spacer>
                  <v-btn x-small color="red" @click="dialog2 = false" title="Fechar"><v-icon x-small
                      color="white">mdi-close</v-icon></v-btn>
                </v-flex>
              </v-toolbar>
              <v-card-text>
                <div style="align-items: center">
                  <v-row style="">
                    <v-flex xs12 style="display: flex;">
                      <div style="display:flex;justify-content:center;align-items:center;width:60%;padding-left:10px;">
                        <v-text-field v-model="email" append-icon="mdi-email" type="email" label="Email"
                          style="min-width: 100% !important;" class="mr-2" :rules="emailRules" />
                      </div>
                      <!--  -->
                      <div style="display:flex;justify-content:center;align-items:center;padding-left:10px;">
                        <v-btn x-small class="mr-2" color="#00bb2d" title="Enviar e-mail direto..."
                          @click="setModalDirectMail(),salvarDadosEmail(editedItem)"><v-icon small
                            color="white">mdi-email-send</v-icon></v-btn>
                        <v-btn x-small class="mr-2" color="orange" title="Enviar e-mail Web Check-IN"
                          @click="enviarEmail()"><v-icon small color="white">mdi-email-plus</v-icon></v-btn>
                        <v-btn x-small class="mr-2" color="purple" title="Enviar e-mail Check-OUT Express"><v-icon small
                            color="white">mdi-cash-multiple</v-icon></v-btn>
                        <v-btn x-small color="blue" title="Enviar e-mail Check-IN Pay"><v-icon small
                            color="white">mdi-counter</v-icon></v-btn>
                      </div>
                    </v-flex>
                  </v-row>
                  <v-row>
                    <v-flex xs12 style="display: flex">
                      <div style="display:flex;justify-content:center;align-items:center;width:60%;padding-left:5px;">
                        <v-text-field v-model="ddi" label="DDI" style="min-width: 20px !important;" class="mr-2"
                          @keypress="isNumber($event)" />
                        <v-text-field v-model="ddd" label="DDD" style="min-width: 20px !important;" class="mr-2"
                          @keypress="isNumber($event)" />
                        <v-text-field v-model="telefone" append-icon="mdi-whatsapp" label="WhatsApp"
                          style="min-width: 200px !important;" class="mr-2" @keypress="isNumber($event)" />
                      </div>

                      <div style="display:flex;justify-content:center;align-items:center;padding-left:10px;">
                        <v-btn x-small class="mr-2" color="#00bb2d" title="Enviar WhatsApp Direto"
                          :disabled="!isWhatsAppSet"
                          @click="setModalDirectWhatsApp(), salvarDadosFone(editedItem)"><v-icon small
                            color="white">mdi-whatsapp</v-icon></v-btn>
                        <v-btn x-small class="mr-2" color="orange" title="Enviar WhatsApp Web Check-IN"
                          :disabled="!isWhatsAppSet"><v-icon small color="white">mdi-email-plus</v-icon></v-btn>
                        <v-btn x-small class="mr-2" color="purple" title="Enviar WhatsApp Check-OUT Express"
                          :disabled="!isWhatsAppSet"><v-icon small color="white">mdi-cash-multiple</v-icon></v-btn>
                        <v-btn x-small class="mr-2" color="blue" title="Enviar WhatsApp Check-IN Pay"
                          :disabled="!isWhatsAppSet"><v-icon small color="white">mdi-counter</v-icon></v-btn>

                      </div>

                    </v-flex>

                  </v-row>
                  <div style="height : 20px!important;">
                    <span><strong> {{ respostaAcao }}</strong></span>
                  </div>
                  <div style="height: 20px!important;">
                    {{ isWhatsAppSetText }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>

          <ModalImportar @importar="(valor) => importar(valor)" @setModalImportar="(valor) => setModalImportar(valor)"
            :exibirModalImportar="exibirModalImportar" :processando="processando" :resultado="resultado"
            :periodoDefault="periodoImportacaoDefault" />

          <ModalSolicitacoes @consultarSolicitacoes="(valor) => consultarSolicitacoes(valor)"
            @setModalSolicitacoes="(valor) => setModalSolicitacoes(valor)"
            :exibirModalSolicitacoes="exibirModalSolicitacoes" :solicitacoes="solicitacoes"
            :itemselecionado="itemselecionado" />

          <ModalDirectMail :email="email" :exibirModalDirectMail="exibirModalDirectMail"
            @setModalDirectMail="setModalDirectMail()" />

          <ModalDirectWhatsApp :numeroWhatsapp="this.ddi+this.ddd+this.telefone"
            :exibirModalDirectWhatsApp="exibirModalDirectWhatsApp" @setModalDirectWhatsApp="setModalDirectWhatsApp()" />

          <ModalLogReservas v-if="exibirModalLogReserva" :exibirModalLogReserva="exibirModalLogReserva"
            :logNumeroReserva="logNumeroReserva" :idReserva="idReserva" @setModalLogReserva="setModalLogReserva()" />

          <!-- Para ordenar: -->
          <!-- :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" -->
          <v-data-table :headers="headers" :items="ReservasList" :search="search" class="elevation-1"
            no-data-text="Nenhum registro encontrado. Clique na vassoura para limpar o filtro de datas.">

            <template v-slot:[`item.numeroreserva`]="{ item }">
              <!-- <v-icon style="margin-bottom:1px;color:#1976d2;margin-right:2px;" :title="item.idhospedepms" small>mdi-qrcode</v-icon> -->
              <span @click="editItem(item)" style="color:#1976d2;cursor:pointer;"
                data-cy="td-numeroreserva">{{item.numeroreserva}}</span>
            </template>

            <!-- <template v-slot:[`item.actions`]="{ item }">
              
              <v-icon color="orange"
              class="mr-1"
              :data-cy="`acao-log_${item.numeroreserva}`"
                @click="[setModalLogReserva(), logNumeroReserva = item.numeroreserva, idReserva=item.idreserva]" title="Log Reserva">
                mdi-eye
              </v-icon>  

              <v-icon color="green"
              class="mr-1"
                :data-cy="`acao-webcheckout_${item.numeroreserva}`"
                @click="direcionarWebCheckout(item)" title="Iniciar o webcheckout.">
                mdi-cash
              </v-icon>
              <v-icon color="purple"
              class="mr-1"
                :data-cy="`acao-fichadigital_${item.numeroreserva}`"
                @click="editItem(item)" title="Iniciar o preenchimento da Ficha Digital">
                mdi-login
              </v-icon>
              <v-icon color="brown"
                :data-cy="`acao-notificar_${item.numeroreserva}`"
                @click="notify(item), listarDados(item)" title="Envie notificações por email e WhatsApp">
                mdi-email-send
              </v-icon>
              <v-icon color="blue" v-if="item.totalsolicitacoes>0"
                @click="exibirSolicitacoes(item)" title="Solicitações do hóspede!">
                mdi-chat-alert
              </v-icon>
            </template> -->

            <template v-slot:[`item.nome`]="{ item }">
              <v-icon class="pb-1" v-if="item.principal" small color="orange" title="Hóspede principal.">
                mdi-crown
              </v-icon>
              <a v-if="item.ativo ===true" @click="editItem(item)" :title="item.idhospedepms"
                :data-cy="`td-nome_${item.numeroreserva}`">{{item.nome}}</a>
              <a v-else style="color:#909ba6;"
                :title="'id hospede pms: '+item.idhospedepms+'; Importado em '+item.dataimportacaopms"
                :data-cy="`td-nome_${item.numeroreserva}`">{{item.nome}}</a>
            </template>

            <template v-slot:[`item.sobrenome`]="{ item }">
              <a v-if="item.ativo ===true" @click="editItem(item)">{{item.sobrenome}}</a>
              <a v-else style="color:#909ba6;">{{item.sobrenome}}</a>
            </template>

            <template v-slot:[`item.uh`]="{ item }">
              <a @click="editItem(item)">{{item.uh}}</a>
            </template>

            <!-- <template v-slot:[`item.iconepreenchido`]="{ item }">
              <v-icon v-if="item.iconepreenchido" color="green" title="Ficha 100% preenchida!">
                mdi-check-circle
              </v-icon>
              <v-icon v-else color="red" :title="`${item.percentualpreenchido}% preenchido. ${item.faltampreencher}.`">
                mdi-account-alert
              </v-icon>
              <v-icon v-if="item.enviadopms" color="orange" title="Ficha enviada ao PMS.">
                mdi-clipboard-check
              </v-icon>
            </template> -->

            <template v-slot:[`item.datacheckinprevisto`]="{ item }">
              <a @click="editItem(item)"> {{item.datacheckinprevisto | formatarData}}</a>
            </template>
            <template v-slot:[`item.datacheckoutprevisto`]="{ item }">
              <a @click="editItem(item)">{{item.datacheckoutprevisto | formatarData}}</a>
            </template>



            <template v-slot:[`footer.page-text`]>
              <v-btn color="primary" dark class="ma-2" @click="voltarPagina"> Voltar </v-btn>
              <v-btn color="primary" dark class="ma-2" @click="avancarPagina"> Avançar </v-btn>
            </template>

          </v-data-table>
          <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
              <span>{{ snackText }}</span>
              <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
            </div>
          </v-snackbar>
        </v-card>
      </div>
    </v-flex>

    <ModalQrCodeWebcheckin @setModalQrCodeWC="(valor) => setModalQrCodeWC(valor)"
      :exibirModalQrCodeWC="exibirModalQrCodeWC" :itemSelecionadoQRcode="itemSelecionadoQRcode" />

  </v-layout>
</template>

<script>

import ModalSolicitacoes from './Modals/ModalSolicitacoes'
import ReservaFdExpress from "../stepfd/ReservaFdExpress.vue";
import moment from "moment";
import ModalDirectMail from './Modals/ModalDirectMail.vue';
import ModalDirectWhatsApp from './Modals/ModalDirectWhatsApp.vue';
import orto from '../../utils/ortografia'
import ModalImportar from './Modals/ModalImportar'

import ModalQrCodeWebcheckin from './Modals/ModalQrCodeWebcheckin'
import dayjs from 'dayjs';

import ModalLogReservas from './Modals/ModalLogReserva.vue'
import BotaoTutorial from '../material/BotaoTutorial.vue';


export default {
    name:'ReservaListExpress',
    props: [],
    components: {
        ModalSolicitacoes,
        ModalDirectMail,
        ModalDirectWhatsApp,
        ReservaFdExpress,
        ModalQrCodeWebcheckin,
        ModalLogReservas,
        ModalImportar,
        BotaoTutorial
    },
  data: () => ({
    // sortBy: 'numeroreserva',
    // sortDesc: true,
    pagina:1,
    itemSelecionadoQRcode:null,
    exibirModalQrCodeWC:false,
    textoBtnPequisar:'Pesquisar Sua Reserva',
    respostaAcao : "",
    telefone : "",
    ddi : "",
    ddd : "",
    listadestatus:[],
    exibirModalSolicitacoes:false,
    exibirModalSolicitacoesResponder:false,
    exibirModalDirectMail : false,
    exibirModalLogReserva : false,
    exibirModalDirectWhatsApp : false,
    exibirModalImportar : false,
    periodoImportacaoDefault: {
      DataInicial: dayjs(new Date()).format('YYYY-MM-DD'),
      DataFinal: dayjs(new Date()).add(7, 'day').format('YYYY-MM-DD'),
    },
    processando: (localStorage.getItem('processando')==='true'||localStorage.getItem('processando')==true),
    resultado: (localStorage.getItem('resultado')),
    isWhatsAppSet: false,
    isWhatsAppSetText : "",
    solicitacoes:[],
    solicitacao:null,
    itemselecionado:null,
    step: 0,
    maxStep: 10,
    filtroReserva:null,
    filtroSobrenome:null,
    filtroNome:null,
    filtroStatusReserva:null,
    filtroDataInicial: moment().format('YYYY-MM-DD'),
    filtroDataFinal: moment().format('YYYY-MM-DD'),
    fichaDigital : {},
    
    logNumeroReserva : 0,
    idReserva : 0,

    descstatusreserva: "Confirmada",
    emailRules : [
      (v) => !!v || "E-mail é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
    ],
    email: "",
    snack: false,
    snackColor: "",
    snackText: "",
    max25chars: (v) => v.length <= 25 || "Input too long!",
    pagination: {},
    ReservasList: [],
    checkboxAdmin: true,
    checkboxActive: true,
    rowsAmount: [
      15,
      20,
      25,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 },
    ],
    dialog: false,
    dialog2: false,
    search: "",
    headers: [
      { text: "Reserva", value: "numeroreserva", width: "120px", },
    //   { text: "Ações", value: "actions", width: "120px", sortable: false},
      { text: "UH", value: "uh", width: "50px" },
      { text: "Nome", value: "nome", width: "150px" },
      { text: "Sobrenome", value: "sobrenome", width: "150px" },
    //   { text: "Status", value: "iconepreenchido", width: "80px" },
    //   { text: "Status Reserva", value: "descricaostatusreserva", width: "80px" },
      { text: "Check-IN", value: "datacheckinprevisto", width: "130px" },
      { text: "Check-OUT", value: "datacheckoutprevisto", width: "130px" },
    //   { text: "Cliente", value: "empresareservante", width: "150px" },
    //   { text: "Tipo Hóspede", value: "tipohospede", width: "100px" },
    //   { text: "Pensão", value: "tipopensao", width: "190px" },
    ],
    editedIndex: -1,
    editedItem: {
      nome: "",
      sobrenome: "",
      email: "",
      isAdmin: true,
      isActive: true,
    },
    defaultItem: {}
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    disabledSearch(){ 
      return this.textoBtnPequisar != 'Pesquisar Sua Reserva'
    },
    ativarImportacoes() {
      return localStorage.getItem('ativarimportacoes') == 'true'
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  // called when page is created before dom
  created() {
    this.buscaReservas();
  },
  async mounted(){
    const result = await this.$http.post('/configWebCheckin/buscarIdHotelEs');
    localStorage.setItem('idHotelEs', result.data[0].id_hotel_cliente_es)
    this.$store.dispatch("setPagina", 'EXPRESS')
    this.$socket.disconnect();
    this.$socket.io.opts.query.usuario = localStorage.getItem('useremail')
    this.$socket.io.opts.query.tipo = 'DASHBOARD'
    this.$socket.io.opts.query.idhotel = 1
    this.$socket.io.opts.query.tipo_acesso = 'DASHBOARD'
    this.$socket.connect();
    await this.listarStatusReservas()
    await this.buscarDadosAPI();
    window.addEventListener('processando-changed', (event) => {
        this.processando = (event.detail.processando==='true'||event.detail.processando==true)
        this.resultado = event.detail.resultado
    });
  },
  methods: {
    avancarPagina(){
      this.pagina = this.pagina + 1
      this.buscaReservas();
    },
    voltarPagina(){
      if(this.pagina > 1){
        this.pagina = this.pagina - 1
        this.buscaReservas();
      }
    },
    setQrCodeModal(item){
      this.setModalQrCodeWC(true)
      this.itemSelecionadoQRcode = item
    },
    setModalQrCodeWC(valor){
        this.exibirModalQrCodeWC = valor
    },
    async listarStatusReservas() {
      await this.$http
        .post("/geo/statusreserva/select",{orderby: "descricao", distinct: true})
        .then((response) => {
            this.listadestatus = response.data;
            // console.log("STATUS AQUI: ", this.listadestatus);
            const statusTodos = { id: -1, descricao: 'Todos' };
            let statusreservapadrao = { id: -1, descricao:'Todos'};
            this.listadestatus.push(statusTodos)
            this.listadestatus = orto.ordenarArray(this.listadestatus, "descricao")
            this.filtroStatusReserva = statusreservapadrao
        })
        .catch((error) => {
          console.log(error);
        });
    },
    direcionarWebCheckin(item){
        // alert(JSON.stringify(item.id))
        let urlwebcheckin = window.location.origin+'/webcheckin/'+item.id
        window.open(urlwebcheckin,'_blank')
    },
    direcionarWebCheckout(item){
        let urlwebcheckout = window.location.origin+'/webcheckout/'+item.hotel
        window.open(urlwebcheckout,'_blank')
    },
    setModalSolicitacoes(valor){
        this.exibirModalSolicitacoes = valor
    },
    setModalImportar(valor) {
        this.exibirModalImportar = valor
    },
    importar(periodo) {
      // importar reservas no período informado
      //console.log(JSON.stringify(periodo))
      const params = {
        id_hotel: localStorage.getItem('idhotelpms'),
        useremail: localStorage.getItem('useremail'),
        periodo: periodo
      }
      this.$http.post('reserva/importar/periodo/',params)
      .then(async () => {
        localStorage.setItem('processando',true)
        this.processando = true
        localStorage.setItem('resultado','')
        this.resultado = ''
        // Este trecho foi colocado no App.vue para liberar o usuário para outras funções...
        this.sockets.subscribe("backend_to_dash_importar_reservas_retorno", (dados) => {
            this.sockets.unsubscribe("backend_to_dash_importar_reservas_retorno")
            //console.error(dados)
            this.processando = false
            this.resultado = dados.retorno+' '+JSON.stringify(dados.periodo)
        });
      })
    },    
    limparFiltro() {
      this.pagina = 1
      this.filtroReserva = null
      this.filtroNome = null
      this.filtroSobrenome = null
      this.filtroDataInicial = null
      this.filtroDataFinal = null
      let statusreservapadrao = {id:-1,descricao:'Todos'}
      this.filtroStatusReserva = statusreservapadrao

      // this.buscaReservas()
    },
    buscarReservasPeloBtn(){
      this.pagina = 1
      this.buscaReservas()
    },
    buscaReservas() {
      
      this.textoBtnPequisar = "Pesquisando"

      if(!this.filtroDataInicial && !this.filtroReserva && !this.filtroNome && !this.filtroSobrenome){
        this.filtroDataInicial = dayjs().format('YYYY-MM-DD')
      }
      if(!this.filtroDataFinal && !this.filtroReserva && !this.filtroNome && !this.filtroSobrenome){
        this.filtroDataFinal = dayjs().format('YYYY-MM-DD')
      }
      let dados = {  
        "hotel" : localStorage.getItem('hotel'),
        "idioma" : "pt-BR",
         "datacheckinprevisto" : {
           "de": this.filtroDataInicial,
           "ate": this.filtroDataFinal
        },
        "orderby": 'numeroreserva desc, ordem asc'
      }

      if(this.filtroReserva){
        dados.numeroreserva = this.filtroReserva
      }

      if(this.filtroNome){
        dados.nome = this.filtroNome
      }

      if(this.filtroSobrenome){
        dados.sobrenome = this.filtroSobrenome
      }

      if(this.filtroStatusReserva && this.filtroStatusReserva.id >= 0){
        dados.idstatusreserva = this.filtroStatusReserva.id
      }


    try {
      
      dados.pagina = this.pagina

      console.log('ola')
    
      this.$http.post("/reserva/select", dados)
      .then((response) => {
          if (response.status == 200) {
            console.log('aqui >>>>>>>> 1')
            this.textoBtnPequisar = "Pesquisar Sua Reserva"
            this.ReservasList = response.data;
            if(response.data.length == 0){
                if(this.filtroReserva){
                  //se não encontrar a reserva pesquisada pelo numero então solicita importação ao robô.
                  this.$socket.emit('dash_backend_importa_reserva', {reserva:this.filtroReserva})  
                }
            }
          }else{
            console.log('aqui >>>>>>>> 2')
            this.textoBtnPequisar = "Pesquisar Sua Reserva"
            this.snackColor= "orange"
            this.snackText= "Nenhum registro encontrado"
            this.snack = true
          }
        })
        .catch((error) => {
          console.log('aqui >>>>>>>> 3')
          this.textoBtnPequisar = "Pesquisar Sua Reserva"
          console.error(error)
          this.ReservasList = []
          //alert('Nenhuma Reserva foi Encontrada')
        });

      } catch (error) {
      console.log('aqui >>>>>>>> err')
        this.textoBtnPequisar = "Pesquisar Sua Reserva"
        alert(error)
      }
    },

    avancar() {
      if (this.step < this.maxStep) {
        this.step++;
      }
    },

    voltar() {
      if (this.step > 0) {
        this.step--;
      }
    },
    isNumber($event){
      //console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
          $event.preventDefault();
      }
    },    
    // object.assign fills in the empty object with the properties of item
    editItem(item, dbox = true) {
      this.editedIndex = this.ReservasList.indexOf(item);
      this.step = 0;
      this.editedItem = Object.assign({}, item);
      this.fichaDigital = this.editedItem
      this.dialog = dbox;
      console.log(this.editedItem)
    },

    async consultarSolicitacoes(reservahospede){
        await this.$http.post('reserva/solicitacao/select',{
                    reservahospede:reservahospede
            })
            .then(resp =>{
              console.log(resp.data)
              this.solicitacoes = resp.data
            })
    },
    async exibirSolicitacoes(item) {
      this.itemselecionado = item
      console.log("Chamando a exibirSolicitacoes")
      this.exibirModalSolicitacoes = !this.exibirModalSolicitacoes
      this.exibirModalSolicitacoesResponder = false
      await this.consultarSolicitacoes(item.id)
    },
    notify(item, dbox = true) {
      this.editedIndex = this.ReservasList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog2 = dbox;
      this.respostaAcao = ""
    },
    listarDados(item) {
      //console.log(item)
      console.log('Iniciando')
      this.$http
        .post("/reserva/select", {
          hotel: localStorage.getItem("hotel"),
          idhospede: item.idhospede,
          idreserva: item.idreserva
          
        })
        .then((response) => {
          //console.log(JSON.stringify(response.data))
          this.ddi = response.data[0].celularddi;
          this.ddd = response.data[0].celularddd;
          this.telefone = response.data[0].celular;
          this.email = response.data[0].email;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    salvarDadosFone(item) {
       if(!this.ddi.length > 0 && !this.ddd.length > 0 && !this.telefone.length > 0){
        //this.respostaAcao = "Dados de telefone inválidos. Verifique"
        return
      }

      let body = {
        reserva: {
          idreservapms:item.idreservapms,
          id: item.idreserva,
          hotel: localStorage.getItem("hotel"),
        },
        hospede: {
          idhospedepms:item.idhospedepms,
          id: item.idhospede,
          celularddi: this.ddi,
          celularddd: this.ddd,
          celular: this.telefone
        },
      };

      //console.log("body")
      //console.log(body)
      this.$http
        .post("/reserva/upinsert", body)
        .then((response) => {
          if (response.status == 200) {
            console.log("Telefone salvo na ficha do hóspede.")
          } else {
            console.log(JSON.stringify(response))
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(JSON.stringify(body));
          alert('Erro ao salvar dados na ficha do hóspede.')
        });
    },
    setModalDirectMail(){
      if(/.+@.+\..+/.test(this.email)){
        this.exibirModalDirectMail = !this.exibirModalDirectMail
        this.respostaAcao = ""
        //this.salvarDadosEmail(item)
      }else{
        this.respostaAcao = 'Informe o e-mail corretamente.'
      }
    },
    salvarDadosEmail(item) {
      console.log("item")
      console.log(item)
      if(!this.email.length > 0){
        return
      }
      let body = {
        reserva: {
          id: item.idreserva,
          idreservapms:item.idreservapms,
          hotel: localStorage.getItem("hotel"),
        },
        hospede: {
          idhospedepms:item.idhospedepms,
          id : item.idhospede,
          email: this.email
        },
      };

      //console.log("body")
      //console.log(body)
      this.$http
        .post("/reserva/upinsert", body)
        .then((response) => {
          if (response.status == 200) {
            console.log('Email salvo na ficha do hóspede.')
          } else {
            console.log(JSON.stringify(response))
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(JSON.stringify(body));
          console.log('Erro ao salvar Email na ficha do hóspede')
        });
    },

    setModalLogReserva(){
        this.exibirModalLogReserva = !this.exibirModalLogReserva
    },
    setModalDirectWhatsApp(){
      if(this.telefone.length > 0){
        this.exibirModalDirectWhatsApp = !this.exibirModalDirectWhatsApp
        this.respostaAcao = ""
      }else{
        this.respostaAcao = 'Informe numero WhatsApp corretamente.'
      }
        
    
    },

    deleteItem(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        this.editedItem = Object.assign({}, item);
        let endpoint = `users/delete/${this.editedItem.username}`;
        let method = "delete";
        this.callTableAction(item, endpoint, method);
      }
    },

    close() {
      this.dialog = false;
      this.dialog2 = false;

      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    enviarEmail(){
      if(this.email.length > 0){
        console.log(this.editedItem)
        this.$http.post('/reserva/setReenvioWebcheckin', { 
            id:this.editedItem.id,
            idpessoa:this.editedItem.idpessoa,
            idtipo:7,
            email:this.email
            }).then(response =>{
            console.log(response)
            this.respostaAcao = "E-mail enviado com sucesso."
            setTimeout(() => { 
              this.dialog2 = false
            }, 1000);
            
            
            this.email = ""
          }).catch(error=> {
            console.log(error)
            console.log(error.data)
          })

        // this.respostaAcao = ""
        // let link = window.location.origin+'/webcheckin/'+this.editedItem.id
        // let body = {
        //   destinatario : this.email,
        //   assunto : "Chekin sem filas",
        //   text :  link,
        //   nomeusuario : "Hotel Modelo",
        //   hotel:localStorage.getItem("hotel")
        // }
       

        //   this.$http.post('/email/enviarEmail', body).then(response =>{
        //     console.log(response)
        //     this.respostaAcao = "E-mail enviado com sucesso."
        //     setTimeout(() => { 
        //       this.dialog2 = false
        //     }, 1000);
            
            
        //     this.email = ""
        //   }).catch(error=> {
        //     console.log(error)
        //     console.log(error.data)
        //   })
        }else{
          this.respostaAcao = "E-mail não pode estar vazio."
          return
        }
        
    },
    buscarDadosAPI() {
      this.$http
        .post("configuracao/buscarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: "APIWHATSAPP",
        })
        .then((resp) => {
          if (resp.status == 200) {
            this.isWhatsAppSet = resp.data[0].valor == "true" ? true : false;
            if(!this.isWhatsAppSet){
              this.isWhatsAppSetText = "Verifique as configuração da API WhatsAPP."
            }
          } else {
            alert("Não foi possivel buscar os dados");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
}
</script>

<style>
table.v-table thead tr {
  color: red !important;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.card-outter {
  position: relative;
  padding-bottom: 50px;
  width: auto;
  height: 700px;
}
.card-actions {
  position: absolute;
  bottom: 0;
  align-items: center;
}
</style>
