<template>
    <div class="containerPrincipal">
          <div class="topo" style="">
                <div class="topoCampo">
                    <!-- <img src="@/assets/img/logowc.png" class="topoImglogo" alt="WEB CHECK-IN" crossorigin /> -->
                </div>

                <div class="topoCampo">
                    <img src="@/assets/img/logowc.png" class="topoImglogo" alt="WEB CHECK-IN" crossorigin />
                    <!-- <img v-if="this.$store.state.img_hotel" :src="this.$store.state.img_hotel" class="topoImglogo" alt="HOTEL LOGO" crossorigin /> -->
                </div>
          </div>
          <div class="conteudo" style="margin-top:10vh">
            <!-- {{componenteatual}} -->
          <!-- <keep-alive> -->
            <Dados  v-if="pagina == 'Dados'" @atualizarDados="(dados,pagina) => atualizarDados(dados,pagina)" v-bind:dados="dados" />
            <!-- <Canais v-if="pagina == 'Canais'" @atualizarDados="(dados,pagina) => atualizarDados(dados,pagina)" v-bind:dados="dados" /> -->
            <ReservasPrincipal v-if="pagina == 'ReservasPrincipal'" @atualizarDados="(dados,pagina) => atualizarDados(dados,pagina)" v-bind:dados="dados" @voltar="() => this.pagina = 'Dados'"/>
            <Reservas v-if="pagina == 'Reservas'" @atualizarDados="(dados,pagina) => atualizarDados(dados,pagina)" v-bind:dados="dados" />
           <!-- <Inicio  @direcionar="(valor) => direcionar(valor)"  v-bind:tokenAuthWebCheckin="tokenAuthWebCheckin"    v-if="componenteatual == 'inicio'" /> -->
            <!-- <Inicio           v-if="componenteatual == 'inicio'"          v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <Nacionalidade    v-if="componenteatual == 'nacionalidade'"   v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <Endereco         v-if="componenteatual == 'endereco'"        v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <Contato          v-if="componenteatual == 'contato'"         v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <FotoFrente       v-if="componenteatual == 'fotofrente'"      v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <FotoVerso        v-if="componenteatual == 'fotoverso'"       v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <DadoPessoal      v-if="componenteatual == 'dadopessoal'"     v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <Assinatura       v-if="componenteatual == 'assinatura'"      v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <FormularioCovid  v-if="componenteatual == 'formulariocovid'" v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <Observacoes      v-if="componenteatual == 'observacoes'"     v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <Horario          v-if="componenteatual == 'horario'"         v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <Hospedes         v-if="componenteatual == 'hospedes'"        v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)"  @recarregarDadosInicial="(idreservahospede) => recarregarDadosInicial(idreservahospede)"/> -->
          <!-- </keep-alive> -->
          </div>

        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                <span>{{ snackText }}</span>
                <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
            </div>
        </v-snackbar>


          <v-dialog v-model="loading" width="70%"  style="min-height:90vh;">
              <v-card style="min-height:50vh;display:flex;justify-content:center;align-items:center;flex-direction:column;text-align:center;">
                  
                  <h1>Aguarde!</h1>
                  <div>Salvando seus dados...</div>
                  
              </v-card>
          </v-dialog>


    </div>


</template>

<script>
import Dados from './webcheckinportal/Dados'
// import Canais from './webcheckinportal/Canais'
import ReservasPrincipal from './webcheckinportal/ReservasPrincipal'
import Reservas from './webcheckinportal/Reservas'


  export default {
    name: 'Home',

    components: {
        Dados,
        // Canais,
        ReservasPrincipal,
        Reservas

    },
    data: () =>{
        return{
            pagina:'Dados',
            dados:{},
            snack: false,
            snackColor: '',
            snackText: '',
            loading:false,
            // assuntodowebcheckin:null,
            // textodowebcheckin:null
        }
    },
    created(){
    //   this.carregarDados(this.idreservahospede)
    },
    async beforeMount(){
        let username = "webcheckin";
        let password = "FSD6&*)))4%8gf54dfh8sdfsdfs342854%$&99##3";

        await this.$store.dispatch("login", { username, password })
        this.$store.dispatch("set_tipoacesso", 'webcheckinportal')
       await this.$http.post("configuracao/buscarParametro", {  "idhotel" : 1,"chave" : 'WCHECKINESTILO'})
                  .then(async resp =>{
                      import('./webcheckinportal/folhadeestilos/'+resp.data[0].valor4.toLowerCase()+'.css')
                  }).catch(error =>{ console.log(error) })

        this.$http.post("configuracao/buscarParametro", {  "idhotel" : 1,"chave" : 'TEXTOWEBCHECKIN'})
                  .then(async resp =>{
                      this.dados.assuntodowebcheckin = resp.data[0].valor
                      this.dados.textodowebcheckin   = resp.data[0].valor2
                  }).catch(error =>{ console.log(error) })
    },
    methods: {
        atualizarDados(dados,pagina){
       
            if(dados.datacheckinprevisto){
                this.dados.datacheckinprevisto = dados.datacheckinprevisto
            }

            if(dados.datacheckoutprevisto){
                this.dados.datacheckoutprevisto = dados.datacheckoutprevisto
            }

            if(dados.nome){
                this.dados.nome = dados.nome
            }

            if(dados.canal){
                this.dados.canal = dados.canal
            }

            if(dados.numeroreserva){
                this.dados.numeroreserva = dados.numeroreserva
            }

            this.pagina = pagina
            
        },
        emitirAlert(dados){
            this.snack = dados.snack //true
            this.snackColor = dados.snackColor //'success'
            this.snackText = dados.snackText //'Data saved'
        },
        direcionar(valor){
            this.componenteatual = valor
        },
        // realizarLogin(){
        //   let username = "master";
        //   let password = "123456";
        //   this.$store.dispatch("login", { username, password })
        //       .then(() => { 
        //           this.usuariologado = true 
        //       })
        //       .catch((err) => {
        //          alert(JSON.stringify(err))
        //       });
        // },
        // recarregarDadosInicial(idreservahospede){
        //     this.$http.post("/reserva/select", {id:idreservahospede})
        //     .then(async resp =>{

        //           this.dados =  null
        //           this.dados = await resp.data[0]
        //           this.componenteatual = "inicio"

        //     })
        //     .catch(error =>{ alert(error) })
        // },
        // async carregarDados(idreservahospede){
        //     await this.realizarLogin();

        //     this.$http.post("/reserva/select", {id:idreservahospede})
        //     .then(resp =>{

        //           this.dados = null
        //           this.dados = resp.data[0]  
        //           this.hospedePrincipal = resp.data[0].principal

        //           //se for hospede principal carrego os demais hospedes para seleção no final
        //           if(this.hospedePrincipal){
        //               this.$http.post("/reserva/select", { idreserva:55,principal:false }).then(acompanhantes =>{
        //                     console.log("acompanhantes")
        //                     console.log(acompanhantes.data)
        //                     this.dadosAcompanhantes = acompanhantes.data
        //               })
        //           }


        //           //verifico parametrização de alguns passos
        //           this.$http.post("configuracao/buscarParametro", {  "idhotel" : this.dados.hotel,"chave" : 'WCHECKINEXIBIROBS'})
        //           .then(async resp =>{
        //               this.dados.exibirPassosObservacao = await resp.data[0].valor == 'true' ? true : false
        //           }).catch(error =>{ console.log(error) })

        //           this.$http.post("configuracao/buscarParametro", { "idhotel" : this.dados.hotel,"chave" : 'WCHECKINEXIBIRHORARIO'})
        //           .then(async resp =>{
        //               this.dados.exibirPassosHorario = await resp.data[0].valor == 'true' ? true : false
        //           }).catch(error =>{ console.log(error) })

        //           this.$http.post("configuracao/buscarParametro", { "idhotel" : this.dados.hotel,"chave" : 'IDADECRIANCAWEBCHECKIN'})
        //           .then(async resp =>{
        //               this.dados.idadelimitecrianca = await resp.data[0].valor
        //           }).catch(error =>{ console.log(error) })



        //     })
        //     .catch(error =>{ alert(error) })
        // },
        // async salvarDados(dadosnovos, novapagina){
        //     this.loading = await true
            
        //     this.dados = dadosnovos

        //     let body = {
        //         reserva: {
        //             id: this.dados.idreserva,
        //             numeroreserva: this.dados.numeroreserva,
        //             hotel: this.dados.hotel,
        //         },
        //         hospede: {
        //             id: this.dados.idhospede,
        //             nome: this.dados.nome,
        //             sobrenome: this.dados.sobrenome,
                    
        //             nascimento: this.dados.datanascimento,
        //             nacionalidade : this.dados.idnacionalidade,
        //             sexo: this.dados.sexo,
                    
        //             // profissao: this.dados.profissao,
        //             // cpf: this.dados.numerodocumento,
        //             email : this.dados.email,
                    
                   
        //         },
        //     };

        //     if(this.dados.termolgpd){
        //         body.hospede.termolgpd = true
        //     }else{
        //         body.hospede.termolgpd = false
        //     }
        //     // console.log("endereco ->",this.dados.endereco)
        //     // console.log("cep ->",this.dados.cep)
        //     // console.log("cidade ->",this.dados.cidade)
        //     // console.log("bairro ->",this.dados.bairro)
        //     // console.log("uf ->",this.dados.uf)
        //     // console.log("numero ->",this.dados.numero)
        //     // console.log("complemento ->",this.dados.complemento)

        //     if(this.dados.cep && this.dados.endereco && this.dados.cidade && this.dados.bairro && this.dados.pais
        //        && this.dados.uf && this.dados.numero
        //       ){
        //         // console.log("PASSANDO NO END")
        //         body.hospede.enderecos = [
        //           {
        //             tipo: 1,
        //             cep: this.dados.cep,
        //             endereco: this.dados.endereco,
        //             cidade: this.dados.cidade,
        //             bairro: this.dados.bairro,
        //             pais:this.dados.pais,
        //             iduf:this.dados.uf,
        //             numero: this.dados.numero
        //           }]

        //     }
        //     // else{
        //     //   console.log("NÂO PASSANDO NO END")
        //     // }

        //     if(this.dados.telefoneddi){ body.hospede.telefoneddi    = this.dados.telefoneddi}
        //     if(this.dados.telefoneddd){ body.hospede.telefoneddd    = this.dados.telefoneddd}
        //     if(this.dados.telefone){ body.hospede.telefone  = this.dados.telefone }
        //     if(this.dados.email){ body.hospede.email  = this.dados.email }


        //     if( this.dados.idtipodocumento && this.dados.tipodocumento && this.dados.documento && this.dados.nome
        //          && this.dados.sobrenome && this.dados.idprofissao){

                  
        //           body.hospede.nome = this.dados.nome
        //           body.hospede.sobrenome = this.dados.sobrenome
        //           body.hospede.profissao = this.dados.idprofissao                  

        //           body.hospede.documentos = [
        //             {
        //               tipo:this.dados.idtipodocumento,
        //               documento:this.dados.documento
        //             }
        //           ]
                 
        //     }

        //     if(this.dados.aceitetermo){ 
        //         body.hospede.aceitetermo    = this.dados.aceitetermo
        //     }

        //     if(this.dados.obs){ 
        //         body.hospede.obs = this.dados.obs
        //     }

        //     if(this.dados.horariochegada && this.dados.horariopartida){
        //         body.hospede.horariochegada = this.dados.horariochegada
        //         body.hospede.horariopartida = this.dados.horariopartida
        //     }

        //     if(this.dados.webcheckinfinalizado){
        //         body.webcheckinfinalizado = this.dados.webcheckinfinalizado
        //     }

        //     if(this.dados.principal){
        //         body.hospede.hospedeprincipal = true
        //     }

        //     //configuro o hospede como sendo criança
        //     if(this.dados.crianca){
        //         body.hospede.crianca = true
        //     }

        //     this.$http.post("/reserva/upinsert", body)
        //       .then(() => {
        //           // this.emitirAlert({snack:true,snackColor:'success',snackText:'Salvo com sucesso!'})
        //           // alert(novapagina)
        //           this.componenteatual = novapagina
        //           this.loading = false
        //       })
        //       .catch((error) => {
        //           console.log(error)
        //           this.loading = false
        //           this.emitirAlert({ snack:true,snackColor:'error',snackText:'Não foi possível salvar os dados. Verifique!'})
        //       });
        // }

    },

  }
</script>

<style > 

</style>
