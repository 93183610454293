<template>
    <div class="wout-login">
        <div class="wout-center">
            <form>
                <div class="wout-text-center">
                    <!-- <img src="@/assets/img/loginwout.png" 
                        width="130" height="auto"
                        alt="WEB CHECK-OUT" crossorigin 
                    /> -->
                    <div class="wout-img-inicio"></div>
                    <h1 class="my-3">{{ "Faça seu login" }}</h1>
                </div>
                <v-text-field class="input-control my-3"
                    v-model="documento"
                    ref="docField"
                    rounded filled
                    label="Número do Documento Pessoal"
                    append-icon="mdi-account-outline"
                ></v-text-field>
                <template v-if="uhs && uhs.length > 0">
                    <v-text-field disabled
                        rounded filled v-model="uh"
                        ref="uhField"
                        label="Número/Nome do apartamento"
                        append-icon="mdi-door-closed"
                    ></v-text-field>
                </template>
                <div v-show="dados.exibirSelectHotel">
                    <div style="display:flex;flex-direction:column;">
                        <label class="wout-hotel-label">Hotel</label>
                        <select v-model="hotelSelecionado" class="wout-select">
                            <option v-for="hotel in dados.hoteis" :key="hotel.id" :value="hotel.id" 
                                :selected="hotel.id == hotelSelecionado"
                            >
                                {{hotel.nome}}
                            </option>
                        </select>
                    </div>
                </div>
                <template v-if="dados.exibirLGPD">
                    <div style="display: flex; align-items: flex-start; justify-content: center; margin-bottom: 10px;">
                        <label class="wout-text-center" for="aceite">Aceito o uso de meus dados conforme a LGPD</label>
                        <input id="aceite" class="wout-input" type="checkbox" style="height: 20px; width: 20px;" v-model="termolgpd"/>
                    </div>
                    <div class="wout-link-center">
                        <a  target="blank_" 
                            @click="modaltermolgpd = true"
                        >Ver Termo LGPD</a>
                    </div>
                </template>

                <div style="display:flex;justify-content:center;">
                    <v-btn v-if="!uh" rounded :disabled="carregando || !documento"
                        class="wout-confirmar-btn"
                        v-on:click="buscarUHsPorDocumento()"
                        id="buscarUHsPorDocumento">
                        Continuar
                    </v-btn>
                    <v-btn v-else rounded :disabled="carregando"
                        class="wout-confirmar-btn"
                        v-on:click="entrar()"
                        id="entrar">
                        Entrar
                    </v-btn>
                </div>
                <div style="display:flex;justify-content:center;">
                    <div class="wout-mensagem">{{textoMensagem}}</div>
                </div>
            </form>

            <v-dialog v-model="modalAlerta" width="80vw" max-width="250px">
                <div style="background-color:white;width:100%;display:flex;flex-direction:column;padding: 10px;justify-content: center;align-items: center;text-align: center;">
                    <p>É obrigatório aceitar o termo LGPD para continuar!</p>
                    <button  style="background-color:#3C8CF1; color: white; border-radius: 10px; width: 50%; padding: 3px;" @click="() => this.modalAlerta = false">Fechar</button>
                </div>
            </v-dialog>

            <v-dialog v-model="modalFalhaLogin" width="80vw" max-width="250px">
                <div style="background-color:white;width:100%;display:flex;flex-direction:column;padding: 10px;justify-content: center;align-items: center;text-align: center;">
                    <p>Reserva não encontrada, verifique as informações fornecidas e tente novamente.</p>
                    <button  style="background-color:#3C8CF1; color: white; border-radius: 10px; width: 50%; padding: 3px;" @click="() => this.modalFalhaLogin = false">Fechar</button>
                </div>
            </v-dialog>

            <v-dialog v-model="modaltermolgpd" width="80vw">
                <div style="display:flex;align-items:center;flex-direction:column; background-color: white;">
                    <pdf :src="termoLGPDSrc" style="width:100%!important"
                        :page="paginaLGPD" >
                    </pdf>
                    <v-card>

                    </v-card>
                    <div style="margin-bottom:20px;margin-top:20px; background-color: white; width: 100%; display: flex; justify-content: center; align-items: center;"> 
                            <v-btn @click="()=>{ this.paginaLGPD > 1 ? this.paginaLGPD -= 1: null }" small elevation="" class="success" style="margin-right:10px;" >Voltar</v-btn>
                            <span style="font-size:10px;">pagina {{paginaLGPD}}</span>
                            <v-btn @click="()=>{ this.paginaLGPD += 1 }" small elevation="" class="info" style="margin-left:10px;" >Avançar</v-btn>
                    </div>
                </div>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import pdf from 'vue-pdf'
import dayjs from 'dayjs'
export default {
    props:["dados","hospedePrincipal"],
    data: () =>{
        return{
            documento: '',
            tipodoc: '2',
            nascimento: '',
            uh: '',
            nascimentoValido: false,
            dateStr: '',
            dadosReserva:null,
            termolgpd:false,
            lgpd:false,
            exibirModalTermo:false,
            termoSrc:null,
            termoTipo:null,
            hotelSelecionado:null,
            modalAlerta: false,
            modaltermolgpd: false,
            paginaLGPD: 1,
            termoLGPDSrc: null,
            modalFalhaLogin: false,
            hospedesUH: [],
            nascimentoEncontrado: '',
            hospedeEncontrado: null,
            tipo_integracao: 'HITS',
            carregando: false,
            uhs: [],
            textoMensagem: '',
            tipoDocSelecionado: 2,
            docs: [
                {id: 2, nome: 'CPF'},
                {id: 1, nome: 'Passaporte'},
                {id: 3, nome: 'RG'},
                {id: 7, nome: 'Certidão de Nascimento'},
            ]

        }
    },
    components: {
        pdf: pdf
	},
    watch: {
        nascimento() {
            this.validate()
        }
    },
    methods: {
        importarReservaPorDocumento() {
            var params = {
                tipo_integracao: this.tipo_integracao,
                idhotel:this.hotelSelecionado?this.hotelSelecionado:this.$route.params.token,
                documento: this.documento,
                tipo: this.tipoDocSelecionado.id
            }
            this.nascimentoEncontrado = ''
            this.carregando = true;
            this.textoMensagem = 'Procurando por reserva...'
            this.$http.post("/webcheckout/documento/importar",params)
            .then(result => {
                this.uhs = result.data
                if (this.uhs.length > 0) this.uh = this.uhs[0].uh
                if (this.uhs.length > 0) this.nascimento = this.uhs[0].nascimento
                if (this.uhs.length > 0) this.tipodoc = this.uhs[0].tipodoc
                this.carregando = false;
                this.textoMensagem = ''
            })
            .catch((e)=>{
                this.carregando = false;
                this.textoMensagem = ``
                this.modalFalhaLogin = true;
                console.error(e);
            })            
        },
        buscarUHsPorDocumento() {
            this.uhs = null;
            this.uh = '';
            var params = {
                tipo_integracao: this.tipo_integracao,
                idhotel:this.hotelSelecionado?this.hotelSelecionado:this.$route.params.token,
                documento: this.documento
            }
            this.nascimentoEncontrado = ''
            this.carregando = true;
            this.$http.post("/webcheckout/documento/uhs",params)
            .then(result => {
                this.uhs = result.data
                if (this.uhs.length > 0) this.uh = this.uhs[0].uh
                if (this.uhs.length > 0) this.nascimento = this.uhs[0].nascimento
                if (this.uhs.length > 0) this.tipodoc = this.uhs[0].tipodoc
                this.carregando = false;
                // Se não veio nada chamar a importação...
                if (this.uhs.length == 0) {
                    this.importarReservaPorDocumento()
                }
            })
            .catch((e)=>{
                this.carregando = false;
                console.error(e);
            })
        },
        buscarHospedesUH() {
            var params = {
                tipo_integracao: this.tipo_integracao,
                uh: this.uh,
                idhotel:this.hotelSelecionado?this.hotelSelecionado:this.$route.params.token
            }
            
            if (!params.uh.trim()) return;

            this.carregando = true;
            this.$http.post("/webcheckout/hospedes/uh",params)
            .then(result => {
                this.hospedesUH = result.data?.hospedes
                this.carregando = false;
            })
        },
        isValidDate() {
            var dateString = this.nascimento
            // Checar se está no padrão DD/MM/YYYY
            var expr = /^\d{1,2}\/\d{1,2}\/\d{2,4}$/g
            if (!expr.test(dateString))
                return false;
            var parts = dateString.split("/");
            if (dateString.length < 3)
                return false;
            var day = parseInt(parts[0], 10);
            var month = parseInt(parts[1], 10);
            var year = parseInt(parts[2], 10);
            var currentYear = new Date().getFullYear()
            // Aceitar ano de dois dígitos...
            if (year < 100) {
                if (year >= (currentYear-2000)) {
                    year += 1900
                } else {
                    year += 2000
                }
            }
            // Checar meses e anos
            if(year < 1000 || year > 3000 || month == 0 || month > 12)
                return false;
            var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
            // Ajuste dos dias de fevereiro para ano bisexto
            if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
                monthLength[1] = 29;
            // Checar o dia
            return day > 0 && day <= monthLength[month - 1];
        },
        validate() {
            this.nascimentoValido = this.isValidDate(this.nascimento)
            // return this.nascimentoValido
        },
        checkDateMask(e) {
            var key = e.keyCode || e.charCode;
            // Substituir - por / para ficar no padrão
            this.nascimento = this.nascimento.replace('-','/')
            // Se for backspace ou delete não alterar pois a data está sendo corrigida...
            if( !(key == 8 || key == 46) ) {
                // Com dois caracteres, adicionar a barra se já não existir...
                if (this.nascimento.length === 2 && !this.nascimento.includes('/')) {
                    this.nascimento += '/'
                }
                // Com cinco caracteres, adicionar a segunda barra se já não existirem duas...
                if (this.nascimento.length === 5 && !(/.{1,2}\/.{1,2}\//g.test(this.nascimento))) {
                    this.nascimento += '/'
                }
            }
            
        },
        getnomehotel() {
            var result = ''
            for (var i = 0; i < this.dados.hoteis.length; i++) {
                if (this.hotelSelecionado == this.dados.hoteis[i]['id']) {
                    result = this.dados.hoteis[i]['nome']
                    break
                }
            }
            return result
        },
        async entrar() {
            console.error(`async entrar() {`)
            if(!this.termolgpd&&this.dados.exibirLGPD) {
                this.modalAlerta = true;
                return
            }

            var params = {
                    digitos_doc: this.documento.slice(-4),
                    dt_nascimento: dayjs(this.nascimento).format('YYYY-MM-DD'),
                    uh: this.uh,
                    id_hotel:this.hotelSelecionado?this.hotelSelecionado:this.$route.params.token,
                    login_documento: true
                }
            //console.error(params)
            await this.$http.post("/webcheckout/login",params)
                .then(result => {
                    if (result.status === 200) {
                        console.log('result login', result)
                        localStorage.setItem('checkout-token', result.data.token)
                        localStorage.setItem('checkout-hotel', result.data.dados.hotel)
                        localStorage.setItem('checkout-nomehotel', this.getnomehotel())
                        localStorage.setItem('checkout-idreservahospede', result.data.dados.idreservahospede)
                        localStorage.setItem('checkout-idhospedepms', result.data.dados.idhospedepms)
                        localStorage.setItem('checkout-idhospede', result.data.dados.idhospede)
                        localStorage.setItem('checkout-email', result.data.dados.numeroendereco)
                        localStorage.setItem('checkout-numeroreserva', result.data.dados.numeroreserva)
                        localStorage.setItem('checkout-idhospedepms', result.data.dados.idhospedepms)
                        localStorage.setItem('checkout-idhospede', result.data.dados.idhospede)
                        localStorage.setItem('checkout-nome', result.data.dados.nome + (result.data.dados.sobrenome?' '+result.data.dados.sobrenome:''))
                        localStorage.setItem('checkout-uh', result.data.dados.uh)
                        localStorage.setItem('checkout-datacheckinprevisto', result.data.dados.datacheckinprevisto)
                        localStorage.setItem('checkout-datacheckoutprevisto', result.data.dados.datacheckoutprevisto)
                        localStorage.setItem('checkout-hospedes', JSON.stringify(result.data.dados.hospedes))
                        this.dados.doc = this.documento;
                        this.dados.docType = this.tipodoc;
                        this.$emit('direcionar', {to:'preinicio', from:'login'})
                    } else {
                        this.modalFalhaLogin = true;
                        console.error(JSON.stringify(result))
                    }
                })
            .catch((e) => {
                this.modalFalhaLogin = true;
                console.error(e)
            })
        },

        async requisitarTermo() {
           const termo = await this.$http.post("/webcheckout/getTermoLGPD");
           const uint8Array = new Uint8Array(termo.data[0]?.termo.data);
                                        console.log("A 2")
                                        this.termoLGPDSrc = uint8Array.reduce((acc, i) => acc += String.fromCharCode.apply(null, [i]), '');
        },
        carregarTipoIntegracao() {
            this.$http
            .post("configuracao/buscarParametro", {
                idhotel: this.hotelSelecionado?this.hotelSelecionado:this.$route.params.token,
                chave: 'TIPOINTEGRACAO',
            })
            .then((resp) => {
                if (resp.status == 200) {
                    if (resp.data&&resp.data.length>0) {
                        if (resp.data[0].valor?.trim()) {
                            this.tipo_integracao = resp.data[0].valor;
                        }
                    }
                } else {
                    console.error("Não foi possivel buscar os dados");
                }
            })
            .catch((error) => {
                console.error(error);
            });
        }        
    },
    created() {
    },
    async mounted(){
        await this.requisitarTermo();
        localStorage.setItem('checkout-hotel', null)
        localStorage.setItem('checkout-numeroreserva', null)
        localStorage.setItem('checkout-nome', null)
        localStorage.setItem('checkout-uh', null)
        localStorage.setItem('checkout-datacheckinprevisto', null)
        localStorage.setItem('checkout-datacheckoutprevisto', null)

        this.hotelSelecionado = this.dados.hotel?this.dados.hotel:null
        this.carregarTipoIntegracao();

        this.$nextTick(() => {
            this.$refs.docField.focus();
        });
        
    },
    computed: {
        dataValida() {
            const parts = this.nascimento.split('/');
            const day = parts[0];
            const month = parts[1];
            const year = parts[2];
            const nascimentoFormatado = `${year}-${month}-${day}T00:00:00`;
            return (nascimentoFormatado === this.nascimentoEncontrado);
        },
        dataUTC() {
            var parts = this.nascimento.split("/")
            var year = parseInt(parts[2],10)
            var currentYear = new Date().getFullYear()
            if (year < 100) {
                if (year >= (currentYear-2000)) {
                    year += 1900
                } else {
                    year += 2000
                }
            }
            return year+'/'+parts[1]+'/'+parts[0]
        }
    }
}
</script>
