<template>
    <div class="wout-login">
        <div class="wout-center">
            <div>
                <img src="@/assets/img/pgtonegado.svg" 
                    width="auto" height="130" alt="Ops! Falha no reconhecimento facial!" crossorigin />
            </div>
            <span class="wout-text-center">Ops!<br>Houve falha na tentativa de reconhecimento facial!</span>
            <br>
            <button class="wout-tentar-novamente"
                @click="voltar"
            >
                Tentar novamente
            </button>
        </div>
    </div>
</template>
<script>

export default {
    data: () => {
        return {
            
        }
    },
    methods: {
        voltar() {
            this.$emit('direcionar',{from: 'falhareconhecimento', to: 'self'})
        }
    }
}
</script>
